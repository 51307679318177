import React, { useState, useEffect, Fragment, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import { getStakingsHistoryAction, loadingToggleAction } from "../../store/actions/ManageStakingsAction";
import { Tabs, Tab, Pagination } from "react-bootstrap";

const StakeHistory = (props) => {
  const [activePage, setActivePage] = useState(1);
  const totalPages = 3; // Example total pages

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const [value, setValue] = useState(8);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const user = JSON.parse(localStorage.userDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(getStakingsHistoryAction());
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);

    // Format date as "May 14, 2024"
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    // Format time as "2:03:26 PM"
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    return { formattedDate, formattedTime };
  }
  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
          <div className="pools-container">
            <h4 className="pool-heading">Stake History</h4>
            <h2 className="text-pools">
              Dashboard/<span className="text-ppols-1">Stake History </span>
            </h2>
          </div>

          <div className="history-stakings">
            <div className="card-body-pools">
              <div className="table-responsive recentOrderTable">
                <div className="table-container">
                  <table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">Pool Name</th>
                        <th scope="col">duration(days)</th>
                        <th scope="col">Profit</th>
                        <th scope="col">Amount Staked</th>
                        <th scope="col">Partner Tier</th>

                        <th scope="col">Earned</th>

                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>

                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.stakings?.stakingHistory && props.stakings.stakingHistory.length > 0 ? (
                        props.stakings.stakingHistory.map((staking) => (
                          <tr key={staking.id}>
                            <td>{staking.pool_info.name}</td>
                            <td data-label="duration(days)">{staking.pool_info.duration}</td>
                            <td data-label="Profit" className="pools-profit">
                              {staking.profit_percent ? staking.profit_percent + "%" : staking.profit_percent_range}
                            </td>
                            <td data-label="Amount Staked">{staking.amount_staked}</td>
                            <td data-label="Partner Tier">{staking.tier}</td>
                            <td data-label="Earned">{((staking.amount_staked * staking.profit_percent) / 100).toFixed(2)}</td>
                            <td data-label="">
                              <div className="may-142024-20326-container">
                                <p className="pm">{formatDate(staking.created_at).formattedDate}</p>
                                <p className="pm">{formatDate(staking.created_at).formattedTime}</p>
                              </div>
                            </td>
                            <td data-label="">
                              <div className="may-142024-20326-container">
                                <p className="pm">{formatDate(staking.end_date).formattedDate}</p>
                                <p className="pm">{formatDate(staking.end_date).formattedTime}</p>
                              </div>
                            </td>

                            <td>
                              {staking.status === "completed" ? (
                                <button href="#" className="button-green-war">
                                  <div className="button-green-war-text">{staking.status}</div>
                                </button>
                              ) : (
                                <button href="#" className="button-red-war">
                                  <div className="button-red-war-text">{staking.status}</div>
                                </button>
                              )}
                            </td>
                            <div className="dotline-for-mobile22"></div>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9">
                            <div className="no-data-message">
                              <p>No staking transaction</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  stakings: state.stakings,
  stakingHistory: state.stakingHistory,
  isLoading: state.isLoading,
  error: state.error,
});

export default connect(mapStateToProps)(StakeHistory);
