import React, { useState, useContext, useEffect } from "react";

/// React router dom
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";

import TopUpAndWithdraw from "./components/TopUpAndWithdraw";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
////import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
//import Todo from "./pages/Todo";

// Widget
import Widget from "./pages/Widget";
import Pools from "./pages/Pools";
import EditProfile from "./pages/EditProfile";
import ManageStaking from "./pages/ManageStakings";
import Calculator from "./pages/Calculator";
import StakeHistory from "./pages/StakeHistory";
import Transactions from "./pages/Transactions";
import Earnings from "./pages/Earnings";
import Rewards from "./pages/Rewards";
import Tiers from "./pages/Tiers";
import ContactsUs from "./pages/ContactsUs";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CkEditor from "./components/Forms/CkEditor/CkEditor";
import Pickers from "./components/Forms/Pickers/Pickers";
import FormValidation from "./components/Forms/FormValidation/FormValidation";
import Card from "./components/card";
/// Pages
//import Registration from "./pages/Registration";
//import Login from "./pages/Login";
//import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import bitcoin from "../images/background/bitcoin.png";
import asd from "../images/background/asd.png";
import dogecoin from "../images/background/dogecoin.png";
import litecoin from "../images/background/litecoin.png";
import eth from "../images/background/eth.png";
import Pools2 from "./pages/Pools2";
import ManageStakings2 from "./pages/ManageStakings2";
import StakeHistory2 from "./pages/StakeHistory2";
import Transactions2 from "./pages/Transactions2";
import Earnings2 from "./pages/Earnings2";
import Rewards2 from "./pages/Rewards2";
import useSmoothScroll from "./components/useSmoothScroll";

const Markup = () => {
  const allroutes = [
    /// Dashboard
    { url: "", component: <Pools /> },
    { url: "dashboard", component: <Pools /> },

    ///// Partner Dashboard Pages
    { url: "pools", component: <Pools /> },
    { url: "profile", component: <EditProfile /> },
    { url: "", component: <ManageStaking /> },
    { url: "calculator", component: <Calculator /> },
    { url: "stake", component: <StakeHistory /> },
    { url: "transactions", component: <Transactions /> },
    { url: "my-earnings", component: <Earnings /> },
    { url: "rewards", component: <Rewards /> },
    { url: "contact-us", component: <ContactsUs /> },
    { url: "donations", component: <Tiers /> },
    { url: "pools2", component: <Pools2 /> },
    { url: "manage-stakings", component: <ManageStakings2 /> },
    { url: "stake-history", component: <StakeHistory2 /> },
    { url: "transactions2", component: <Transactions2 /> },
    { url: "my-earnings2", component: <Earnings2 /> },
    { url: "rewards2", component: <Rewards2 /> },





    
  ];

  return (
    <>
      <Routes>
        {/* <Route path="page-lock-screen" element={<LockScreen />} /> */}
        {/* <Route path="page-error-400" element={<Error400 />} />
        <Route path="page-error-403" element={<Error403 />} />
        <Route path="page-error-404" element={<Error404 />} />
        <Route path="page-error-500" element={<Error500 />} />
        <Route path="page-error-503" element={<Error503 />} /> */}
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route key={i} exact path={`${data.url}`} element={data.component} />
          ))}
        </Route>
        {/* <Route path="page-lock-screen" element={<LockScreen />} />
        <Route path="page-error-400" element={<Error400 />} />
        <Route path="page-error-403" element={<Error403 />} />
        <Route path="page-error-404" element={<Error404 />} />
        <Route path="page-error-500" element={<Error500 />} />
        <Route path="page-error-503" element={<Error503 />} /> */}
        <Route path="*" element={<Navigate to="/pools" replace />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // const BalanceCardsRow = () => (
  //   <Row>
  //     <Col xs={12} sm={6} md={3}>
  //       <GreetingCard />
  //     </Col>
  //     <Col xs={12} sm={6} md={3}>
  //       <BalanceCard/>
  //     </Col>
  //     <Col xs={12} sm={6} md={3}>
  //       <BalanceCard2 />
  //     </Col>
  //     <Col xs={12} sm={6} md={3}>
  //       <BalanceCard2 />
  //     </Col>
  //   </Row>
  // );
  

  const { menuToggle } = useContext(ThemeContext);
  useSmoothScroll()
  return (
    <div className="smooth-wrapper" >
      <div className="overall-jsx">
      
        <Nav />
        <div>

        <Outlet/>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default Markup;
