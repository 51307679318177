import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getUpdatedBalancePartnerAction } from "../../store/actions/PartnersActions";

function calculateRemainingPercentage(balance) {
  let nextTier = 0;
  let nextTierThreshold = 0;

  if (balance >= 0 && balance <= 500) {
    nextTier = 2;
    nextTierThreshold = 501;
  } else if (balance <= 2000) {
    nextTier = 3;
    nextTierThreshold = 2001;
  } else if (balance <= 5000) {
    nextTier = 4;
    nextTierThreshold = 5001;
  } else if (balance <= 10000) {
    nextTier = 5;
    nextTierThreshold = 10001;
  } else if (balance <= 25000) {
    nextTier = 6;
    nextTierThreshold = 25001;
  } else if (balance <= 50000) {
    nextTier = 7;
    nextTierThreshold = 50001;
  } else {
    return "Woah! You are in the highest tier.";
  }
  const amountNeeded = nextTierThreshold - balance;
  let percentage = (balance * 100) / nextTierThreshold;
  if (percentage <= 5) percentage = 5;
  return percentage;
}

function calculateNextTier(balance) {
  let nextTier = 0;
  let nextTierThreshold = 0;

  if (balance >= 0 && balance <= 500) {
    nextTier = 2;
    nextTierThreshold = 501;
  } else if (balance <= 2000) {
    nextTier = 3;
    nextTierThreshold = 2001;
  } else if (balance <= 5000) {
    nextTier = 4;
    nextTierThreshold = 5001;
  } else if (balance <= 10000) {
    nextTier = 5;
    nextTierThreshold = 10001;
  } else if (balance <= 25000) {
    nextTier = 6;
    nextTierThreshold = 25001;
  } else if (balance <= 50000) {
    nextTier = 7;
    nextTierThreshold = 50001;
  } else {
    return "Woah! You are in the highest tier.";
  }

  const amountNeeded = nextTierThreshold - balance;
  return `${amountNeeded.toFixed(2)} USDT to reach Tier ${nextTier}.`;
}

const BalanceCard = (props) => {
  // let isVerifiedWaring = true;
  const [isVerifiedWaring, setIsVerifiedWaring] = useState(true);
  const [remainingPercent, setRemainingPercent] = useState("");
  const [nextTierAlert, setNextTierAlert] = useState("");

  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.userDetails);
  let partnerBalance = user.balance;
  useEffect(() => {
    // console.log(props.partners.partners);
    dispatch(getUpdatedBalancePartnerAction());
    if (!user.is_verified) notifyWarning();
    setNextTierAlert(calculateNextTier(user.balance + user.total_staked));
    setRemainingPercent(calculateRemainingPercentage(user.balance + user.total_staked));
  }, [partnerBalance]);
  function openTopUpModal() {}
  const notifyWarning = () => {
    if (isVerifiedWaring) {
      toast.warn("❗ Please verify your email. !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsVerifiedWaring(false);
      // setIsVerifiedWaring(false);
    }
  };
  return (
    <Fragment>
      <div className="container text-center">
        <div class="row">
          <div class="col">
            <div className="widget-stat card bg-secondary ">
              <div className="card-body p-4">
                <div className="media">
                  <span className="me-3">
                    <a as="a" href="#" onClick={() => openTopUpModal()}>
                      <i className="la la-dollar"></i>
                    </a>
                  </span>
                  <div className="media-body text-white">
                    <p className="mb-1">Balance</p>
                    <h3 className="text-white">{user.balance.toLocaleString()} USDT</h3>
                    <div className="progress mb-2 bg-secondary">
                      <div className="progress-bar progress-animated bg-white" style={{ width: `${remainingPercent}%` }}></div>
                    </div>
                    <medium>{nextTierAlert}</medium>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div className="widget-stat card bg-secondary ">
              <div className="card-body p-4">
                <div className="media">
                  <span className="me-3">
                    <i className="la la-dollar"></i>
                  </span>
                  <div className="media-body text-white">
                    <p className="mb-1">Amount Staked</p>
                    <h3 className="text-white">{user.total_staked.toLocaleString()} USDT</h3>
                    <div className="progress mb-2 bg-secondary">
                      <div className="progress-bar progress-animated bg-[9568ff]" style={{ width: `${remainingPercent}%` }}></div>
                    </div>
                    {/* <small>30% Increase in 30 Days</small> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  showLoading: state.showLoading,
  error: state.error,
});
export default connect(mapStateToProps)(BalanceCard);
