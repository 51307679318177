import React from "react";
import swal from "sweetalert";

//import { useNavigate } from "react-router-dom";

import {
  registerSignUpEmail,
  signUpVerifyEmail,
  signUpSetPassword,
  signUpPromoCode,
  signUpSetAccountType,
  signUpCompleteProfile,
  formatError,
  formatError2,
  getIpInfo,
  login,
  verifyLoginService,
  googleLogin,
  saveTokenInLocalStorage,
  forgotPassword,
  resetPassword,
  signUp,
  verifyEmail,
} from "../../services/AuthService";

export const CONFIRMED_SIGNUP_REGISTER_EMAIL_ACTION = "[signup action] confirmed signup register email action";
export const CONFIRMED_SIGNUP_EMAIL_VERIFIED_ACTION = "[signup action] confirmed signup email verified action";
export const CONFIRMED_SIGNUP_SET_PASSWORD_ACTION = "[signup action] confirmed signup set password action";
// export const CONFIRMED_SIGNUP_LINK_RERERRAL_ACCOUNT_ACTION = "[signup action] confirmed signup link referral account action";
export const CONFIRMED_SIGNUP_SET_PROMO_ACCOUNT_ACTION = "[signup action] confirmed signup set promo code action";
export const CONFIRMED_SIGNUP_SET_ACCOUNT_TYPE_ACTION = "[signup action] confirmed signup set account type action";
export const CONFIRMED_SIGNUP_COMPLETE_PROFILE_ACTION = "[signup action] confirmed signup link referral account action";
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_AUTHENTICATED_ACTION = "[Login Authenticated action] Login Authenticated action";
export const LOGIN_UNAUTHENTICATE_ACTION = "[Login UNauthenticate action] Login UnAuthenticate action";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const AUTH_LOADING_TOGGLE_ACTION = "[auth loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const FORGOR_PASSWORD_ACTION = "[Forgotpassword action] ForgotPassword action";

//Register Email(Sign up) Action
export function signupRegisterEmailAction(_email, _referralCode, navigate, setIsLoadingCallback) {
  return (dispatch) => {
    registerSignUpEmail(_email, _referralCode)
      .then((response) => {
        dispatch(confirmedSignupRegisterEmail(response.data));
        dispatch(loadingToggleAction(false));
        setIsLoadingCallback(); // This will update the isLoading state in the component

        navigate("/verification-new-register", { state: { email: response.data.data.user.email, token: response.data.data.idToken, userId: response.data.data.user.id } });
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data.message);
        dispatch(signupFailedAction(errorMessage));
        setIsLoadingCallback(); // Reset local state in case of error
      });
  };
}

export function confirmedSignupRegisterEmail(payload) {
  return {
    type: CONFIRMED_SIGNUP_REGISTER_EMAIL_ACTION,
    payload,
  };
}

//Verify Email (Sign up) with code Action
export function signUpVerifyEmailAction(_email, code, token, navigate, setIsLoadingCallback) {
  return (dispatch) => {
    signUpVerifyEmail(_email, code, token)
      .then((response) => {
        dispatch(confirmedSignupEmailVerified(response.data));
        setIsLoadingCallback(); // This will update the isLoading state in the component

        navigate("/set-password", { state: { email: response.data.data.user.email, token: response.data.data.idToken } });
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data.message);
        dispatch(signupFailedAction(errorMessage));
        setIsLoadingCallback(); // This will update the isLoading state in the component
      });
  };
}

export function confirmedSignupEmailVerified(payload) {
  return {
    type: CONFIRMED_SIGNUP_EMAIL_VERIFIED_ACTION,
    payload,
  };
}

//Set Password (Sign up) with code Action
export function signUpSetPasswordAction(_email, password, token, navigate, setIsLoadingCallback) {
  return (dispatch) => {
    signUpSetPassword(_email, password, token)
      .then((response) => {
        saveTokenInLocalStorage(response.data.data);
        dispatch(confirmedSignUpSetPassword(response.data.data));
        setIsLoadingCallback();
        response.data.data.is_referred ? navigate("/account-type") : navigate("/register-done");
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data.message);
        dispatch(signupFailedAction(errorMessage));
        setIsLoadingCallback();
      });
  };
}

export function confirmedSignUpSetPassword(payload) {
  return {
    type: CONFIRMED_SIGNUP_SET_PASSWORD_ACTION,
    payload,
  };
}

//Link Referral (Sign up) with referral-code Action
export function signUpSetPromoCodeAction(promoCode, navigate) {
  return (dispatch) => {
    signUpPromoCode(promoCode)
      .then((response) => {
        saveTokenInLocalStorage(response.data.data);
        dispatch(confirmedSignUpSetPromoCodeAction(response.data.data));
        dispatch(loadingToggleAction(false));
        navigate("/account-type");
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data.message, navigate);
        dispatch(loadingToggleAction(false));
      });
  };
}

export function confirmedSignUpSetPromoCodeAction(payload) {
  return {
    type: CONFIRMED_SIGNUP_SET_PROMO_ACCOUNT_ACTION,
    payload,
  };
}
//Set Account type (Sign up)
export function signUpSetAccountTypeAction(accType, navigate, setIsLoadingCallback) {
  return (dispatch) => {
    signUpSetAccountType(accType)
      .then((response) => {
        saveTokenInLocalStorage(response.data.data);
        dispatch(confirmedSignUpSetAccountType(response.data.data));
        navigate("/complete-profile");
        setIsLoadingCallback();

        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data.message, navigate);
        dispatch(signupFailedAction(errorMessage));
        setIsLoadingCallback();
      });
  };
}

export function confirmedSignUpSetAccountType(payload) {
  return {
    type: CONFIRMED_SIGNUP_SET_ACCOUNT_TYPE_ACTION,
    payload,
  };
}

//Sign Up Complete Profile Action
export function signUpCompleteProfileAction(name, phone, country, dob, navigate, setIsLoadingCallback) {
  return (dispatch) => {
    signUpCompleteProfile(name, phone, country, dob)
      .then((response) => {
        saveTokenInLocalStorage(response.data.data);
        dispatch(confirmedSignUpCompleteProfile(response.data.data));
        setIsLoadingCallback();
        navigate("/pools");
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data.message, navigate);
        dispatch(signupFailedAction(errorMessage));
        setIsLoadingCallback();
      });
  };
}

export function confirmedSignUpCompleteProfile(payload) {
  return {
    type: CONFIRMED_SIGNUP_SET_PROMO_ACCOUNT_ACTION,
    payload,
  };
}

export function signupAction(name, account_type, email, password, repeat_password, phone, country, referralCode, navigate) {
  return (dispatch) => {
    signUp(name, account_type, email, password, repeat_password, phone, country, referralCode)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        // runLogoutTimer(
        //     dispatch,
        //     response.data.expiresIn * 1000,
        //     //history,
        // );
        dispatch(confirmedSignupAction(response.data));
        navigate("/email-not-verified");
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate) {
  localStorage.removeItem("userDetails");
  localStorage.removeItem("jwt");
  navigate("/login");
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function googleLoginAction(navigate) {
  return (dispatch) => {
    googleLogin()
      .then((response) => {
        // saveTokenInLocalStorage(response.data);
        // runLogoutTimer(
        //     dispatch,
        //     response.data.expiresIn * 1000,
        //     navigate,
        // );
        // dispatch(loginConfirmedAction(response.data));
        const url = response.data;
        // console.log(url);
        window.location.href = url;
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}
export function getIpInfoAction(navigate) {
  return (dispatch) => {
    getIpInfo()
      .then((response) => {
        const res = response.data;
        // console.log(res);
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginAction(email, password, captchaToken, navigate) {
  return (dispatch) => {
    login(email, password, captchaToken)
      .then((response) => {
        if (!response.data.data.user.is_verified) {
          saveTokenInLocalStorage(response.data);
          return navigate("/email-not-verified", { state: { email: response.data.data.email } });
        } else if (response.data.data.user.authenticated) {
          saveTokenInLocalStorage(response.data.data);
          dispatch(loginAuthenticatedAction());
        } else if (!response.data.data.user.is_active) {
          swal("Sorry", "Your profile is not complete. Please complete your profile to login", "error");
          navigate("/set-password", { state: { email: response.data.data.user.email, token: response.data.data.idToken } });
        } else if (!response.data.data.user.is_complete) {
          swal("Sorry", "Your profile is not complete. Please complete your profile to login", "error");
          saveTokenInLocalStorage(response.data.data);
          navigate("/register-done");
        } else {
          const partner = response.data.data.user;
          if (partner.is_Admin) {
            saveTokenInLocalStorage(response.data.data);
            dispatch(loginConfirmedAction(response.data.data));
            navigate("/pools");
            return;
          }
        }

        // console.log("a");
        // if (!partner.is_verified) return navigate("/email-not-verified", { state: { email: partner.email } });

        // runLogoutTimer(
        //     dispatch,
        //     response.data.data.expiresIn * 1000,
        //     navigate,
        // );
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data.message);
        if (error.response.data.message === "Inactive account") {
          navigate("/register");
        }
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginUnAuthenticateAction() {
  return (dispatch) => {
    dispatch({
      type: LOGIN_UNAUTHENTICATE_ACTION,
      payload: "data",
    });
  };
}

export function verifyLoginAction(loginVerificationCode, navigate) {
  return (dispatch) => {
    verifyLoginService(loginVerificationCode, navigate)
      .then((response) => {
        const partner = response.data.data.user;
        saveTokenInLocalStorage(response.data.data);
        // if (!partner.is_verified) return navigate("/email-not-verified", { state: { email: partner.email } });

        // runLogoutTimer(
        //     dispatch,
        //     response.data.expiresIn * 1000,
        //     navigate,
        // );
        dispatch(loginConfirmedAction(response.data.data));
        dispatch(loginUnAuthenticateAction());
        navigate("/pools");
      })
      .catch((error) => {
        const errorMessage = formatError2(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function forgotPasswordAction(email, navigate) {
  return (dispatch) => {
    forgotPassword(email)
      .then((response) => {
        swal("Thankyou!", `Please check your email inbox for password reset Link.`, "success");

        // navigate("/login");
      })
      .catch((error) => {
        const errorMessage = formatError2(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function resetPasswordAction(_password, _resetToken, navigate) {
  return (dispatch) => {
    resetPassword(_password, _resetToken)
      .then((response) => {
        saveTokenInLocalStorage(response.data.data);
        dispatch(loginConfirmedAction(response.data.data));
        swal("Success!", `You have successfully reset your password.`, "success");
        navigate("/pools");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function loginAuthenticatedAction(data) {
  return {
    type: LOGIN_AUTHENTICATED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: AUTH_LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
