import React, { useState, useEffect, Fragment, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getTransactionsAction,
  loadingToggleAction,
  cancelWithdraTransactionAction,
} from "../../store/actions/TransactionsActions";
import {
  Dropdown,
  Button,
  Modal,
  Pagination,
  DropdownButton,
  Nav,
  Tab,
  Tabs,
  Container,
} from "react-bootstrap";

const Transactions2 = (props) => {
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const totalPages = 2; // Example total pages

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPageChange = (rows) => {
    setRowsPerPage(rows);
  };
  const circleStyle = {
    strokeDasharray: `${70}, 100`,
  };
  const [value, setValue] = useState(8);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [key, setKey] = useState("deposits");
  const [isWithdrawCanceled, setIsWithdrawCanceled] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(getTransactionsAction());
  }, [isWithdrawCanceled]);
  const withdrawls = props.transactions.withdrawls;
  const deposits = props.transactions.deposits;
  function formatDate(dateString) {
    const date = new Date(dateString);

    // Format date as "May 14, 2024"
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    // Format time as "2:03:26 PM"
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    return { formattedDate, formattedTime };
  }
  const handleCancelWithdraw = (_withdraw) => {
    dispatch(cancelWithdraTransactionAction(_withdraw));
    setTimeout(() => {
      setIsWithdrawCanceled(!isWithdrawCanceled);
    }, 2000);
  };
  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
          <div className="pools-container">
            <h4 className="pool-heading">Transactions</h4>
            <h2 className="text-pools">
              Dashboard/<span className="text-ppols-1">Transactions </span>
            </h2>
          </div>
          <div className="main-div">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="custom-tabs"
            >
              <Tab
                eventKey="deposits"
                title="Deposits"
                className={key === "deposits" ? "active-tab" : "inactive-tab"}
              >
                <div className="manage-Tran">
                  <div className="card-body-pools">
                    <div className="table-responsive recentOrderTable">
                      <div className="table-container">
                        <table className="table verticle-middle table-responsive-md">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Transaction Type</th>
                              <th scope="col">Transaction Amount</th>
                              <th scope="col">Created At</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {deposits && deposits.length > 0 ? (
                              deposits.map((deposit, index) => (
                                <React.Fragment key={deposit.id}>
                                  <tr>
                                    <td>{index + 1}.</td> {/* Row number */}
                                    <td
                                      data-label="Transaction Type"
                                      className="Transaction-Type"
                                    >
                                      {deposit.type}
                                    </td>
                                    <td data-label="Transaction Amount">
                                      {deposit.amount}{" "}
                                      {/* Display the deposit amount */}
                                    </td>
                                    <td data-label="Date and Time">
                                      <div className="may-142024-20326-container">
                                        <p className="pm">
                                          {
                                            formatDate(deposit.created_at)
                                              .formattedDate
                                          }
                                        </p>
                                        <p className="pm">
                                          {
                                            formatDate(deposit.created_at)
                                              .formattedTime
                                          }
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <button className="button-green-server">
                                        <div className="button-text-server">
                                          {deposit.status}
                                        </div>
                                      </button>
                                    </td>
                                    <div className="dotline-for-mobile23"></div>
                                  </tr>
                                </React.Fragment>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">
                                  <div className="no-data-message">
                                    <p>No deposit available</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </Tab>
              <Tab
                eventKey="withdraws"
                title="Withdraws"
                className={key === "withdraws" ? "active-tab" : "inactive-tab"}
              >
                <div className="manage-withdraw ">
                  <div className="card-body-pools">
                    <div className="table-responsive recentOrderTable">
                      <div className="table-container">
                        <table className="table verticle-middle table-responsive-md">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Transaction Type</th>
                              <th scope="col">Transaction Amount</th>
                              <th scope="col">Withdraw Fee</th>

                              <th scope="col">Created At</th>
                              <th scope="col">Status</th>
                              <th scope="col">Details</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {withdrawls && withdrawls.length > 0 ? (
                              withdrawls.map((withdraw, index) => (
                                <tr key={withdraw.id}>
                                  <td>{index + 1}.</td> {/* Row number */}
                                  <td
                                    data-label="Transaction Type"
                                    className="Transaction-Type-withdraw"
                                  >
                                    {withdraw.type}
                                  </td>
                                  <td data-label="Transaction Amount">
                                    {withdraw.amount}
                                  </td>
                                  <td data-label="Withdraw Fee">
                                    {withdraw.type === "withdrawl"
                                      ? withdraw.withdraw_fee
                                      : "-"}
                                  </td>
                                  <td data-label="Created At">
                                    <div className="">
                                      <p className="pm-withdraw">
                                        {
                                          formatDate(withdraw.created_at)
                                            .formattedDate
                                        }
                                      </p>
                                      <p className="pm-withdraw">
                                        {
                                          formatDate(withdraw.created_at)
                                            .formattedTime
                                        }
                                      </p>
                                    </div>
                                  </td>
                                  <td data-label="">
                                    {withdraw.status === "Requested" ? (
                                      <button
                                        href="#"
                                        className="transaction-required"
                                      >
                                        <div className="transaction-required-text">
                                          Requested
                                        </div>
                                      </button>
                                    ) : withdraw.status === "Canceled" ? (
                                      <button
                                        href="#"
                                        className="button-status-cancel"
                                      >
                                        <div className="button-status-cancel-text">
                                          Cancelled
                                        </div>
                                      </button>
                                    ) : withdraw.status === "Served" ? (
                                      <button
                                        href="#"
                                        className="button-status-served"
                                      >
                                        <div className="button-status-served-text">
                                          Served
                                        </div>
                                      </button>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td>
                                    {withdraw.transaction_id ? (
                                      <a
                                        href={`https://tronscan.org/#/transaction/${withdraw.transaction_id}`}
                                        className="button-trans-mang"
                                      >
                                        <div className="button-trans-mang-text">
                                          Details
                                        </div>
                                      </a>
                                    ) : (
                                      "--"
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {withdraw.status === "Served" ||
                                    withdraw.status === "Canceled" ? (
                                      "-"
                                    ) : (
                                      <button
                                        className="button-cancel"
                                        onClick={() =>
                                          handleCancelWithdraw(withdraw)
                                        }
                                      >
                                        <div className="button-cancel-text">
                                          Cancel
                                        </div>
                                      </button>
                                    )}
                                  </td>
                                  <div className="dotline-for-withdraw">
                                    <div className="dotline-for-withdraw-2"></div>
                                  </div>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="8">
                                  <div className="no-data-message">
                                    <p>No withdraw available</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </Tab>
            </Tabs>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  transactions: state.transactions,
  withdrawls: state.withdrawls,
  deposits: state.deposits,
  showLoading: state.showLoading,
  error: state.error,
});

export default connect(mapStateToProps)(Transactions2);
