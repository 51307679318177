import { Dropdown, Button, Modal, Container, Row, Col } from "react-bootstrap";

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import QRCode from "qrcode.react";

import { connect, useDispatch } from "react-redux";
import swal from "sweetalert";
import { withdrawPartnerAction, sendVerificationCodeAction } from "../../store/actions/PartnersActions";
import { Bars } from "react-loader-spinner";

const TopUp = () => {
  const [topUpModal, setTopUpModal] = useState(false);
  const [withDrawModal, setWithDrawModal] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const [withdrawCode, setWithdrawCode] = useState();
  const partner = JSON.parse(localStorage.userDetails);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailTimer, setEmailTimer] = useState(60); // State to track remaining time
  const [selectedDeposit, setSelectedDeposit] = useState(false);
  const [selectedWithdraw, setSelectedwithdraw] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  let errorsObj = { withdraw_amount: "", destination_wallet: "", verification_code: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const notifyTopFullWidth = (msg) => {
    toast.warn(`✔️ Please check your Email for Withdraw Verification Code.`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function handleSendVerificationCode() {
    // console.log(localStorage.userDetails);
    notifyTopFullWidth();
    dispatch(sendVerificationCodeAction());
    setSendEmail(true);
    setTimeout(() => {
      setSendEmail(false);
    }, 60000);
  }
  function handleWithdraw(e) {
    e.preventDefault();
    // console.log(localStorage.userDetails);
    let error = false;
    const errorObj = { ...errorsObj };
    const userDetails = JSON.parse(localStorage.userDetails);
    if (userDetails.balance < withdrawAmount)
      return swal("Oops", `You have ${userDetails.balance} USDT in your account. Please withdraw with in your available balance.`, "error", { button: "Try Again!" });

    // if (withdrawAmount < 50) return swal("Sorry", "Minimum withdrawlable amount is 50 USDT.", "error", { button: "Try Again!" });

    if (!withdrawAmount) {
      errorObj.withdraw_amount = "Plese enter withdraw amount";
      error = true;
      setErrors(errorObj);
    }
    if (withdrawAmount < 50) {
      errorObj.withdraw_amount = "Minimum withdrawlable amount is 50 USDT.";
      error = true;
      setErrors(errorObj);
    }
    if (!walletAddress) {
      errorObj.destination_wallet = "Plese enter reciever wallet address.";
      error = true;
      setErrors(errorObj);
    }

    if (!withdrawCode) {
      errorObj.verification_code = "Plese enter verification code";
      error = true;
      setErrors(errorObj);
    }
    if (error) {
      return;
    }
    setIsLoading(true);
    dispatch(
      withdrawPartnerAction(withdrawAmount, walletAddress, withdrawCode, () => {
        setIsLoading(false);
        setWithdrawCode("");
        setWalletAddress("");
        setWithdrawAmount("");
        handleCloseSidebar();
      })
    );
  }
  const closeTopUpModal = () => {
    setTopUpModal(false);
  };
  function handleTopUp(e) {
    e.preventDefault();
    // dispatch(topUpPartnerAction(topUpAmount));
    // setTimeout(() => {
    //   dispatch(getPartnersAction());
    // }, 2000);
    closeTopUpModal();
  }
  const openTopUpModal = () => {
    // console.log(partner);
    // if (!partner.wallet_address) return notifyTopCenter();
    setTopUpModal(true);
  };
  const openWithdrawModal = (partner) => {
    setWithDrawModal(true);
  };
  const closeWithDrawModal = () => {
    setWithDrawModal(false);
  };

  // useEffect to update the timer every second
  useEffect(() => {
    // let user = JSON.parse(localStorage.userDetails);
    // setPartner(user);
    // timer every second
    let timerInterval;
    if (sendEmail) {
      timerInterval = setInterval(() => {
        setEmailTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      setEmailTimer(60);
      clearInterval(timerInterval);
    };
  }, [sendEmail]);

  useEffect(() => {
    setIsLoading(false);
  }, []);
  const handleDepositClick = () => {
    setSelectedDeposit(true);
  };
  const handleWithdrawClick = () => {
    setSelectedwithdraw(true);
  };

  const handleCloseSidebar = () => {
    setSelectedDeposit(false);
    setSelectedwithdraw(false);
  };
  const handleCopyToClipboard = () => {
    console.log("Copying address:", partner.wallet_address); // Debug: check if the function is triggered and if the wallet address is correct
    navigator.clipboard
      .writeText(partner.wallet_address)
      .then(() => {
        console.log("Copied successfully");
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 10000); // Reset after 10 seconds
      })
      .catch((err) => {
        console.error("Failed to copy:", err); // Debug: catch any error in copying
      });
  };

  return (
    <>
      {/*******************************Deposit-Up Modal *****************************************/}
      <div className={`sidebar-tier-deposit ${selectedDeposit ? "open" : ""}`}>
        <div className="tier-main-headind">
          <div className="side-model-heading">Deposit</div>
          <button onClick={handleCloseSidebar} className="tier-close-button">
            &times;
          </button>
        </div>
        <form onSubmit={(e) => handleTopUp(e)}>
          <div>
            <div>
              <div>
                <label htmlFor="author" className="side-modal-sub">
                  Wallet Address
                </label>

                <div className="qr-code">
                  <QRCode className="justify-content-center align-items-center" value={partner.wallet_address} />
                </div>
                <div className="address-text">
                  <input type="text" value={partner.wallet_address} className="address-text1" name="partnerName" disabled />
                </div>
              </div>
            </div>
            <button type="button" className="btn-topUp-deposit" onClick={handleCopyToClipboard}>
              <div className="btn-topUp-text" name="submit">
                {isCopied ? "Copied" : "Copy"}
              </div>
            </button>
            <p className="deposit-intra">Please transfer USDT(TRC20) from your preferred exchange to the above wallet address.</p>
          </div>
        </form>
      </div>

      {/******************************* Withdraw Modal *****************************************/}
      <div className={`sidebar-tier ${selectedWithdraw ? "open" : ""}`}>
        <div className="tier-main-headind">
          <div className="side-model-heading">Withdraw</div>
          <button onClick={handleCloseSidebar} className="tier-close-button">
            &times;
          </button>
        </div>
        <div className="withdraw-model">
          <form className="comment-form" onSubmit={(e) => handleWithdraw(e)}>
            <div className="row">
              <div className="col-lg-8">
                <div className="form-group mb-3">
                  <label htmlFor="author" className="text-black font-w600 input-text-withraw">
                    {" "}
                    Withdraw Amount <span className="required">*</span>
                    {""}
                  </label>
                  <input
                    type="Number"
                    value={withdrawAmount}
                    className="form-control input-customize"
                    onChange={(e) => setWithdrawAmount(e.target.value)}
                    name="partnerName"
                    placeholder="Enter Amount"
                  />
                  <div className="text-danger-amount ">2 USDT network fee</div>
                  {errors.withdraw_amount && <div className="text-danger fs-12">{errors.withdraw_amount}</div>}
                </div>
              </div>
              <div className="col-lg-8">
                <div className="form-group mb-3">
                  <label htmlFor="author" className="text-black font-w600 input-text-withraw">
                    {" "}
                    Network <span className="required">*</span>
                    {""}
                  </label>
                  <input type="Text" value={"TRON TRC20"} className="form-control input-customize" name="partnerName" placeholder="" disabled />
                  {/* <input type="text" value={pool._id} style={{ visibility: "hidden" }} name="poolId" placeholder="POOL_ID" /> */}

                  {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                </div>
              </div>
              <div className="col-lg-8">
                <div className="form-group mb-3">
                  <label htmlFor="author" className="text-black font-w600 input-text-withraw">
                    {" "}
                    Reciever Address <span className="required">*</span>
                    {""}
                  </label>
                  <input
                    type="Text"
                    value={walletAddress}
                    className="form-control input-customize"
                    onChange={(e) => setWalletAddress(e.target.value)}
                    name="partnerName"
                    placeholder="Your Wallet Address"
                  />
                  {errors.destination_wallet && <div className="text-danger fs-12">{errors.destination_wallet}</div>}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-1  align-items-end">
                  <label htmlFor="author" className="text-black font-w600 input-text-withraw">
                    {" "}
                    Verification Code <span className="required">*</span>
                    {""}
                  </label>
                  <div className="verification-text input-text-withraw">
                    You will receive withdraw verification code on <span className="span">"{JSON.parse(localStorage.userDetails).email}"</span>
                  </div>
                  <div className="">
                    <input type="Text" value={withdrawCode} className="form-control input-customize" onChange={(e) => setWithdrawCode(e.target.value)} name="partnerName" placeholder="Code" />
                    {errors.verification_code && <div className="text-danger fs-12">{errors.verification_code}</div>}

                    <div className="col-lg-4"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                {!sendEmail && (
                  <div className="text-center ">
                    <Button type="button" onClick={handleSendVerificationCode} className="btn btn-primary mb-1 ms-1">
                      Send Code
                    </Button>
                  </div>
                )}
                {sendEmail && (
                  <div className="text-center">
                    <Button type="button" disabled onClick={handleSendVerificationCode} className="btn btn-primary mb-1 ms-1">
                      Send Code ({emailTimer} seconds)
                    </Button>
                  </div>
                )}
              </div>

              <button className="btn-topUp">
                {isLoading ? (
                  <button type="button" className="signup-next-button" disabled>
                    <Bars height="15" width="25" color="#ffffff" ariaLabel="bars-loading" visible={true} />
                  </button>
                ) : (
                  <div type="submit" value="Confirm" className="btn-topUp-text" name="submit">
                    Confirm
                  </div>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="col" style={{ zIndex: 1 }}>
        <div className="">
          <button as="a" href="#" className="deposit-button" onClick={() => handleDepositClick()}>
            <div className="deposit-button-text">Deposit</div>
          </button>
        </div>
      </div>
      <div className="col" style={{ zIndex: 1 }}>
        <div className="">
          <button as="a" href="#" className="withdraw-button" onClick={handleWithdrawClick}>
            <div className="withdraw-button-text">Withdraw</div>
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  showLoading: state.showLoading,
  error: state.error,
});

export default connect(mapStateToProps)(TopUp);
