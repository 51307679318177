import React, { useState, useEffect, useRef, Fragment, Suspense } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import { calculateProfitPercentage, calculateProfitPercentage2 } from "../../services/PartnersService";
import swal from "sweetalert";
import { connect, useDispatch } from "react-redux";
import { getAllActivePoolsAction } from "../../store/actions/PoolsActions";
import { createStakingsAction } from "../../store/actions/ManageStakingsAction";
import AnimationData from "../../images/Animation - 1727789718486 (1).gif";
import { Dropdown, Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import Intercom from "@intercom/messenger-js-sdk";
import CardRow from "./card2";

const Pools = (props) => {
  const [days, setDays] = useState();
  const [tier, setTier] = useState();
  const [accountType, setAccountType] = useState();
  const [isIslamic, setIsIslamic] = useState();
  const [partner, setPartner] = useState();
  const [amount, setAmount] = useState();
  const [profit, setProfit] = useState();
  let errorsObjcal = { days: "", amount: "" };
  const [errorsCal, setErrorsCal] = useState(errorsObjcal);
  const options3 = [
    { value: "10", label: "Silver Elite" },
    { value: "30", label: "Gold Elite" },
    { value: "60", label: "Platinum Elite" },
    { value: "90", label: "Titanium Elite" },
    { value: "180", label: "Diamond Elite" },
  ];

  const progressBarRef = useRef(null);

  const reduxDispatch = useDispatch();
  const [autoStakIsChecked, setAutoStakIsChecked] = useState(false);
  const [specialPoolModal, setSpecialPoolModal] = useState(false);

  const [normalModalVisibility, setNormalModalVisibility] = useState([]);
  const [specialModalVisibility, setSpecialModalVisibility] = useState([]);
  const user = localStorage.userDetails ? JSON.parse(localStorage.userDetails) : "";
  const partners = JSON.parse(localStorage.userDetails);

  const hiddenTextareaRef = useRef(null);
  const [copyButtonText, setCopyButtonText] = useState("Copy");

  const toggleSwitch = () => {
    setAutoStakIsChecked(!autoStakIsChecked);
  };
  const openModal = (index, type) => {
    if (type === "normal") {
      const updatedVisibility = [...normalModalVisibility];
      updatedVisibility[index] = true;
      setNormalModalVisibility(updatedVisibility);
    } else {
      const updatedVisibility = [...specialModalVisibility];
      updatedVisibility[index] = true;
      setSpecialModalVisibility(updatedVisibility);
    }
  };

  const closeModal = (index, type) => {
    if (type === "normal") {
      const updatedVisibility = [...normalModalVisibility];
      updatedVisibility[index] = false;
      setNormalModalVisibility(updatedVisibility);
    } else {
      const updatedVisibility = [...specialModalVisibility];
      updatedVisibility[index] = false;
      setSpecialModalVisibility(updatedVisibility);
    }
  };

  // const openModal = (index) => {
  //   const updatedVisibility = [...normalModalVisibility];
  //   updatedVisibility[index] = true;
  //   setNormalModalVisibility(updatedVisibility);
  // };

  // const closeModal = (index) => {
  //   const updatedVisibility = [...normalModalVisibility];
  //   updatedVisibility[index] = !updatedVisibility[index];
  //   setNormalModalVisibility(updatedVisibility);
  // };
  // const specialPoolModalOpen = () => {
  //   setSpecialPoolModal(true);
  // };

  // const specialPoolModalClose = () => {
  //   setSpecialPoolModal(false);
  // };
  const navigate = useNavigate();

  //To get staking amount for staking creation
  const [stakeAmount, setStakeAmount] = useState();
  let errorsObj = { amount: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    reduxDispatch(getAllActivePoolsAction());
    // console.log(user);
    if (user) {
      Intercom({
        app_id: "kv0kcopk",
        // user_id: user.id,
        id: user.id,
        image_url: user.image_url,
        name: user.name,
        account_type: user.account_type,
        email: user.email,
        gender: user.gender,
        balance: user.balance,
        is_verified: user.is_verified,
        tier: user.tier,
        role: user.role,
        total_staked: user.total_staked,
        referral_code: user.referral_code,
        wallet_address: user.wallet_address,
        phone: user.phone,
        country: user.country,
        is_active: user.is_active,
        is_complete: user.is_complete,
        referral_level: user.referral_level,
        isPass: user.password ? true : false,
      });
    }

    return () => {
      // Cleanup if needed when the component is unmounted
      if (window.Intercom) {
        window.Intercom("shutdown");
      }
    };
  }, []);

  function handleCreateStaking(e, index, type) {
    e.preventDefault();
    let user = JSON.parse(localStorage.getItem("userDetails"));
    if (!user.is_verified) {
      toast.warn("❗ Please verify your email first to enable your staking ability!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
      // setIsVerifiedWaring(false);
    }
    const form = e.target;
    const poolId = form.elements.poolId.value;
    const minStake = form.elements.minStake.value;
    const autoStake = form.elements.autoStake.value;

    let error = false;
    const errorObj = { ...errorsObj };
    if (stakeAmount === "") {
      errorObj.amount = "Staking Amount must be valid number...";
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }
    if (stakeAmount === "0") {
      errorObj.amount = "Staking Amount must be greater than 0...";
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }

    if (JSON.parse(stakeAmount) > user.balance.toFixed(3)) {
      errorObj.amount = "Insufficient Balance.";
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }

    if (JSON.parse(stakeAmount) < minStake) {
      errorObj.amount = `Please enter an amount greater than ${minStake}`;
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }
    //console.log(e);

    //reduxDispatch(createStakingsAction());
    reduxDispatch(createStakingsAction(stakeAmount, poolId, autoStake, navigate));
    setStakeAmount("");
    closeModal(index);
    /* {
      Model should be closed here.
    } */
  }

  function handleStakeAll(e, index) {
    e.preventDefault();
    if (stakeAmount === 0 || stakeAmount === "" || stakeAmount === undefined) {
      setStakeAmount(parseFloat(user.balance.toFixed(3)));
    } else {
      // If stakeAmount is not 0, reset it to 0
      setStakeAmount("");
    }
  }
  function openInfoAlert() {
    swal("Info!", "If turned on this will automatically stake again when staking period ends.", "success");
  }

  const handlePoolSelect = (selectedOption) => {
    setDays(parseFloat(selectedOption.value));
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.userDetails);
    setTier(user.tier);
    setAccountType(user.account_type);
    setPartner(user);
    setIsIslamic(user.account_type === "Islamic" ? true : false);
  }, [profit]);

  function getProfitRange(rangeString, amount) {
    // Remove the '%' character and split the string based on ' - ' to get the range
    const [min, max] = rangeString.replace(/%/g, "").split(" - ").map(Number);

    // Generate a random number between the min and max values
    const randomNumber = Math.random() * (max - min) + min;
    const lowerBoundProfit = (min * amount) / 100;
    const upperBoundProfit = (max * amount) / 100;
    const profitRange = lowerBoundProfit.toFixed(2) + " USDT - " + upperBoundProfit.toFixed(2) + " USDT";

    return profitRange;
  }
  const handleCopyClick = () => {
    if (hiddenTextareaRef.current) {
      hiddenTextareaRef.current.select(); // Select the textarea's text
      document.execCommand("copy"); // Copy the selected text to the clipboard

      setCopyButtonText("Copied");
      setTimeout(() => {
        setCopyButtonText("Copy");
      }, 10000);
    }
  };

  const handleCalculateProfit = (e) => {
    e.preventDefault();
    let profitPercentage;
    const errorObj = { ...errorsObj };
    let error = false;
    if (!days) {
      errorObj.days = "Please select Pool.";
      error = true;
    }
    if (!amount) {
      errorObj.amount = "Please Enter amount.";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    const profitPercent = calculateProfitPercentage(tier, days, accountType);
    if (partner.account_type === "Normal") {
      return setProfit((amount * parseFloat(profitPercent)) / 100);
    } else if (partner.account_type === "Islamic") {
      return setProfit(getProfitRange(profitPercent, amount));
    }
    // setProfit((amount * profitPercentage) / 100);
    return (amount * profitPercentage) / 100;
  };
  const referralLink = `${process.env.REACT_APP_MYARBIT_DashboardUrl}register?ref=${partners.referral_code}`;
  const pools = [
    {
      name: "Gold Elite",
      days: 180,
      minStake: 50,
      profitRange: "63.00% - 77.00%",
      multiplier: "X2",
      badgeText: "Special",
    },
    {
      name: "Gold Elite",
      days: 180,
      minStake: 50,
      profitRange: "63.00% - 77.00%",
      multiplier: "X2",
      badgeText: "Special",
    },
  ];

  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <CardRow />
        <div className="">
          <div className="display-flex">
            {/*-------------------------------------------Normal Pools ------------------------------------------ */}
            <div className="dashboard-card-home">
              <div className="card-header">
                <h4 className="card-title">Investment Pools List</h4>
              </div>
              <div className="card-body-ivest">
                <div className=" recentOrderTable">
                  <div className="table-container">
                    <table className="table verticle-middle ">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Duration(days)</th>
                          <th scope="col">Min-Stake</th>
                          <th scope="col">Profit</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.pools.pools.map((pool, index) => (
                          <tr key={index}>
                            <td>{pool.name}</td>
                            <td data-label="duration">{pool.duration}</td>
                            <td data-label="Min-Stake">{pool.min_stake}</td>
                            <td className="Transaction-Type-pool" data-label="Profit">
                              {calculateProfitPercentage2(pool, user.tier, pool.duration, user.account_type)}
                            </td>
                            <td>
                              <button as="a" href="#" className="dashboard-card-home-button" onClick={() => openModal(index, "normal")}>
                                <span className="dashboard-card-home-buttontext"> Stake</span>
                              </button>
                              <Modal className="modal fade model-dashboard-card-home" show={normalModalVisibility[index]} onHide={() => closeModal(index, "normal")}>
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">Specify Staking Amount</h5>
                                    <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeModal(index, "normal")}>
                                      <span>×</span>
                                    </Button>
                                  </div>
                                  <div className="modal-body">
                                    <form className="comment-form" onSubmit={(e) => handleCreateStaking(e, index, "normal")}>
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <div className="form-group mb-3">
                                            <label htmlFor="author" className="text-black font-w600">
                                              {" "}
                                              Amount <span className="required">*</span>
                                              {""}
                                            </label>
                                            <span className="text-green font-w300"> &nbsp;Available Balance - {user.balance.toLocaleString()}</span>
                                            {""}

                                            <input
                                              type="Number"
                                              className="form-control"
                                              value={stakeAmount}
                                              onChange={(e) => setStakeAmount(e.target.value)}
                                              name="stakeAmount"
                                              placeholder="Enter Staking Amount"
                                            />
                                            {errors.amount && <div className="text-danger fs-12">{errors.amount}</div>}
                                            <input type="text" value={pool._id} style={{ visibility: "hidden" }} onChange={(e) => setStakeAmount(e.target.value)} name="poolId" placeholder="Author" />
                                            <input
                                              type="text"
                                              value={pool.min_stake}
                                              style={{ visibility: "hidden" }}
                                              onChange={(e) => setStakeAmount(e.target.value)}
                                              name="minStake"
                                              placeholder="Author"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-4 col-4">
                                          <div className="input-group-append">
                                            <button type="button" className="btn btn-primary" onClick={handleStakeAll}>
                                              Stake All
                                            </button>
                                          </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-4 col-4">
                                          <div className="form-group mb-3">
                                            <input type="submit" value="Confirm Staking" className="submit btn btn-primary" name="submit" />
                                          </div>
                                        </div>
                                        <div class="form-check form-switch">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckChecked"
                                            name="autoStake"
                                            value={autoStakIsChecked}
                                            checked={autoStakIsChecked}
                                            onChange={toggleSwitch}
                                          />
                                          <label class="form-check-label" for="flexSwitchCheckChecked">
                                            Turn Auto Restake On
                                          </label>
                                          <a as="a" href="#" onClick={() => openInfoAlert()}>
                                            <i class="la la-question" style={{ color: "#000000" }}></i>
                                          </a>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </Modal>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*-------------------------------------------Special Pools ------------------------------------------ */}
            <div className="calculate-profit-1">
              <div className="special-pool-home">
                <div className="special-pool-home-heading">
                  <div>Special Pools</div>
                  <a className="special-pool-home-sell-all" href="/pools2">
                    See All
                  </a>
                </div>
                {props.pools.specialPool.length > 0 &&
                  props.pools.specialPool.slice(0, 2).map((pool, index) => (
                    <div className="special-pool-home-card" key={index}>
                      <div className="special-pool-home-multiplier-container">
                        <span className="special-pool-home-multiplier">
                          <span className="special-x2">X2</span>
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                          <path d="M0 0H34V34L17 23L0 34V0Z" fill="#FDC82B" fill-opacity="0.5" />
                        </svg>
                      </div>
                      <div className="special-pool-home-header">
                        <h6>
                          {pool.name}
                          <span className="special-pool-home-badge">Special</span>
                        </h6>
                      </div>
                      <div className="special-pool-home-details">
                        <p>
                          Days{" "}
                          <span>
                            <strong>{pool.duration}</strong>
                          </span>
                        </p>
                        <p>
                          Min - Stake{" "}
                          <span>
                            <strong>{pool.min_stake}</strong>
                          </span>
                        </p>
                        <p>
                          Profit{" "}
                          <span className="special-pool-home-profit">
                            <strong>{calculateProfitPercentage2(pool, user.tier, pool.duration, user.account_type)}</strong>{" "}
                          </span>
                        </p>
                      </div>
                      <button className="special-pool-home-btn" onClick={() => openModal(index, "special")}>
                        Special Stake
                      </button>
                      <Modal className="modal fade model-dashboard-card-home" show={specialModalVisibility[index]} onHide={() => closeModal(index, "special")}>
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Special Pool Staking Amount</h5>
                            <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeModal(index, "special")}>
                              <span>×</span>
                            </Button>
                          </div>
                          <div className="modal-body">
                            <form className="comment-form" onSubmit={(e) => handleCreateStaking(e, index, "special")}>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="author" className="text-black font-w600">
                                      {" "}
                                      Amount <span className="required">*</span>
                                      {""}
                                    </label>
                                    <span className="text-green font-w300"> &nbsp;Available Balance - {user.balance.toLocaleString()}</span>
                                    {""}

                                    <input
                                      type="Number"
                                      className="form-control"
                                      value={stakeAmount}
                                      onChange={(e) => setStakeAmount(e.target.value)}
                                      name="stakeAmount"
                                      placeholder="Enter Staking Amount"
                                    />
                                    {errors.amount && <div className="text-danger fs-12">{errors.amount}</div>}
                                    <input type="text" value={pool._id} style={{ visibility: "hidden" }} onChange={(e) => setStakeAmount(e.target.value)} name="poolId" placeholder="Author" />
                                    <input type="text" value={pool.min_stake} style={{ visibility: "hidden" }} onChange={(e) => setStakeAmount(e.target.value)} name="minStake" placeholder="Author" />
                                  </div>
                                </div>
                                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-4 col-4">
                                  <div className="input-group-append">
                                    <button type="button" className="btn btn-primary" onClick={handleStakeAll}>
                                      Stake All
                                    </button>
                                  </div>
                                </div>
                                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-4 col-4">
                                  <div className="form-group mb-3">
                                    <input type="submit" value="Confirm Staking" className="submit btn btn-primary" name="submit" />
                                  </div>
                                </div>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    name="autoStake"
                                    value={autoStakIsChecked}
                                    checked={autoStakIsChecked}
                                    onChange={toggleSwitch}
                                  />
                                  <label class="form-check-label" for="flexSwitchCheckChecked">
                                    Turn Auto Restake On
                                  </label>
                                  <a as="a" href="#" onClick={() => openInfoAlert()}>
                                    <i class="la la-question" style={{ color: "#000000" }}></i>
                                  </a>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  ))}
              </div>
            </div>

            <div className="calculate-profit">
              <form onSubmit={handleCalculateProfit}>
                <div className="calculate-profit-heading">Calculate Profit</div>
                <div className="line-div-profit" />
                <div>
                  <div className="heading-pool-profit">Select Pool</div>
                  <Select
                    options={options3}
                    onChange={handlePoolSelect}
                    className="input-profit-select"
                    defaultValue={""}
                    isSearchable={true}
                    placeholder={"Select Pool"}
                    styles={{
                      container: (base) => ({ ...base, height: "50px" }),
                    }} // Adjust width here
                  />
                  {errors.days && <div className="text-danger fs-12">{errors.days}</div>}
                </div>
                <div>
                  <div className="heading-pool-profit">Amount</div>
                  <input type="Number" onChange={(e) => setAmount(parseFloat(e.target.value))} className="input-profit" placeholder=" Enter Amount" />
                  {errors.amount && <div className="text-danger fs-12">{errors.amount}</div>}
                </div>
                <button type="submit" className="calculate-profit-button">
                  <div className="calculate-profit-button-text">Calculate</div>
                </button>
                <div className="line-div-profit" />
                <span className="expected-profit">Expected Profit: </span>
                <span className="expected-profit-text">{profit}</span>
              </form>
            </div>
          </div>

          <div className="custom-card-2 mobile-profit-hide">
            <div className="custom-card-2-balanceHideShow">
              <div className="custom-card-2-balanceHideShowChild" />
              <div className="custom-card-2-Profit-card" />
              <div className="custom-card-2-balance">Referral Link</div>

              <div className="custom-card-2-usdtParent">
                <div className="custom-card-profit">Earn the Profits</div>
                <div className="custom-card-2-link">
                  <textarea
                    ref={hiddenTextareaRef}
                    value={referralLink}
                    readOnly
                    style={{
                      position: "absolute",
                      left: "-9999px",
                      opacity: 0,
                    }} // Hide textarea off-screen
                  />
                  {referralLink}
                </div>
                <div className="custom-card-2-rectangleParent"></div>
              </div>
              <div className="custom-card-2-balanceHideShowInner">
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="64" height="64" rx="10" fill="white" />
                  <path
                    d="M50.0594 50H29.1416V48.5156H48.4832C48.704 44.0845 46.7542 39.5917 42.4808 37.2098C41.5488 38.0946 40.5033 38.8443 39.2805 39.2938C35.5555 40.6631 32.1657 40.0638 29.1452 37.4841C28.876 37.2543 28.7008 37.2004 28.4175 37.4195C27.7701 37.9223 27.106 38.4031 26.4383 38.9008L25.3872 37.8907C26.4947 36.808 27.7391 35.9993 29.0858 35.2712C29.2949 35.4904 29.4914 35.6998 29.6915 35.906C32.9864 39.3035 38.1843 39.3403 41.5305 35.9902C42.2595 35.2603 42.2591 35.2612 43.1527 35.7924C47.7136 38.5032 49.8893 42.5861 50.0587 47.8179C50.079 48.5369 50.0594 49.2559 50.0594 50Z"
                    fill="#222222"
                  />
                  <path
                    d="M35.6366 18.7159C40.5557 18.6972 44.5721 22.7065 44.5795 27.6433C44.587 32.5802 40.6086 36.5789 35.6663 36.6037C30.7659 36.6282 26.7169 32.5934 26.7075 27.6753C26.6982 22.7572 30.7104 18.7349 35.6366 18.7159ZM43.0551 27.6756C43.0641 23.5885 39.767 20.2639 35.6795 20.2384C31.6214 20.2133 28.2536 23.5523 28.2359 27.6188C28.2165 31.7114 31.5659 35.0828 35.6473 35.0818C39.7286 35.0808 43.046 31.7631 43.0551 27.6756Z"
                    fill="#222222"
                  />
                  <path
                    d="M20.0134 34.3127C22.0304 36.2426 24.0099 38.1323 25.9784 40.0334C26.0658 40.118 26.1123 40.3239 26.0778 40.4439C25.7648 41.5373 25.7583 41.5353 24.6082 41.5353H23.1437C23.267 44.5346 24.3852 46.9262 26.8913 48.6282C26.7271 49.0222 26.5819 49.4098 26.3999 49.7796C26.3543 49.8719 26.174 49.9764 26.0829 49.9564C22.1782 49.1113 19.0456 47.2486 17.4779 43.358C17.292 42.8957 17.145 42.4187 17.0384 41.9319C16.9677 41.6076 16.8212 41.5198 16.5133 41.5298C15.9344 41.5492 15.3538 41.5224 14.7749 41.5405C14.4812 41.5498 14.3176 41.4737 14.2008 41.1736C13.8267 40.2106 13.8135 40.2187 14.5628 39.5035C16.253 37.8926 17.9418 36.2804 19.6294 34.6671C19.7446 34.5574 19.8634 34.4512 20.0134 34.3127ZM16.3574 39.9014L16.3984 40.0128C16.8731 40.0128 17.3485 40.0302 17.8219 40.0066C18.1446 39.9908 18.254 40.0993 18.2982 40.4281C18.7177 43.541 20.2509 45.8913 23.0827 47.3364C23.1857 47.3886 23.2989 47.4203 23.5116 47.5013C21.898 45.1965 21.5231 42.6945 21.586 40.0102H23.763C22.4399 38.7436 21.2284 37.5853 20.0037 36.4146L16.3574 39.9014Z"
                    fill="#222222"
                  />
                  <path
                    d="M31.246 18.0601L29.9342 18.7023C29.1009 17.1672 27.8808 16.1296 26.1982 15.7043C24.9773 15.3958 23.7688 15.4891 22.6045 15.9799C20.3042 16.9496 18.8475 19.4439 19.1692 21.8539C19.3693 23.3525 20.0312 24.6098 21.1955 25.5789C22.3598 26.548 23.7188 26.9569 25.2649 26.8969C25.2972 27.4051 25.3268 27.8798 25.3565 28.3491C22.9359 28.7295 18.9808 27.1947 17.8574 23.0721C16.8489 19.368 18.9527 15.5084 22.6564 14.3428C26.5438 13.1197 30.0675 15.3593 31.246 18.0601Z"
                    fill="#222222"
                  />
                  <path
                    d="M14.4403 36.646L13 36.4266C13.3024 32.9736 15.5449 29.002 19.3993 27.2009C21.1281 29.2663 23.3212 30.1202 26.0656 29.5728L26.4548 31.0347C23.6478 31.6424 21.2185 31.0121 19.0837 29.101C16.3497 30.8759 14.9566 33.4731 14.4403 36.646Z"
                    fill="#222222"
                  />
                </svg>
              </div>
              <div className="custom-card-2-copy" alt="" src="Group 1707478407.svg" onClick={handleCopyClick}>
                {copyButtonText}
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  pools: state.pools,
  specialPool: state.specialPool,
  isLoading: state.isLoading,
  error: state.error,
});

export default connect(mapStateToProps)(Pools);
