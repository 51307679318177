import React, { useState, useEffect, Fragment, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import { getPartnerEarnigsAction } from "../../store/actions/EarningsActions";
import { Tabs, Tab, Pagination } from "react-bootstrap";

const Earnings2 = (props) => {
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const totalPages = 3; // Example total pages

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const [value, setValue] = useState(8);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [key, setKey] = useState("profits");
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(loadingToggleAction(true));
    dispatch(getPartnerEarnigsAction());
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);

    // Format date as "May 14, 2024"
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    // Format time as "2:03:26 PM"
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    return { formattedDate, formattedTime };
  }

  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <div className="">
          <div className="pools-container">
            <h4 className="pool-heading">Earnings</h4>
            <h2 className="text-pools">
              Dashboard/<span className="text-ppols-1">Earnings </span>
            </h2>
          </div>
          <div className="main-div">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="custom-tabs-reward"
            >
              <Tab eventKey="profits" title="Profits" className="custom">
                <div className="manage-profit">
                  <div className="card-body">
                    <div className="table-responsive recentOrderTable">
                      <div className="table-container">
                        <table className="table verticle-middle table-responsive-md">
                          <thead>
                            <tr>
                              <th scope="col">Type</th>
                              <th scope="col">Invested Amount</th>
                              <th scope="col">Earned</th>
                              <th scope="col">Pool</th>
                              <th scope="col">Duration</th>
                              <th scope="col">Tier</th>
                              <th scope="col">Profit</th>
                              <th scope="col">Created At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.profits && props.profits.length > 0 ? (
                              props.profits.map((profit) => (
                                <tr key={profit.id}>
                                  <td className="earning-text">
                                    {profit.type}
                                  </td>
                                  <td data-label="Invested Amount">
                                    {profit.invested_amount}
                                  </td>
                                  <td data-label="Earned">
                                    {profit.earning.toFixed(2)}
                                  </td>
                                  <td data-label="Pool">
                                    {profit.pool_info.name}
                                  </td>
                                  <td data-label="Duration">
                                    {profit.pool_info.duration}
                                  </td>
                                  <td data-label="Tier">{profit.tier}</td>
                                  <td
                                    className="earning-text"
                                    data-label="Profit"
                                  >
                                    {profit.profit_percent}
                                  </td>
                                  <td data-label="Created At">
                                    <div>
                                      <p className="pm">
                                        {
                                          formatDate(profit.created_at)
                                            .formattedDate
                                        }
                                      </p>
                                      <p className="pm">
                                        {
                                          formatDate(profit.created_at)
                                            .formattedTime
                                        }
                                      </p>
                                    </div>
                                  </td>
                                  <div className="dotline-for-mobile-profit"></div>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="8">
                                  <div className="no-data-message">
                                    <p>No profit available </p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="rewards" title="Rewards">
                <div className="manage-reward">
                  <div className="card-body">
                    <div className="table-responsive recentOrderTable">
                      <table className="table verticle-middle table-responsive-md">
                        <thead>
                          <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Invested Amount</th>
                            <th scope="col">Earned</th>

                            <th scope="col">Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.rewards && props.rewards.length > 0 ? (
                            props.rewards.map((earning) => (
                              <tr key={earning.id}>
                                <td className="earning-text">{earning.type}</td>
                                <td data-label="Invested Amount">
                                  {earning.invested_amount}
                                </td>
                                <td data-label="Earned">{earning.earning}</td>
                                <td data-label="Created At">
                                  <div>
                                    <p className="pm">
                                      {
                                        formatDate(earning.created_at)
                                          .formattedDate
                                      }
                                    </p>
                                    <p className="pm">
                                      {
                                        formatDate(earning.created_at)
                                          .formattedTime
                                      }
                                    </p>
                                  </div>
                                </td>
                                <div className="dotline-for-mobile-reward"></div>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">
                                <div className="no-data-message">
                                  <p>No reward available</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>{" "}
                      </table>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="incentives" title="Incentives">
                <div className="manage-Incentives">
                  <div className="card-body">
                    <div className="table-responsive recentOrderTable">
                      <div className="table-container">
                        <table className="table verticle-middle table-responsive-md">
                          <thead>
                            <tr>
                              <th scope="col">Type</th>
                              <th scope="col">Earned</th>
                              <th scope="col">Created At</th>'
                            </tr>
                          </thead>
                          <tbody>
                            {props.incentives && props.incentives.length > 0 ? (
                              props.incentives.map((earning) => (
                                <tr key={earning.id}>
                                  <td className="earning-text">
                                    {earning.type}
                                  </td>
                                  <td data-label="Earned">{earning.earning}</td>
                                  <td data-label="Created at">
                                    <div>
                                      <p className="pm">
                                        {
                                          formatDate(earning.created_at)
                                            .formattedDate
                                        }
                                      </p>
                                      <p className="pm">
                                        {
                                          formatDate(earning.created_at)
                                            .formattedTime
                                        }
                                      </p>
                                    </div>
                                  </td>
                                  <div className="dotline-for-mobile-Incentives"></div>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3">
                                  <div className="no-data-message-1">
                                    <p>No incentive available</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  profits: state.earnings.profits,
  rewards: state.earnings.rewards,
  incentives: state.earnings.incentives,
  isLoading: state.earnings.isLoading,
  error: state.error,
});

export default connect(mapStateToProps)(Earnings2);
