import React, { useState, useEffect, useRef } from "react";
import { Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getTransactionsAction,
  loadingToggleAction,
} from "../../store/actions/TransactionsActions";
import { calculateProfitPercentage } from "../../services/PartnersService";

const Tier = (props) => {
  const [selectedTier, setSelectedTier] = useState(null); // State to store selected tier
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.userDetails); // User details from localStorage

  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(getTransactionsAction());
  }, [dispatch]);

  // Handle tier click to open sidebar and display tier details
  const handleTierClick = (tier) => {
    setSelectedTier(tier);
  };

  // Close the sidebar when clicking close button
  const handleCloseSidebar = () => {
    setSelectedTier(null);
  };

  return (
    <div>
      <div className="Tier-div">
        <div className="custom-navbar-tier tier-navbar1">
          <Nav className="navbar-nav">
            {profitTiers.tiers.map((tier, index) => (
              <Nav.Link
                key={tier.tier}
                className="nav-link-tier"
                href="#"
                onClick={() => handleTierClick(tier)}
              >
                <span className="tier-circle">{tier.tier}</span>
                <span className="tier-text">Tier </span>
                {tier.tier !== 7 && (
                  <>
                    <i className="tier-arrow">
                      <svg
                        width="19"
                        height="36"
                        viewBox="0 0 19 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.25 17.567C7.58333 17.7594 7.58333 18.2406 7.25 18.433L2.75 21.0311C2.41667 21.2235 2 20.983 2 20.5981L2 15.4019C2 15.017 2.41667 14.7765 2.75 14.9689L7.25 17.567Z"
                          fill="white"
                        />
                        <line
                          x1="18.25"
                          y1="1.09278e-08"
                          x2="18.25"
                          y2="36"
                          stroke="white"
                          strokeOpacity="0.5"
                          strokeWidth="0.5"
                        />
                      </svg>
                    </i>
                    <span className="vertical-line"></span>
                  </>
                )}
              </Nav.Link>
            ))}
          </Nav>
        </div>
      </div>

      {selectedTier && (
        <div className={`sidebar-tier ${selectedTier ? "open" : ""}`}>
          <div className="tier-main-headind">
            <h3>Tier {selectedTier.tier}</h3>
            <button onClick={handleCloseSidebar} className="tier-close-button-1">
              &times;
            </button>
          </div>

          <div className="tier-balance">Balance: {selectedTier.balance}</div>

          {selectedTier.plans.map((plan, index) => (
            <div key={index} className="tier-rectangle-parent">
              <div className="tier-group-child" />

              <div className="tier-rank">{plan.pool}</div>
              <div className="tier-days">{plan.investment_period} Days</div>
              <div className="tier-div1">
                {calculateProfitPercentage(
                  selectedTier.tier,
                  plan.investment_period,
                  user.account_type
                )}
              </div>
              <div className="return-on-investment">Return On Investment</div>
              <div className="tier-group-item " />
              <div className="tier-group-child1" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Tier;

const profitTiers = {
  tiers: [
    {
      tier: 1,
      balance: "0 - 500 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "2.5%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "9%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "20%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "33%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "70%",
        },
      ],
    },
    {
      tier: 2,
      balance: "501 - 2,000 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "2.75%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "9.9%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "22%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "36.3%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "77%",
        },
      ],
    },
    {
      tier: 3,
      balance: "2,001 - 5,000 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "3%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "10.8%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "24%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "39.6%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "84%",
        },
      ],
    },
    {
      tier: 4,
      balance: "5,001 - 10,000 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "3.25%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "11.7%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "26%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "42.9%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "91%",
        },
      ],
    },
    {
      tier: 5,
      balance: "10,001 - 25,000 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "3.5%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "12.6%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "28%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "46.2%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "98%",
        },
      ],
    },
    {
      tier: 6,
      balance: "25,001 - 50,000 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "3.75%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "13.5%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "30%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "49.5%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "105%",
        },
      ],
    },
    {
      tier: 7,
      balance: "50,000+ USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "4%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "14.4%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "32%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "52.8%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "112%",
        },
      ],
    },
  ],
};
