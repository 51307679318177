import React, { useState, useEffect, useRef, Fragment, Suspense } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import swal from "sweetalert";
import { connect, useDispatch } from "react-redux";
import { getAllActivePoolsAction } from "../../store/actions/PoolsActions";
import { createStakingsAction } from "../../store/actions/ManageStakingsAction";
import { calculateProfitPercentage2 } from "../../services/PartnersService";
import AnimationData from "../../images/Animation - 1727789718486 (1).gif";

import { Dropdown, Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const Pools2 = (props) => {
  const progressBarRef = useRef(null);

  const reduxDispatch = useDispatch();
  const [autoStakIsChecked, setAutoStakIsChecked] = useState(false);
  const [modalVisibility, setModalVisibility] = useState([]);
  const [normalModalVisibility, setNormalModalVisibility] = useState([]);
  const [specialModalVisibility, setSpecialModalVisibility] = useState([]);
  const [specialPoolModal, setSpecialPoolModal] = useState(false);

  // const specialPoolModalOpen = () => {
  //   setSpecialPoolModal(true);
  // };

  // const specialPoolModalClose = () => {
  //   setSpecialPoolModal(false);
  // };
  const user = JSON.parse(localStorage.userDetails);

  const toggleSwitch = () => {
    setAutoStakIsChecked(!autoStakIsChecked);
  };

  const openModal = (index, type) => {
    if (type === "normal") {
      const updatedVisibility = [...normalModalVisibility];
      updatedVisibility[index] = true;
      setNormalModalVisibility(updatedVisibility);
    } else {
      const updatedVisibility = [...specialModalVisibility];
      updatedVisibility[index] = true;
      setSpecialModalVisibility(updatedVisibility);
    }
  };

  const closeModal = (index, type) => {
    if (type === "normal") {
      const updatedVisibility = [...normalModalVisibility];
      updatedVisibility[index] = false;
      setNormalModalVisibility(updatedVisibility);
    } else {
      const updatedVisibility = [...specialModalVisibility];
      updatedVisibility[index] = false;
      setSpecialModalVisibility(updatedVisibility);
    }
  };

  const navigate = useNavigate();

  //To get staking amount for staking creation
  const [stakeAmount, setStakeAmount] = useState();
  let errorsObj = { amount: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    reduxDispatch(getAllActivePoolsAction());
  }, []);

  function handleCreateStaking(e, index, type) {
    e.preventDefault();
    let user = JSON.parse(localStorage.getItem("userDetails"));
    if (!user.is_verified) {
      toast.warn("❗ Please verify your email first to enable your staking ability!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
      // setIsVerifiedWaring(false);
    }
    const form = e.target;
    const poolId = form.elements.poolId.value;
    const minStake = form.elements.minStake.value;
    const autoStake = form.elements.autoStake.value;

    let error = false;
    const errorObj = { ...errorsObj };
    if (stakeAmount === "") {
      errorObj.amount = "Staking Amount must be valid number...";
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }
    if (stakeAmount === "0") {
      errorObj.amount = "Staking Amount must be greater than 0...";
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }

    if (JSON.parse(stakeAmount) > user.balance.toFixed(3)) {
      errorObj.amount = "Insufficient Balance.";
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }

    if (JSON.parse(stakeAmount) < minStake) {
      errorObj.amount = `Please enter an amount greater than ${minStake}`;
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }
    //console.log(e);
    //reduxDispatch(createStakingsAction());
    reduxDispatch(createStakingsAction(stakeAmount, poolId, autoStake, navigate));
    setStakeAmount("");
    closeModal(index, type);
    /* {
      Model should be closed here.
    } */
  }

  function handleStakeAll(e, index) {
    e.preventDefault();
    if (stakeAmount === 0 || stakeAmount === "" || stakeAmount === undefined) {
      setStakeAmount(parseFloat(user.balance.toFixed(3)));
    } else {
      // If stakeAmount is not 0, reset it to 0
      setStakeAmount("");
    }
  }
  function openInfoAlert() {
    swal("Info!", "If turned on this will automatically stake again when staking period ends.", "success");
  }
  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
          <div className="pools-container">
            <h4 className="pool-heading">Special Investment Pools</h4>
            <h2 className="text-pools">
              Dashboard/<span className="text-ppols-1">Pools </span>
            </h2>
          </div>

          {/* -----------------------------------Special Pool Modal-1--------------------------------------------------- */}

          <div className="card-pools-special">
            <div className="">
              <div className="table-responsive recentOrderTable">
                <div className="table-container">
                  <table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Duration(days)</th>
                        <th scope="col">Min-Stake</th>
                        <th scope="col">Profit</th>
                        <th scope="col">Pool Type</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.pools.specialPool.length > 0 &&
                        props.pools.specialPool.map((pool, index) => (
                          <tr key={index}>
                            <td className="pool-entry">
                              <div className="multiplier-badge-container">
                                <span className="badge-icon"></span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                  <path d="M0 0H34V34L17 23L0 34V0Z" fill="#FDC82B" fill-opacity="0.5" />
                                </svg>
                              </div>
                              <span className="multiplier-label">X2</span>

                              {pool.name}
                            </td>

                            <td data-label="Duration(days)" className="pool-duration-1">
                              {pool.duration}
                            </td>
                            <td data-label="Min-Stake">{pool.min_stake}</td>

                            <td data-label="Profit" className="Transaction-Type">
                              <span>{calculateProfitPercentage2(pool, user.tier, pool.duration, user.account_type)} </span>
                            </td>
                            <td data-label="">
                              <span className="custom-button-account-type-special">Special </span>
                            </td>
                            <td>
                              <button className="stake-button-special" onClick={() => openModal(index, "special")}>
                                <div className="stake-button-text-special">Stake</div>
                              </button>
                              <Modal className="modal fade model-dashboard-card-home" show={specialModalVisibility[index]} onHide={() => closeModal(index, "special")}>
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">Special Pool Staking Amount</h5>
                                    <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeModal(index, "special")}>
                                      <span>×</span>
                                    </Button>
                                  </div>
                                  <div className="modal-body">
                                    <form className="comment-form" onSubmit={(e) => handleCreateStaking(e, index)}>
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <div className="form-group mb-3">
                                            <label htmlFor="author" className="text-black font-w600">
                                              {" "}
                                              Amount <span className="required">*</span>
                                              {""}
                                            </label>
                                            <span className="text-green font-w300"> &nbsp;Available Balance - {user.balance.toLocaleString()}</span>
                                            {""}

                                            <input
                                              type="Number"
                                              className="form-control"
                                              value={stakeAmount}
                                              onChange={(e) => setStakeAmount(e.target.value)}
                                              name="stakeAmount"
                                              placeholder="Enter Staking Amount"
                                            />
                                            {errors.amount && <div className="text-danger fs-12">{errors.amount}</div>}
                                            <input type="text" value={pool._id} style={{ visibility: "hidden" }} onChange={(e) => setStakeAmount(e.target.value)} name="poolId" placeholder="Author" />
                                            <input
                                              type="text"
                                              value={pool.min_stake}
                                              style={{ visibility: "hidden" }}
                                              onChange={(e) => setStakeAmount(e.target.value)}
                                              name="minStake"
                                              placeholder="Author"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-4 col-4">
                                          <div className="input-group-append">
                                            <button type="button" className="btn btn-primary" onClick={handleStakeAll}>
                                              Stake All
                                            </button>
                                          </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-4 col-4">
                                          <div className="form-group mb-3">
                                            <input type="submit" value="Confirm Staking" className="submit btn btn-primary" name="submit" />
                                          </div>
                                        </div>
                                        <div class="form-check form-switch">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckChecked"
                                            name="autoStake"
                                            value={autoStakIsChecked}
                                            checked={autoStakIsChecked}
                                            onChange={toggleSwitch}
                                          />
                                          <label class="form-check-label" for="flexSwitchCheckChecked">
                                            Turn Auto Restake On
                                          </label>
                                          <a as="a" href="#" onClick={() => openInfoAlert()}>
                                            <i class="la la-question" style={{ color: "#000000" }}></i>
                                          </a>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </Modal>
                            </td>
                            <div className="dotline-for-mobile-special"></div>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/*-----------------------------------------Normal pools ---------------------------------------------------  */}
          <div className="pools-container">
            <h4 className="pool-heading">Normal Investment Pools</h4>
          </div>
          <div className="card-pools">
            <div className="card-body">
              <div className="table-responsive recentOrderTable">
                <div className="table-container">
                  <table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Duration(days)</th>
                        <th scope="col">Min-Stake</th>
                        <th scope="col">Profit</th>
                        <th scope="col">Pool Type</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.pools.pools.map((pool, index) => (
                        <tr key={index}>
                          <td className="pool-name-1">{pool.name}</td>
                          <td data-label="Duration(days)" className="pool-duration-1">
                            {pool.duration}
                          </td>
                          <td data-label="Min-Stake">{pool.min_stake}</td>

                          <td data-label="Profit" className="Transaction-Type">
                            <span>{calculateProfitPercentage2(pool, user.tier, pool.duration, user.account_type)} </span>
                          </td>
                          <td>
                            <span className="custom-button-account-type">Normal </span>
                          </td>
                          <td>
                            <button className="stake-button" onClick={() => openModal(index, "normal")}>
                              <div className="stake-button-text">Stake</div>
                            </button>
                            <Modal className="modal fade model-dashboard-card-home" show={normalModalVisibility[index]} onHide={() => closeModal(index, "normal")}>
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">Specify Staking Amount</h5>
                                  <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeModal(index, "normal")}>
                                    <span>×</span>
                                  </Button>
                                </div>
                                <div className="modal-body">
                                  <form className="comment-form" onSubmit={(e) => handleCreateStaking(e, index)}>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="author" className="text-black font-w600">
                                            {" "}
                                            Amount <span className="required">*</span>
                                            {""}
                                          </label>
                                          <span className="text-green font-w300"> &nbsp;Available Balance - {user.balance.toLocaleString()}</span>
                                          {""}

                                          <input
                                            type="Number"
                                            className="form-control"
                                            value={stakeAmount}
                                            onChange={(e) => setStakeAmount(e.target.value)}
                                            name="stakeAmount"
                                            placeholder="Enter Staking Amount"
                                          />
                                          {errors.amount && <div className="text-danger fs-12">{errors.amount}</div>}
                                          <input type="text" value={pool._id} style={{ visibility: "hidden" }} onChange={(e) => setStakeAmount(e.target.value)} name="poolId" placeholder="Author" />
                                          <input
                                            type="text"
                                            value={pool.min_stake}
                                            style={{ visibility: "hidden" }}
                                            onChange={(e) => setStakeAmount(e.target.value)}
                                            name="minStake"
                                            placeholder="Author"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-4 col-4">
                                        <div className="input-group-append">
                                          <button type="button" className="btn btn-primary" onClick={handleStakeAll}>
                                            Stake All
                                          </button>
                                        </div>
                                      </div>
                                      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-4 col-4">
                                        <div className="form-group mb-3">
                                          <input type="submit" value="Confirm Staking" className="submit btn btn-primary" name="submit" />
                                        </div>
                                      </div>
                                      <div class="form-check form-switch">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="flexSwitchCheckChecked"
                                          name="autoStake"
                                          value={autoStakIsChecked}
                                          checked={autoStakIsChecked}
                                          onChange={toggleSwitch}
                                        />
                                        <label class="form-check-label" for="flexSwitchCheckChecked">
                                          Turn Auto Restake On
                                        </label>
                                        <a as="a" href="#" onClick={() => openInfoAlert()}>
                                          <i class="la la-question" style={{ color: "#000000" }}></i>
                                        </a>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Modal>
                          </td>
                          <div className="dotline-for-mobile"></div>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  pools: state.pools,
  specialPool: state.specialPool,
  isLoading: state.isLoading,
  error: state.error,
});

export default connect(mapStateToProps)(Pools2);
