import React, { useState, Fragment, useMemo, useEffect } from "react";
import Select from "react-select";
import { calculateProfitPercentage } from "../../services/PartnersService";

const Pools = () => {
  const [days, setDays] = useState();
  const [tier, setTier] = useState();
  const [accountType, setAccountType] = useState();
  const [isIslamic, setIsIslamic] = useState();
  const [partner, setPartner] = useState();
  const [amount, setAmount] = useState();
  const [profit, setProfit] = useState();
  let errorsObj = { days: "", amount: "" };
  const [errors, setErrors] = useState(errorsObj);
  const options3 = [
    { value: "10", label: "Silver Elite" },
    { value: "30", label: "Gold Elite" },
    { value: "60", label: "Platinum Elite" },
    { value: "90", label: "Titanium Elite" },
    { value: "180", label: "Diamond Elite" },
  ];
  const handlePoolSelect = (selectedOption) => {
    setDays(parseFloat(selectedOption.value));
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.userDetails);
    setTier(user.tier);
    setAccountType(user.account_type);
    setPartner(user);
    setIsIslamic(user.account_type === "Islamic" ? true : false);
  }, [profit]);

  function getProfitRange(rangeString, amount) {
    // Remove the '%' character and split the string based on ' - ' to get the range
    const [min, max] = rangeString.replace(/%/g, "").split(" - ").map(Number);

    // Generate a random number between the min and max values
    const randomNumber = Math.random() * (max - min) + min;
    const lowerBoundProfit = (min * amount) / 100;
    const upperBoundProfit = (max * amount) / 100;
    const profitRange = lowerBoundProfit.toFixed(2) + " USDT - " + upperBoundProfit.toFixed(2) + " USDT";

    return profitRange;
  }

  const handleCalculateProfit = (e) => {
    e.preventDefault();
    let profitPercentage;
    const errorObj = { ...errorsObj };
    let error = false;
    if (!days) {
      errorObj.days = "Please select Pool.";
      error = true;
    }
    if (!amount) {
      errorObj.amount = "Please Enter amount.";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    const profitPercent = calculateProfitPercentage(tier, days, accountType);
    if (partner.account_type === "Normal") {
      return setProfit((amount * parseFloat(profitPercent)) / 100);
    } else if (partner.account_type === "Islamic") {
      return setProfit(getProfitRange(profitPercent, amount));
    }
    // setProfit((amount * profitPercentage) / 100);
    return (amount * profitPercentage) / 100;
  };
  return (
    <Fragment>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Calculate Profit</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleCalculateProfit}>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Pool</label>
                    <div className="col-sm-9">
                      <Select options={options3} onChange={handlePoolSelect} className="custom-react-select" defaultValue={""} isSearchable={true} placeholder={"Select Pool"} />
                      {errors.days && <div className="text-danger fs-12">{errors.days}</div>}
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Amount</label>
                    <div className="col-sm-9">
                      <input type="Number" onChange={(e) => setAmount(parseFloat(e.target.value))} className="form-control" placeholder="Amount" />
                      {errors.amount && <div className="text-danger fs-12">{errors.amount}</div>}
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        Calculate
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          {" "}
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Expected Profit</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Profit</label>
                  <div className="col-sm-9">
                    <input type="Text" disabled defaultValue={profit} onChange={(e) => e.preventDefault()} className="form-control" placeholder="Profit" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Pools;
