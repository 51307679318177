import React, { useState, useEffect } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import logotext1 from "./../../../images/logo/logo-text.png";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import Bootstrap Icons
import { MenuList } from "./Menu";
import TopUp from "../../components/TopUp";
import LogoutPage from "./Logout";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import profileIcon from "./../../../images/profileIcon.png";
import PerfectScrollbar from "react-perfect-scrollbar";

import Tier from "../../pages/Tier";

const Header2 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("/"); // Track the active link
  const handleToggle = () => setIsMenuOpen(!isMenuOpen);
  const location = useLocation();
  const user = JSON.parse(localStorage.userDetails);

  const toggleMenu = () => {
    const menuElement = document.querySelector(".menu1");
    const navbarElement = document.querySelector(".custom-navbar");

    if (isMenuOpen) {
      menuElement.classList.remove("is-visible");
      navbarElement.style.position = ""; // Reset position if changed
    } else {
      menuElement.classList.add("is-visible");
      navbarElement.style.position = "relative"; // Change position to avoid layout issues
    }

    setIsMenuOpen(!isMenuOpen);
  };
  const handleLinkClick = (link) => {
    setActiveLink(link); // Set the active link when a link is clicked
    toggleMenu(); // Close the menu
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 990 && isMenuOpen) {
        toggleMenu(); // Close the menu if screen size is greater than 820px
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMenuOpen]);

  return (
    <div>
      <Navbar expand="lg" className="custom-navbar border-bottom border-2">
        <Navbar.Brand href="/">
          <img src={logotext1} className="logo2" alt="Logo" />
        </Navbar.Brand>
        <div className="ml-auto toggle-container">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleToggle}
          >
            <svg
              width="23"
              height="20"
              viewBox="0 0 23 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="menu - iocn 1" clip-path="url(#clip0_16_14328)">
                <path
                  id="Vector"
                  d="M0 9.72001C0.0157926 9.69001 0.029462 9.65832 0.04085 9.6253C0.218458 8.95414 0.597948 8.58947 1.15919 8.56859C1.3735 8.56038 1.58782 8.56859 1.80213 8.56859C8.32272 8.56859 14.8435 8.56983 21.3645 8.57232C21.5559 8.5664 21.7469 8.59708 21.9311 8.6633C22.1714 8.76447 22.3771 8.96599 22.5142 9.2345C22.6512 9.50301 22.7114 9.82242 22.6847 10.1399C22.6604 10.4573 22.5518 10.7552 22.3765 10.9852C22.2012 11.2153 21.9693 11.3641 21.7185 11.4076C21.594 11.4269 21.4686 11.4354 21.3432 11.433C14.7042 11.433 8.06499 11.4362 1.4256 11.4427C0.721089 11.4427 0.225562 11.1556 0 10.2808V9.72001Z"
                  fill="white"
                />
                <path
                  id="Vector_2"
                  d="M14.159 2.85389C11.7214 2.85389 9.28424 2.85389 6.84746 2.85389C6.04763 2.85389 5.50355 1.95901 5.7226 1.02086C5.78876 0.738024 5.92311 0.4881 6.10704 0.305716C6.29097 0.123332 6.51538 0.0175178 6.74918 0.00292969C6.80128 0.00292969 6.85278 0.00292969 6.90429 0.00292969H21.4593C22.0513 0.00292969 22.4805 0.369087 22.6368 0.994763C22.69 1.20617 22.7034 1.43028 22.6759 1.64933C22.6483 1.86838 22.5807 2.07633 22.4782 2.25669C22.3758 2.43705 22.2414 2.58486 22.0857 2.6884C21.93 2.79193 21.7573 2.84834 21.5812 2.85315C21.1668 2.86583 20.7524 2.85315 20.338 2.85315L14.159 2.85389Z"
                  fill="white"
                />
                <path
                  id="Vector_3"
                  d="M16.4452 19.9977C14.7473 19.9977 13.0494 20.0037 11.3538 19.9977C11.0539 19.9998 10.7656 19.8517 10.5524 19.5861C10.3392 19.3204 10.2185 18.9589 10.2168 18.5812C10.2152 18.2034 10.3327 17.8403 10.5436 17.5717C10.7545 17.3031 11.0415 17.1511 11.3414 17.149C11.8061 17.1423 12.2714 17.149 12.7362 17.149C15.6521 17.149 18.5679 17.149 21.4834 17.149C22.1346 17.149 22.6307 17.6829 22.687 18.4436C22.7491 19.2878 22.2353 19.997 21.5402 19.9992C19.8405 20.0022 18.1426 19.9977 16.4452 19.9977Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_16_14328">
                  <rect width="22.6906" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-block">
          <Nav className="mr-auto nav-custom">
            {MenuList.map((menu, index) => (
              <Link
                key={index}
                className={`nav-link-custom ${
                  location.pathname === menu.to ||
                  (location.pathname === "/" && menu.to === "/")
                    ? "border-hover2"
                    : ""
                }`}
                to={menu.to}
              >
                {menu.title}
              </Link>
            ))}
          </Nav>
          <div className="withdraw-deposit collapse-buttons">
            <TopUp />
          </div>
          <Nav className=" side-logo align-items-center">
            <Nav.Link href="/profile" className="profile-view">
              <svg
                width="33"
                height="33"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18" cy="18" r="18" fill="#D9D9D9" />
                <defs>
                  <clipPath id="clipCircle">
                    <circle cx="18" cy="18" r="18" />
                  </clipPath>
                </defs>
                <image
                  href={user.image_url ? user.image_url : profileIcon} // Replace with the URL of your human image
                  width="36"
                  height="36"
                  clipPath="url(#clipCircle)" // Clip the image to the circle shape
                  preserveAspectRatio="xMidYMid slice" // Ensure the image covers the circle area
                />
              </svg>
            </Nav.Link>

            <Button variant="" className="logout-view">
              <LogoutPage />
            </Button>
          </Nav>
        </Navbar.Collapse>
        <div className="withdraw-deposit collapse-buttons-mobile">
          <TopUp />
        </div>
        {isMenuOpen && (
          <div className="menu1">
            <div className="menu-item1">
              <div className="close-button" onClick={toggleMenu}>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6539 14.0208C12.4729 13.8673 12.3719 13.7922 12.2834 13.7037C8.33794 9.7577 4.39191 5.81166 0.445313 1.86563C0.149903 1.57105 -0.0161614 1.24477 0.0789707 0.830024C0.117578 0.664571 0.197885 0.511751 0.312249 0.386109C0.426614 0.260467 0.571228 0.166183 0.732331 0.112231C0.893435 0.0582789 1.06567 0.0464541 1.23263 0.0778814C1.3996 0.109309 1.55574 0.182942 1.6862 0.291775C1.77466 0.364376 1.8531 0.450328 1.93655 0.531274C5.862 4.45506 9.78634 8.37912 13.7096 12.3035C13.7972 12.3869 13.8706 12.4921 13.9641 12.6022C14.0918 12.482 14.1819 12.4003 14.2687 12.3135C18.2359 8.34463 22.2022 4.37439 26.1677 0.402761C26.4598 0.110689 26.7802 -0.0670575 27.1983 0.0239022C27.3639 0.0605542 27.5176 0.138688 27.6447 0.250972C27.7719 0.363257 27.8685 0.50601 27.9254 0.665837C27.9823 0.825664 27.9976 0.997326 27.97 1.16471C27.9424 1.3321 27.8727 1.48972 27.7674 1.62279C27.6866 1.71822 27.6002 1.80878 27.5087 1.894C23.5683 5.83614 19.63 9.77578 15.694 13.7129C15.6105 13.8005 15.5054 13.8748 15.3927 13.9708C15.5112 14.0951 15.593 14.1852 15.679 14.2712C19.6389 18.2328 23.5969 22.1958 27.553 26.1602C27.7477 26.3573 27.8895 26.6005 27.9652 26.867C28.0687 27.2576 27.8317 27.6665 27.4804 27.8684C27.3074 27.9672 27.1087 28.0116 26.9101 27.9958C26.7116 27.9801 26.5223 27.9048 26.3672 27.78C26.2724 27.6987 26.1821 27.6123 26.0968 27.5213C22.1569 23.5825 18.2181 19.6434 14.2804 15.704C14.1977 15.6106 14.1207 15.5122 14.05 15.4094L13.9149 15.3652C13.8624 15.4792 13.7966 15.5865 13.7188 15.6848C9.75435 19.6559 5.78773 23.6259 1.81889 27.5947C1.50178 27.9126 1.15129 28.0954 0.699833 27.9485C0.544812 27.901 0.40383 27.8162 0.289284 27.7015C0.174737 27.5867 0.090134 27.4456 0.0429112 27.2905C-0.00431162 27.1354 -0.0127079 26.9711 0.0184619 26.812C0.0496316 26.6528 0.119412 26.5038 0.221668 26.378C0.303206 26.2832 0.389843 26.193 0.481195 26.1076C4.42612 22.1605 8.3716 18.2139 12.3176 14.2679C12.4242 14.1782 12.5365 14.0957 12.6539 14.0208Z"
                    fill="#666666"
                  />
                </svg>{" "}
              </div>
            </div>
            <div className="myarbit-logo-11">
              <svg
                width="111"
                height="83"
                viewBox="0 0 111 83"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_16_16646)">
                  <path
                    d="M31.2969 17.2979L54.9778 58.0724V40.3622L31.2969 17.2979Z"
                    fill="url(#paint0_linear_16_16646)"
                  />
                  <path
                    d="M31.2971 17.2979L25.5295 58.0724L39.8656 32.0521L31.2971 17.2979Z"
                    fill="url(#paint1_linear_16_16646)"
                  />
                  <path
                    d="M39.8656 32.0522L25.5295 58.0726L47.4218 45.0624L39.8656 32.0522Z"
                    fill="url(#paint2_linear_16_16646)"
                  />
                  <path
                    d="M54.9779 0V40.3624L41.3159 27.0562L54.9779 0Z"
                    fill="url(#paint3_linear_16_16646)"
                  />
                  <path
                    d="M78.6601 17.2979L54.978 58.0724V40.3622L78.6601 17.2979Z"
                    fill="url(#paint4_linear_16_16646)"
                  />
                  <path
                    d="M78.6601 17.2979L84.4265 58.0724L70.0916 32.0521L78.6601 17.2979Z"
                    fill="url(#paint5_linear_16_16646)"
                  />
                  <path
                    d="M70.0916 32.0522L84.4265 58.0726L62.5342 45.0624L70.0916 32.0522Z"
                    fill="url(#paint6_linear_16_16646)"
                  />
                  <path
                    d="M54.978 0V40.3624L68.6412 27.0562L54.978 0Z"
                    fill="url(#paint7_linear_16_16646)"
                  />
                  <path
                    d="M0 65.207H4.02707L8.48986 72.3886L12.9526 65.207H16.9785V82.1903H13.2709V71.1026L8.49103 78.357H8.39473L3.66301 71.1754V82.1903H0V65.207Z"
                    fill="black"
                  />
                  <path
                    d="M25.8382 75.4938L19.312 65.207H23.6797L27.7314 72.0245L31.856 65.207H36.1015L29.5752 75.4245V82.1938H25.8382V75.4938Z"
                    fill="black"
                  />
                  <path
                    d="M41.585 65.0864H45.0307L52.3121 82.1906H48.4025L46.8499 78.382H39.6683L38.1181 82.1906H34.3071L41.585 65.0864ZM45.5169 75.0819L43.2597 69.575L41.0025 75.0819H45.5169Z"
                    fill="black"
                  />
                  <path
                    d="M54.9297 65.207H62.6938C64.8523 65.207 66.5024 65.7942 67.6427 66.9299C68.6128 67.9 69.099 69.186 69.099 70.8055V70.8548C69.099 73.5959 67.6181 75.3176 65.4583 76.1197L69.6064 82.1844H65.2469L61.6062 76.7504H58.6702V82.1844H54.9297V65.207ZM62.446 73.4561C64.2898 73.4561 65.3092 72.5096 65.3092 71.0544V71.0063C65.3092 69.3797 64.217 68.58 62.3731 68.58H58.6702V73.4561H62.446Z"
                    fill="black"
                  />
                  <path
                    d="M72.4709 65.207H80.356C82.322 65.207 83.8017 65.7167 84.7965 66.7115C85.5727 67.4877 85.9603 68.4578 85.9603 69.5982V69.6475C85.9603 71.5395 84.9421 72.6798 83.7289 73.3105C85.6937 74.0621 86.9069 75.2272 86.9069 77.4832V77.5325C86.9069 80.6377 84.3842 82.1903 80.5509 82.1903H72.4709V65.207ZM82.2491 70.2288C82.2491 69.0897 81.3754 68.4825 79.7981 68.4825H76.1105V72.0738H79.5562C81.2004 72.0738 82.2491 71.5395 82.2491 70.2782V70.2288ZM83.1945 77.0228C83.1945 75.8825 82.3725 75.179 80.4288 75.179H76.1105V78.9113H80.5509C82.1951 78.9113 83.1945 78.3241 83.1945 77.0675V77.0228Z"
                    fill="black"
                  />
                  <path
                    d="M90.1577 65.207H93.8947V82.1903H90.1577V65.207Z"
                    fill="url(#paint8_linear_16_16646)"
                  />
                  <path
                    d="M102.096 68.6528H96.9282V65.207H111V68.6528H105.833V82.1903H102.096V68.6528Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_16_16646"
                    x1="34.6768"
                    y1="15.1358"
                    x2="58.8827"
                    y2="55.7706"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FF00B2" />
                    <stop offset="0.16" stop-color="#C90BC2" />
                    <stop offset="0.39" stop-color="#821AD8" />
                    <stop offset="0.6" stop-color="#4A26E9" />
                    <stop offset="0.78" stop-color="#222EF5" />
                    <stop offset="0.92" stop-color="#0933FC" />
                    <stop offset="1" stop-color="#0035FF" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_16_16646"
                    x1="30.8825"
                    y1="58.4471"
                    x2="36.3271"
                    y2="18.2985"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FF00B2" />
                    <stop offset="0.16" stop-color="#C908C2" />
                    <stop offset="0.39" stop-color="#8212D8" />
                    <stop offset="0.6" stop-color="#4A1AE9" />
                    <stop offset="0.78" stop-color="#221FF5" />
                    <stop offset="0.92" stop-color="#0923FC" />
                    <stop offset="1" stop-color="#0024FF" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_16_16646"
                    x1="21.89"
                    y1="51.4219"
                    x2="43.8598"
                    y2="38.4927"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.01" stop-color="#E10495" />
                    <stop offset="0.09" stop-color="#C90796" />
                    <stop offset="0.34" stop-color="#821198" />
                    <stop offset="0.56" stop-color="#4A199A" />
                    <stop offset="0.76" stop-color="#221F9B" />
                    <stop offset="0.91" stop-color="#09239C" />
                    <stop offset="1" stop-color="#00249C" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_16_16646"
                    x1="48.1463"
                    y1="46.1922"
                    x2="48.1463"
                    y2="-5.63837"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#002492" />
                    <stop offset="1" stop-color="#E10495" />
                  </linearGradient>
                  <linearGradient
                    id="paint4_linear_16_16646"
                    x1="75.2802"
                    y1="15.1358"
                    x2="51.0743"
                    y2="55.7706"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FF00B2" />
                    <stop offset="0.16" stop-color="#C90BC2" />
                    <stop offset="0.39" stop-color="#821AD8" />
                    <stop offset="0.6" stop-color="#4A26E9" />
                    <stop offset="0.78" stop-color="#222EF5" />
                    <stop offset="0.92" stop-color="#0933FC" />
                    <stop offset="1" stop-color="#0035FF" />
                  </linearGradient>
                  <linearGradient
                    id="paint5_linear_16_16646"
                    x1="79.0735"
                    y1="58.4471"
                    x2="73.6301"
                    y2="18.2985"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FF00B2" />
                    <stop offset="0.16" stop-color="#C908C2" />
                    <stop offset="0.39" stop-color="#8212D8" />
                    <stop offset="0.6" stop-color="#4A1AE9" />
                    <stop offset="0.78" stop-color="#221FF5" />
                    <stop offset="0.92" stop-color="#0923FC" />
                    <stop offset="1" stop-color="#0024FF" />
                  </linearGradient>
                  <linearGradient
                    id="paint6_linear_16_16646"
                    x1="88.066"
                    y1="51.4219"
                    x2="66.0974"
                    y2="38.4927"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.01" stop-color="#E10495" />
                    <stop offset="0.09" stop-color="#C90796" />
                    <stop offset="0.34" stop-color="#821198" />
                    <stop offset="0.56" stop-color="#4A199A" />
                    <stop offset="0.76" stop-color="#221F9B" />
                    <stop offset="0.91" stop-color="#09239C" />
                    <stop offset="1" stop-color="#00249C" />
                  </linearGradient>
                  <linearGradient
                    id="paint7_linear_16_16646"
                    x1="61.8096"
                    y1="46.1922"
                    x2="61.8096"
                    y2="-5.63837"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#002492" />
                    <stop offset="1" stop-color="#E10495" />
                  </linearGradient>
                  <linearGradient
                    id="paint8_linear_16_16646"
                    x1="92.0262"
                    y1="86.0529"
                    x2="92.0262"
                    y2="64.0232"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#002492" />
                    <stop offset="1" stop-color="#E10495" />
                  </linearGradient>
                  <clipPath id="clip0_16_16646">
                    <rect width="111" height="82.1904" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="menu-inner1"></div>
            <div className="dashboard-parent1">
              <Link
                to="/"
                className={`dashboard1 ${
                  activeLink === "/" ? "active-link" : ""
                }`}
                onClick={() => handleLinkClick("/")}
              >
                Dashboard
                {activeLink === "/" && <div className="group-child1" />}
              </Link>
              <Link
                to="pools2"
                className={`pools1 ${
                  activeLink === "pools2" ? "active-link" : ""
                }`}
                onClick={() => handleLinkClick("pools2")}
              >
                Pools
                {activeLink === "pools2" && <div className="group-child1" />}
              </Link>
              <Link
                to="manage-stakings"
                className={`stakings1 ${
                  activeLink === "manage-stakings" ? "active-link" : ""
                }`}
                onClick={() => handleLinkClick("manage-stakings")}
              >
                Stakings
                {activeLink === "manage-stakings" && (
                  <div className="group-child1" />
                )}
              </Link>
              <Link
                to="stake-history"
                className={`stake-history1 ${
                  activeLink === "stake-history" ? "active-link" : ""
                }`}
                onClick={() => handleLinkClick("stake-history")}
              >
                Stake History
                {activeLink === "stake-history" && (
                  <div className="group-child1" />
                )}
              </Link>
              <Link
                to="transactions2"
                className={`transactions1 ${
                  activeLink === "transactions2" ? "active-link" : ""
                }`}
                onClick={() => handleLinkClick("transactions2")}
              >
                Transactions
                {activeLink === "transactions2" && (
                  <div className="group-child1" />
                )}
              </Link>
              <Link
                to="my-earnings2"
                className={`earnings1 ${
                  activeLink === "my-earnings2" ? "active-link" : ""
                }`}
                onClick={() => handleLinkClick("my-earnings2")}
              >
                Earnings
                {activeLink === "my-earnings2" && (
                  <div className="group-child1" />
                )}
              </Link>
              <Link
                to="rewards2"
                className={`rewards1 ${
                  activeLink === "rewards2" ? "active-link" : ""
                }`}
                onClick={() => handleLinkClick("rewards2")}
              >
                Rewards
                {activeLink === "rewards2" && <div className="group-child1" />}
              </Link>
              <Link
                to="profile"
                className={`profile1 ${
                  activeLink === "profile" ? "active-link" : ""
                }`}
                onClick={() => handleLinkClick("profile")}
              >
                Profile
                {activeLink === "profile" && <div className="group-child1" />}
              </Link>{" "}
              <Link>
                <LogoutPage />
              </Link>
            </div>
          </div>
        )}
      </Navbar>
      <Tier />
    </div>
  );
};

export default Header2;
