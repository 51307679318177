import React, {
  useState,
  useReducer,
  useEffect,
  Fragment,
  Suspense,
} from "react";
import { connect, useDispatch } from "react-redux";
import {
  getStakingsAction,
  unStakeAction,
  loadingToggleAction,
  autoStakeToggleAction,
  clearStakingsAction,
} from "../../store/actions/ManageStakingsAction";
import { Dropdown, Button, Modal } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Tabs, Tab, Pagination } from "react-bootstrap";

const ManageStakings2 = (props) => {
  const user = JSON.parse(localStorage.userDetails);
  const percentage = 80;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [unstakeModal, setUnstakeModal] = useState(false);
  const [unStakeDoc, setUnStakeDoc] = useState({});
  const [autoStakeToggle, setAutoStakeToggle] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const totalPages = 3; // Example total pages

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const [value, setValue] = useState(8);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(getStakingsAction());
  }, [autoStakeToggle]);

  const openUnstakeModal = (staking) => {
    setUnStakeDoc(staking);
    setUnstakeModal(true);
  };
  const closeUnstakeModal = () => {
    setUnstakeModal(false);
  };

  const handleUnstake = (e) => {
    e.preventDefault();
    dispatch(unStakeAction(unStakeDoc, navigate));
    dispatch(clearStakingsAction());
    setUnstakeModal(false);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Format date as "May 14, 2024"
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    // Format time as "2:03:26 PM"
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    return { formattedDate, formattedTime };
  }

  const calProgressbarValue = (_startDate_str, _EndDate_str) => {
    const startDate = new Date(_startDate_str);
    const endDate = new Date(_EndDate_str);
    const currentDate = Date.now();
    // Calculate time difference in milliseconds
    const timeDiff = endDate - currentDate;
    // Convert milliseconds to days
    const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysLeft;
  };

  const calProgressbarText = (_startDate_str, _EndDate_str) => {
    const startDate = new Date(_startDate_str);
    const endDate = new Date(_EndDate_str);
    const currentDate = new Date();
    // Calculate time difference in milliseconds
    const timeDiff = endDate - currentDate;
    // Convert milliseconds to days
    const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (daysLeft >= 30) {
      const monthsLeft = Math.floor(daysLeft / 30);
      return `${monthsLeft}M left`;
    } else {
      return `${daysLeft}d left`;
    }
  };
  const handleAutoStakeToggle = (_staking) => {
    dispatch(autoStakeToggleAction(_staking, navigate));
    setTimeout(() => {
      setAutoStakeToggle(!autoStakeToggle);
    }, 500);
  };

  return (
    <Fragment>
      <Modal show={unstakeModal} onHide={() => closeUnstakeModal()} centered>
        <div className="unstake-modal-content">
          <div className="unstake-modal-header">
            <div className="unstake-model-svg">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.6875 15.4875L14.6625 2.57501C14.025 1.71251 13.05 1.22501 12 1.22501C10.9125 1.22501 9.93753 1.71251 9.33753 2.57501L1.31253 15.4875C0.562527 16.5 0.450027 17.8125 1.01253 18.9375C1.57503 20.0625 2.70003 20.775 3.97503 20.775H20.025C21.3 20.775 22.425 20.0625 22.9875 18.9375C23.55 17.85 23.4375 16.5 22.6875 15.4875ZM21.4875 18.1875C21.1875 18.75 20.6625 19.0875 20.025 19.0875H3.97503C3.33753 19.0875 2.81253 18.75 2.51253 18.1875C2.25003 17.625 2.28753 16.9875 2.66253 16.5L10.6875 3.58751C10.9875 3.17501 11.475 2.91251 12 2.91251C12.525 2.91251 13.0125 3.13751 13.3125 3.58751L21.3375 16.5C21.7125 16.9875 21.75 17.625 21.4875 18.1875Z"
                  fill="#F23030"
                />
                <path
                  d="M12 8.20004C11.55 8.20004 11.1375 8.57504 11.1375 9.06254V13.15C11.1375 13.6 11.5125 14.0125 12 14.0125C12.4875 14.0125 12.8625 13.6375 12.8625 13.15V9.02504C12.8625 8.57504 12.45 8.20004 12 8.20004Z"
                  fill="#F23030"
                />
                <path
                  d="M12 15C11.55 15 11.1375 15.375 11.1375 15.8625V16.05C11.1375 16.5 11.5125 16.9125 12 16.9125C12.4875 16.9125 12.8625 16.5375 12.8625 16.05V15.825C12.8625 15.375 12.45 15 12 15Z"
                  fill="#F23030"
                />
              </svg>
            </div>
            <div className="unstake-modal-title-wrapper">
              <div className="unstake-modal-title">Un-stake Confirmation</div>
              <div className="unstake-modal-description">
                <span>
                  Are you sure you want to Unstake? In case you unstake you will
                  be{" "}
                </span>
                <span className="unstake-modal-fee">charged 10% </span>
                <span>of the amount staked.*</span>
              </div>
            </div>

            <div className="unstake-modal-footer">
              <button
                className="unstake-confirm-button"
                onClick={handleUnstake}
              >
                <div className="unstake-confirm-text">Yes</div>
              </button>
              <button
                className="unstake-cancel-button"
                onClick={() => closeUnstakeModal()}
              >
                <div className="">No</div>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
          <div className="pools-container">
            <h4 className="pool-heading">Manage Stakings</h4>
            <h2 className="text-pools">
              Dashboard/ <span className="text-ppols-1">Stakings </span>
            </h2>
          </div>

          <div className="manage-stakings">
            <div className="card-body-pools">
              <div className="table-responsive recentOrderTable">
                <div className="table-container">
                  <table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">Pool Name</th>
                        <th scope="col">Duration(days)</th>
                        <th scope="col">Profit</th>
                        <th scope="col">Amount Staked</th>
                        <th scope="col">Auto Stake</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">Time Left</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.stakings?.stakings &&
                      props.stakings.stakings.length > 0 ? (
                        props.stakings.stakings.map((staking) => (
                          <tr key={staking.id}>
                            <td>{staking.pool_info.name}</td>
                            <td data-label="Duration(days)">
                              {staking.pool_info.duration}
                            </td>
                            <td
                              data-label="Profit"
                              className="Transaction-Type"
                            >
                              {user.account_type === "Normal"
                                ? staking.profit_percent + "%"
                                : staking.profit_percent_range}{" "}
                            </td>
                            <td data-label="Amount Staked">
                              {staking.amount_staked}
                            </td>
                            <td>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckChecked"
                                  name="autoStake"
                                  value={staking.auto_stake}
                                  checked={staking.auto_stake}
                                  onClick={() => handleAutoStakeToggle(staking)}
                                />
                              </div>
                            </td>
                            <td data-label="">
                              <div className="may-142024-20326-container">
                                <p className="pm">
                                  {formatDate(staking.created_at).formattedDate}
                                </p>
                                <p className="pm">
                                  {formatDate(staking.created_at).formattedTime}
                                </p>
                              </div>
                            </td>
                            <td className="progress-container">
                              <CircularProgressbar
                                value={calProgressbarValue(
                                  staking.created_at,
                                  staking.end_date
                                )}
                                maxValue={staking.pool_info.duration}
                                text={calProgressbarText(
                                  staking.created_at,
                                  staking.end_date
                                )}
                                styles={buildStyles({
                                  rotation: 0.25,
                                  strokeLinecap: "butt",
                                  textSize: "14px",
                                  pathTransitionDuration: 0.5,
                                  pathColor: "#01a419",
                                  textColor: "#000",
                                  trailColor: "#d6d6d6",
                                  backgroundColor: "#3e98c7",
                                })}
                              />
                            </td>

                            <td>
                              <button
                                className="button-trans-mang"
                                onClick={() => openUnstakeModal(staking)}
                              >
                                <div className="button-trans-mang-text">
                                  {" "}
                                  Un-Stake
                                </div>
                              </button>
                            </td>
                            <div className="dotline-for-mobile1">
                              <div className="dotline-for-mobile2"></div>
                            </div>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9">
                            <div className="no-data-message">
                              <p>No staking in  process</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  stakings: state.stakings,
  isLoading: state.isLoading,
  error: state.error,
});

export default connect(mapStateToProps)(ManageStakings2);
