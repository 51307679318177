import React, { useState, useMemo, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import swal from "sweetalert";

import { Button, Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Pagination from "react-bootstrap/Pagination";

import { useTable, useSortBy } from "react-table";
import {
  getPartnersWithPaginationAction,
  updatePartnerVerifyStatusAction,
  updatePartnerAction,
  blockPartnerAction,
  blockPartnerWithdrawsAction,
  partnerSearchAction,
  partnerSearchByPhoneAction,
  partnerSearchByEmailAction,
} from "../../../store/actions/PartnersActions";
import MOCK_DATA from "../components/SortingTable/MOCK_DATA_2.json";

const options3 = [
  { value: "", label: "No Country Selected" },
  { value: "1", label: "Russia" },
  { value: "2", label: "Canada" },
  { value: "3", label: "China" },
  { value: "4", label: "India" },
  { value: "5", label: "Afghanistan" },
];

const ManagePartners = (props) => {
  const [editModal, setEditModal] = useState(false);
  const [editPartnerByPhoneModal, setEditPartnerByPhoneModal] = useState(false);
  const [partner, setPartner] = useState({});
  const [partnerInfoModal, setPartnerInfoModal] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState({});
  const [referredModal, setReferredModal] = useState(false);
  const [referredPartners, setReferredPartners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstPage, setFirstPage] = useState({ value: 1, isActive: true });
  const [secondPage, setSecondPage] = useState({ value: 2, isActive: false });
  const [thirdPage, setThirdPage] = useState({ value: 3, isActive: false });
  const [fourthPage, setFourthPage] = useState({ value: 4, isActive: false });
  const [fifthPage, setFifthPage] = useState({ value: 5, isActive: false });
  const [totalPages, setTotalPages] = useState(0);
  const [filtersModal, setFiltersModal] = useState(false);
  const [filters, setFilters] = useState(false);

  const dispatch = useDispatch();

  function handleUpdatePartner(e) {
    e.preventDefault();
    partner.balance = parseFloat(partner.balance);
    dispatch(updatePartnerAction(partner));
    if (updatePartnerByPhone) {
      closeEditPartnerByPhoneModal();
      setUpdatePartnerByPhone(false);
      return;
    }
    setTimeout(() => {
      dispatch(getPartnersWithPaginationAction(currentPage));
    }, 2000);
    closeEditModal();
  }
  function handleBlockPartner(partner) {
    const status = partner.isBlocked; //console.log(partner.isBlocked);
    const partnerId = partner._id; //console.log(partner._id);
    // console.log(currentPage);
    dispatch(blockPartnerAction(partnerId, status));
    setTimeout(() => {
      // console.log(currentPage);
      dispatch(getPartnersWithPaginationAction(currentPage));
    }, 2000);
    closeEditModal();
  }
  function handleBlockPartnerWithdraws(partner) {
    const status = partner.is_withdraw_disabled;
    //console.log(partner.is_withdraw_disabled);
    const partnerId = partner._id; //console.log(partner._id);
    // console.log(currentPage);
    dispatch(blockPartnerWithdrawsAction(partnerId, status));
    setTimeout(() => {
      // console.log(currentPage);
      dispatch(getPartnersWithPaginationAction(currentPage));
    }, 2000);
    closeEditModal();
  }

  function handleUpdateVerifyStatus(partner) {
    const status = partner.is_verified; //console.log(partner.isBlocked);
    const partnerId = partner._id; //console.log(partner._id);
    dispatch(updatePartnerVerifyStatusAction(partnerId, status));
    setTimeout(() => {
      // console.log(currentPage);
      dispatch(getPartnersWithPaginationAction(currentPage));
    }, 2000);
    closeEditModal();
  }

  function openReferredModal(partner) {
    setReferredPartners(partner.referred);
    setPartnerInfo(partner);
    setReferredModal(true);
  }
  const closeReferredModal = () => {
    setReferredModal(false);
  };

  const openEditModal = (partner) => {
    setEditModal(true);
    const partnerObj = {
      id: partner._id,
      name: partner.name,
      email: partner.email,
      role: partner.role,
      balance: partner.balance,
      total_staked: partner.total_staked,
    };
    setPartner(partnerObj);
  };

  const closeEditModal = () => {
    setEditModal(false);
  };
  const closeEditPartnerByPhoneModal = () => {
    props.partners.partner_by_phone = {};
    props.partners.partner_by_email = {};
    setPartnerFoundByPhone({});
    setEditPartnerByPhoneModal(false);
  };

  const handleInputChange = (attr, value) => {
    setPartner((partner) => ({
      ...partner,
      [attr]: value,
    }));
  };
  const handleSelectChange = (selectedOption) => {
    setPartner((partner) => ({
      ...partner,
      ["role"]: selectedOption.label,
    }));
    //console.log(partner.role);
  };

  const COLUMNS = [
    {
      Header: "#",
      Footer: "#",
      accessor: "#",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
    },
    {
      Header: "Country",
      Footer: "Country",
      accessor: "country",
    },
    {
      Header: "Phone",
      Footer: "Phone",
      accessor: "phone",
    },
    {
      Header: "Email",
      Footer: "Email",
      accessor: "email",
    },
    {
      Header: "IsVerified",
      Footer: "IsVerified",
      accessor: "is_verified",
    },
    {
      Header: "Referral-Level",
      Footer: "Referral-Level",
      accessor: "referral_level",
    },
    {
      Header: "Role",
      Footer: "Role",
      accessor: "role",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "isBlocked",
    },
    {
      Header: "Balance",
      Footer: "Balance",
      accessor: "balance",
    },
    {
      Header: "Staked",
      Footer: "Staked",
      accessor: "total_staked",
    },
    {
      Header: "IsReferred",
      Footer: "IsReferred",
      accessor: "referred_by",
    },
    {
      Header: "Withdraw_Disabled",
      Footer: "Withdraw_Disabled",
      accessor: "is_withdraw_disabled",
    },
    {
      Header: "Joined",
      Footer: "Joined",
      accessor: "created_at",
    },
    {
      Header: "Action",
      Footer: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <>
          {/* <button className="btn btn-primary shadow btn-xs sharp me-1" onClick={openEditModal}>
            <i className="fas fa-pencil-alt"></i>
          </button> */}
          {/* {!user.is_verified && (
            <div>
              <br />
              <button className="btn btn-primary" onClick={handleVerifyEmail}>
                {" "}
                Verify Email
              </button>
            </div>
          )} */}
          {row.original.referred.length != 0 && (
            <button className="btn btn-primary shadow btn-xs sharp me-1" onClick={() => openReferredModal(row.original)}>
              <i className="fa fa-users"></i>
            </button>
          )}

          <a href="#" className="btn btn-primary shadow btn-xs sharp me-1" onClick={() => openEditModal(row.original)}>
            <i className="fas fa-pencil-alt"></i>
          </a>
          <button className="btn btn-danger shadow btn-xs sharp me-1" onClick={() => handleBlockPartner(row.original)}>
            <i className="fa fa-trash"></i>
          </button>
          <button className="btn btn-danger shadow btn-xs sharp me-1" onClick={() => handleBlockPartnerWithdraws(row.original)}>
            <i className="fa-solid fa-ban"></i>
          </button>
          <button className="btn btn-primary shadow btn-xs sharp me-1" onClick={() => handleUpdateVerifyStatus(row.original)}>
            <i className="fa fa-thumbs-up"></i>
          </button>
        </>
      ),
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => MOCK_DATA, []);

  const tdata = props.partners.partners_pagination;
  const tableInstance = useTable({ columns, data: tdata }, useSortBy);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //footerGroups,
    rows,
    prepareRow,
  } = tableInstance;
  // const [selectOption , setSelectOption] = useState('Gender');
  const [partnerFoundByPhone, setPartnerFoundByPhone] = useState({});
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [country, setCountry] = useState();
  const [partnerPhone, setPartnerPhone] = useState("");
  const [partnerEmail, setPartnerEmail] = useState("");

  const [updatePartnerByPhone, setUpdatePartnerByPhone] = useState(false);

  const [isReferred, setIsReferred] = useState(false);
  const [balanceFrom, setBalanceFrom] = useState();
  const [balanceTo, setBalanceTo] = useState();
  const [stakeFrom, setStakeFrom] = useState();
  const [stakeTo, setStakeTo] = useState();
  const handleSelectCountry = (selectedOption) => {
    setCountry(selectedOption.label);
  };

  const handleSearchPartner = (e) => {
    e.preventDefault();
    // Access the selected date range
    // console.log("Selected date range:", dateRange);
    dispatch(partnerSearchAction(dateRange, country, balanceFrom, balanceTo, stakeFrom, stakeTo, isReferred));
    setFilters(true);
    // Further processing or submission logic
  };

  useEffect(() => {
    if (!filters) {
      dispatch(getPartnersWithPaginationAction(1));
      setTotalPages(props.partners.total_pages);
      // console.log("TotalPages: ", totalPages);
    } else {
      // console.log(props.partners);
    }
  }, [filters]);

  useEffect(() => {
    // dispatch(getPartnersWithPaginationAction(1));
    // setTotalPages(props.partners.total_pages);
    setUpdatePartnerByPhone(true);
    setPartnerFoundByPhone(props.partners.partner_by_phone);
    // console.log("Parnter by Phone Searched", props.partners.partner_by_phone);
    const partnerObj = {
      id: props.partners.partner_by_phone._id,
      name: props.partners.partner_by_phone.name,
      email: props.partners.partner_by_phone.email,
      role: props.partners.partner_by_phone.role,
      balance: props.partners.partner_by_phone.balance,
      total_staked: props.partners.partner_by_phone.total_staked,
      country: props.partners.partner_by_phone.country,
      gender: props.partners.partner_by_phone.gender,
      is_verified: props.partners.partner_by_phone.is_verified,
      isBlocked: props.partners.partner_by_phone.isBlocked,
      phone: props.partners.partner_by_phone.phone,
      referral_code: props.partners.partner_by_phone.referral_code,
      referral_level: props.partners.partner_by_phone.referral_level,
      is_referred: props.partners.partner_by_phone.referred_by ? "referred" : "NotReferred",
      tier: props.partners.partner_by_phone.tier,
      total_staked: props.partners.partner_by_phone.total_staked,
      wallet_address: props.partners.partner_by_phone.wallet_address,
    };
    setPartner(partnerObj);
    if (!Object.keys(props.partners.partner_by_phone).length) return;
    setEditPartnerByPhoneModal(true);
  }, [props.partners.partner_by_phone]);

  useEffect(() => {
    // dispatch(getPartnersWithPaginationAction(1));
    // setTotalPages(props.partners.total_pages);
    setUpdatePartnerByPhone(true);
    setPartnerFoundByPhone(props.partners.partner_by_email);
    // console.log("Parnter by Phone Searched", props.partners.partner_by_phone);
    const partnerObj = {
      id: props.partners.partner_by_email._id,
      name: props.partners.partner_by_email.name,
      email: props.partners.partner_by_email.email,
      role: props.partners.partner_by_email.role,
      balance: props.partners.partner_by_email.balance,
      total_staked: props.partners.partner_by_email.total_staked,
      country: props.partners.partner_by_email.country,
      gender: props.partners.partner_by_email.gender,
      is_verified: props.partners.partner_by_email.is_verified,
      isBlocked: props.partners.partner_by_email.isBlocked,
      phone: props.partners.partner_by_email.phone,
      referral_code: props.partners.partner_by_email.referral_code,
      referral_level: props.partners.partner_by_email.referral_level,
      is_referred: props.partners.partner_by_email.referred_by ? "referred" : "NotReferred",
      tier: props.partners.partner_by_email.tier,
      total_staked: props.partners.partner_by_email.total_staked,
      wallet_address: props.partners.partner_by_email.wallet_address,
      is_withdraw_disabled: props.partners.partner_by_email.is_withdraw_disabled,
    };
    setPartner(partnerObj);
    if (!Object.keys(props.partners.partner_by_email).length) return;
    setEditPartnerByPhoneModal(true);
  }, [props.partners.partner_by_email]);

  const handleSearchByPhone = (e) => {
    e.preventDefault();
    // Access the selected date range
    dispatch(partnerSearchByPhoneAction(partnerPhone));

    // dispatch(partnerSearchAction(dateRange, country, balanceFrom, balanceTo, stakeFrom, stakeTo, isReferred));
    // Further processing or submission logic
  };

  const handleSearchByEmail = (e) => {
    e.preventDefault();
    // console.log("Partner Email: ", partnerEmail);
    dispatch(partnerSearchByEmailAction(partnerEmail));
  };
  const handleDateRangeChange = (startDate, endDate) => {
    // Update the selected date range in state
    setDateRange({ startDate, endDate });
    // console.log(dateRange);
  };

  const HandleClearFilters = () => {
    setFilters(false);
    dispatch(getPartnersWithPaginationAction(currentPage));
  };
  const openPartnerInfoModal = (partner) => {
    setPartnerInfo(partner);
    setPartnerInfoModal(true);
  };
  const closePartnerInfoModal = () => {
    setPartnerInfoModal(false);
  };
  const handlePageClick = (pageNumber) => {
    console.log("Clicked on page:", pageNumber);
    setCurrentPage(pageNumber);
    console.log(currentPage);
    dispatch(getPartnersWithPaginationAction(pageNumber));
    // Add your logic for handling the page click here
  };
  const handleNextPage = () => {
    setTotalPages(props.partners.total_pages);
    if (fifthPage.value > totalPages && totalPages != 0) return;
    setFirstPage((prevPage) => ({
      ...prevPage,
      value: prevPage.value + 5,
    }));
    setSecondPage((prevPage) => ({
      ...prevPage,
      value: prevPage.value + 5,
    }));
    setThirdPage((prevPage) => ({
      ...prevPage,
      value: prevPage.value + 5,
    }));
    setFourthPage((prevPage) => ({
      ...prevPage,
      value: prevPage.value + 5,
    }));
    setFifthPage((prevPage) => ({
      ...prevPage,
      value: prevPage.value + 5,
    }));
  };

  const handlePreviousPage = () => {
    if (firstPage.value === 1) return;
    setFirstPage((prevPage) => ({
      ...prevPage,
      value: prevPage.value - 5,
    }));
    setSecondPage((prevPage) => ({
      ...prevPage,
      value: prevPage.value - 5,
    }));
    setThirdPage((prevPage) => ({
      ...prevPage,
      value: prevPage.value - 5,
    }));
    setFourthPage((prevPage) => ({
      ...prevPage,
      value: prevPage.value - 5,
    }));
    setFifthPage((prevPage) => ({
      ...prevPage,
      value: prevPage.value - 5,
    }));
  };

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    };
    const formattedDate = new Date(dateTimeString).toLocaleString("en-US", options);
    return formattedDate;
  };
  const openFiltersModal = () => {
    // console.log("Clicked");
    setFiltersModal(true);
  };
  const closeFiltersModal = () => {
    setFiltersModal(false);
  };
  return (
    <>
      {/******************************* Modal to update Partner Data *****************************************/}
      <Modal className="modal fade" show={editModal} onHide={() => closeEditModal()}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update Partner</h5>
            <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeEditModal()}>
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            {/* Form for Pool Creation */}
            <form className="comment-form" onSubmit={(e) => handleUpdatePartner(e)}>
              <div className="row">
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Name <span className="required">*</span>
                      {""}
                    </label>
                    <input type="text" value={partner.name} className="form-control" onChange={(e) => handleInputChange("name", e.target.value)} name="partnerName" placeholder="Enter Pool Name" />
                    {/* <input type="text" value={pool._id} style={{ visibility: "hidden" }} name="poolId" placeholder="POOL_ID" /> */}

                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Email <span className="required">*</span>
                      {""}
                    </label>
                    <input type="text" value={partner.email} className="form-control" onChange={(e) => handleInputChange("email", e.target.value)} name="poolName" placeholder="Enter Pool Name" />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      Role
                    </label>
                    <Select
                      options={[
                        { value: "1", label: "admin" },
                        { value: "2", label: "partner" },
                      ]}
                      className="custom-react-select"
                      defaultValue={partner.role}
                      onChange={handleSelectChange}
                      isSearchable={true}
                      placeholder={"Select Role"}
                    />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Balance <span className="required">*</span>
                      {""}
                    </label>
                    <input
                      type="number"
                      value={partner.balance}
                      className="form-control"
                      onChange={(e) => handleInputChange("balance", parseFloat(e.target.value) || 0)}
                      name="poolName"
                      placeholder="Enter Pool Name"
                    />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Staked <span className="required">*</span>
                      {""}
                    </label>
                    <input
                      type="number"
                      value={partner.total_staked}
                      className="form-control"
                      onChange={(e) => handleInputChange("total_staked", e.target.value)}
                      name="poolName"
                      placeholder="Enter Pool Name"
                    />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <input
                  type="text"
                  value={partner.role}
                  style={{ visibility: "hidden" }}
                  className="form-control"
                  onChange={(e) => handleInputChange("role", e.target.value)}
                  name="poolName"
                  placeholder="Enter Pool Name"
                />
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <input type="submit" value="Update" className="submit btn btn-primary" name="submit" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/******************************* Modal to update Partner Data by phone *****************************************/}
      <Modal className="modal fade" show={editPartnerByPhoneModal} onHide={() => closeEditPartnerByPhoneModal()}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">View details and Update Partner Data</h5>
            <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeEditPartnerByPhoneModal()}>
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            {/* Form for Pool Creation */}
            <form className="comment-form" onSubmit={(e) => handleUpdatePartner(e)}>
              <div className="row">
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Balance
                      {""}
                    </label>
                    <input
                      type="number"
                      value={partner.balance}
                      className="form-control"
                      onChange={(e) => handleInputChange("balance", e.target.value)}
                      name="poolName"
                      placeholder="Enter Pool Name"
                    />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Is Referred
                      {""}
                    </label>
                    <input
                      type="text"
                      value={partner.is_referred}
                      className={partner.is_referred === "referred" ? "form-control text-success" : "form-control text-danger"}
                      onChange={(e) => handleInputChange("balance", e.target.value)}
                      name="poolName"
                      placeholder="Enter Pool Name"
                    />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <div className="form-check">
                      <input type="checkbox" checked={partner.is_verified} onChange={(e) => handleInputChange("is_verified", e.target.checked)} name="is_verified" className="form-check-input" />
                      <label className="form-check-label">Verification Status</label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <div className="form-check">
                      <input type="checkbox" checked={partner.isBlocked} onChange={(e) => handleInputChange("isBlocked", e.target.checked)} name="isBlocked" className="form-check-input" />
                      <label className="form-check-label">Block Status</label>
                    </div>
                  </div>
                  {/* <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Block Status
                      {""}
                    </label>
                    <input type="checkbox" value={partner.isBlocked} className="" onChange={(e) => handleInputChange("isBlocked", e.target.checked)} name="isBlocked" placeholder="" />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  {/* </div> */}
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        checked={partner.is_withdraw_disabled}
                        onChange={(e) => handleInputChange("is_withdraw_disabled", e.target.checked)}
                        name="withdrawDisabled"
                        className="form-check-input"
                      />
                      <label className="form-check-label">Withdraws Disabled</label>
                    </div>
                  </div>
                  {/* <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Block Status
                      {""}
                    </label>
                    <input type="checkbox" value={partner.isBlocked} className="" onChange={(e) => handleInputChange("isBlocked", e.target.checked)} name="isBlocked" placeholder="" />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  {/* </div> */}
                </div>

                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Tier <span className="required">*</span>
                      {""}
                    </label>
                    <input disabled type="text" value={partner.tier} className="form-control" onChange={(e) => handleInputChange("tier", e.target.value)} name="country" placeholder="" />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Name <span className="required">*</span>
                      {""}
                    </label>
                    <input
                      disabled
                      type="text"
                      value={partner.name}
                      className="form-control"
                      onChange={(e) => handleInputChange("name", e.target.value)}
                      name="partnerName"
                      placeholder="Enter Pool Name"
                    />
                    {/* <input type="text" value={pool._id} style={{ visibility: "hidden" }} name="poolId" placeholder="POOL_ID" /> */}

                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Email <span className="required">*</span>
                      {""}
                    </label>
                    <input
                      disabled
                      type="text"
                      value={partner.email}
                      className="form-control"
                      onChange={(e) => handleInputChange("email", e.target.value)}
                      name="poolName"
                      placeholder="Enter Pool Name"
                    />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Phone <span className="required">*</span>
                      {""}
                    </label>
                    <input disabled type="text" value={partner.phone} className="form-control" onChange={(e) => handleInputChange("phone", e.target.value)} name="country" placeholder="" />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Total Staked <span className="required">*</span>
                      {""}
                    </label>
                    <input
                      disabled
                      type="text"
                      value={partner.total_staked}
                      className="form-control"
                      onChange={(e) => handleInputChange("total_staked", e.target.value)}
                      name="country"
                      placeholder=""
                    />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Wallet Address <span className="required">*</span>
                      {""}
                    </label>
                    <input
                      disabled
                      type="text"
                      value={partner.wallet_address}
                      className="form-control"
                      onChange={(e) => handleInputChange("wallet_address", e.target.value)}
                      name="country"
                      placeholder=""
                    />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Country <span className="required">*</span>
                      {""}
                    </label>
                    <input disabled type="text" value={partner.country} className="form-control" onChange={(e) => handleInputChange("country", e.target.value)} name="country" placeholder="" />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Gender <span className="required">*</span>
                      {""}
                    </label>
                    <input disabled type="text" value={partner.gender} className="form-control" onChange={(e) => handleInputChange("gender", e.target.value)} name="country" placeholder="" />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Referral Code <span className="required">*</span>
                      {""}
                    </label>
                    <input
                      disabled
                      type="text"
                      value={partner.referral_code}
                      className="form-control"
                      onChange={(e) => handleInputChange("referral_code", e.target.value)}
                      name="country"
                      placeholder=""
                    />
                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <input type="submit" value="Update" className="submit btn btn-primary" name="submit" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/******************************* Modal to view Referred Partners*****************************************/}
      <Modal className="modal fade" show={referredModal} onHide={() => closeReferredModal()}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Referred Parnters</h5>
            <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeReferredModal()}>
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            {/* Form for Pool Creation */}

            {partnerInfo.referred && Array.isArray(partnerInfo.referred) && (
              <p>
                <strong>Referred Count:</strong> {partnerInfo.referred.length}
              </p>
            )}
            <div className="table-responsive recentOrderTable">
              <table className="table verticle-middle table-responsive-md">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Amount Staked</th>
                  </tr>
                </thead>
                <tbody>
                  {referredPartners.map((partner, index) => (
                    <tr key={index}>
                      <td>{partner.name}</td>
                      <td>{partner.email}</td>
                      <td>{partner.phone}</td>
                      <td>{partner.balance}</td>
                      <td>{partner.amount_staked}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>

      {/******************************* Modal For Partner Info *****************************************/}
      <Modal className="modal fade" show={partnerInfoModal} onHide={() => closePartnerInfoModal()}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Partner Info</h5>
            <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closePartnerInfoModal()}>
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            {/* Partner Profile here */}
            <p>
              <strong>Name:</strong> {partnerInfo.name}
            </p>
            <p>
              <strong>Country:</strong> {partnerInfo.country}
            </p>
            <p>
              <strong>Email:</strong> {partnerInfo.email}
            </p>
            <p>
              <strong>Phone:</strong> {partnerInfo.phone}
            </p>
            <p>
              <strong>Wallet Address:</strong> {partnerInfo.wallet_address}
            </p>
            <p>
              <strong>Tier:</strong> {partnerInfo.tier}
            </p>
            <p>
              <strong>Role:</strong> {partnerInfo.role}
            </p>

            <p className={partnerInfo.isBlocked ? "text-danger" : "text_successs"}>
              <strong>Status:</strong>
              {partnerInfo.isBlocked ? " Blocked" : " Not Blocked"}
            </p>

            <p>
              <strong>Referral Code:</strong> {partnerInfo.referral_code}
            </p>
            <p>
              <strong>Balace:</strong> {partnerInfo.balance}
            </p>
            <p>
              <strong>Referral Level:</strong> {partnerInfo.referral_level}
            </p>
            <p>
              <strong>Total Staked:</strong> {partnerInfo.total_staked}
            </p>
            <p>
              <strong>Created At:</strong> {partnerInfo.created_at}
            </p>
            {partnerInfo.referred && Array.isArray(partnerInfo.referred) && (
              <p>
                <strong>Referred Count:</strong> {partnerInfo.referred.length}
              </p>
            )}
          </div>
        </div>
      </Modal>
      {/* Pagination Component */}
      <div className="row">
        <div className="col">
          <div className="card">
            {/* <div className="card-header">
              <h6 className="title">Partners</h6>
            </div> */}
            <div className="card-body">
              <h6 className="title">Partners: {props.partners.total_partners}</h6>
            </div>
          </div>
        </div>{" "}
        <div className="col">
          <div className="card">
            {/* <div className="card-header">
              <h6 className="title">Partners</h6>
            </div> */}
            <div className="card-body">
              <h6 className="title">Depositors: {props.partners.total_depositors}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <h5>Find Partner By Email or Phone</h5>
              <div className=" mt-2 close">
                <Button className="me-2" onClick={openFiltersModal}>
                  <i class="bi bi-filter-square-fill"></i>
                </Button>
                <Button type="button" href="#" onClick={HandleClearFilters}>
                  Clear Filters
                </Button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <form onSubmit={handleSearchByEmail}>
                    <p className="mb-1">Email</p>
                    {/* <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" type="email" /> */}

                    <input value={partnerEmail} className="form-control" type="email" onChange={(e) => setPartnerEmail(e.target.value)} />

                    <br />
                    <button href="#" type="" style={{ "margin-top": "10px" }} className="btn btn-primary shadow ">
                      Find
                    </button>
                  </form>
                </div>
                <div className="col-6">
                  <form onSubmit={handleSearchByPhone}>
                    <p className="mb-1">Phone Number</p>
                    {/* <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" type="email" /> */}

                    <input value={partnerPhone} className="form-control" type="text" onChange={(e) => setPartnerPhone(e.target.value)} />

                    <br />
                    <button href="#" type="" style={{ "margin-top": "13px" }} className="btn btn-primary shadow ">
                      Find
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/******************************* Modal For Filters *****************************************/}
      <Modal className="fade bd-example-modal-lg" size="lg" show={filtersModal} onHide={() => closeFiltersModal()}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Filter</h5>
            <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeFiltersModal()}>
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            {/******************************* Filters *****************************************/}
            <div className="row">
              <div className="card">
                <form onSubmit={handleSearchPartner}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <p className="mb-1">Date Range</p>
                        <DateRangePicker
                          initialSettings={{
                            showDropdowns: true,
                            showYearDropdown: true,
                          }}
                          onApply={(event, picker) => {
                            handleDateRangeChange(picker.startDate, picker.endDate);
                          }}
                        >
                          <input type="text" className="form-control input-daterange-timepicker" />
                        </DateRangePicker>
                      </div>
                      <div className="col">
                        <label className="form-label">Country</label>
                        <Select options={options3} onChange={handleSelectCountry} className="custom-react-select" defaultValue={""} isSearchable={true} placeholder={"Select Country"} />
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <label className="form-label">Balance From</label>
                            <input type="number" value={balanceFrom} className="form-control" onChange={(e) => setBalanceFrom(e.target.value)} defaultValue={""} placeholder="" />
                          </div>

                          <div className="col">
                            <label className="form-label">Balance to</label>
                            <input value={balanceTo} className="form-control" onChange={(e) => setBalanceTo(e.target.value)} defaultValue={""} placeholder="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <label className="form-label">Stake From</label>
                            <input value={stakeFrom} className="form-control" onChange={(e) => setStakeFrom(e.target.value)} defaultValue={""} placeholder="" />
                          </div>

                          <div className="col">
                            <label className="form-label">Stake to</label>
                            <input value={stakeTo} className="form-control" onChange={(e) => setStakeTo(e.target.value)} defaultValue={""} placeholder="" />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check form-check-inline"></div>
                        <div className="form-check mb-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => {
                              setIsReferred(e.target.checked);
                              // console.log(isReferred);
                            }}
                            id="check1"
                            defaultChecked={isReferred}
                          />
                          <label className="form-check-label" htmlFor="check1">
                            Referral Sign-Up
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button href="#" type="submit" className="btn btn-primary shadow " onClick={() => console.log("clicked")}>
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/******************************* Table to show Partners *****************************************/}
      {!filters && (
        <div className="row">
          <div className="col">
            <div className="card profile-card card-bx m-b30">
              <div className="card-header">
                <h6 className="title">Partners</h6>
              </div>
              <div className="table-responsive">
                <div className="dataTables_wrapper">
                  <table {...getTableProps()} className="table dataTable display">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              <span className="ml-1">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: "0.7" }} />
                                  ) : (
                                    <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: "0.7" }} />
                                  )
                                ) : (
                                  <i className="fa fa-sort ms-2 fs-14" style={{ opacity: "0.3" }} />
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            <td>{index + 1}</td> {/* numerates each record in table */}
                            {row.cells.map((cell) => {
                              const userBalance = row.original.balance; // Assuming the balance is accessible from row.original
                              if (cell.render("Cell").props.cell.column.Header === "Name" && userBalance > 0) {
                                // Here I want to check if the user has balance greater than 0 then I want to render some aditional htlm along with the name of user
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    <a href="#" onClick={() => openPartnerInfoModal(row.original)}>
                                      <strong>
                                        {cell.render("Cell").props.value}
                                        <span>
                                          <svg style={{ marginLeft: "5px" }} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" position="absolute" viewBox="0 0 16 16">
                                            <path fill="#2583ef" d="M8,0C3.582,0,0,3.582,0,8s3.582,8,8,8s8-3.582,8-8S12.418,0,8,0z"></path>
                                            <polygon fill="#fff" points="7,12 3.48,8.48 4.894,7.066 7,9.172 11.71,4.462 13.124,5.876"></polygon>
                                          </svg>
                                        </span>
                                      </strong>
                                    </a>{" "}
                                  </td>
                                );
                              } else if (cell.render("Cell").props.cell.column.Header === "Name") {
                                // Here I want to check if the user has balance greater than 0 then I want to render some aditional htlm along with the name of user
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    <a href="#" onClick={() => openPartnerInfoModal(row.original)}>
                                      <strong>{cell.render("Cell").props.value}</strong>
                                    </a>{" "}
                                  </td>
                                );
                              } else if (cell.render("Cell").props.column.Header === "Status" && cell.render("Cell").props.value === true) {
                                return (
                                  <td className="text-danger" {...cell.getCellProps()}>
                                    {" "}
                                    Blocked{" "}
                                  </td>
                                );
                              } else if (cell.render("Cell").props.column.Header === "Status" && cell.render("Cell").props.value === false) {
                                return (
                                  <td className="text-success" {...cell.getCellProps()}>
                                    {" "}
                                    Not Blocked{" "}
                                  </td>
                                );
                              } else if (cell.render("Cell").props.column.Header === "IsReferred" && cell.render("Cell").props.value) {
                                return (
                                  <td className="text-success" {...cell.getCellProps()}>
                                    {" "}
                                    referred{" "}
                                  </td>
                                );
                              } else if (cell.render("Cell").props.column.Header === "IsReferred") {
                                return (
                                  <td className="text-danger" {...cell.getCellProps()}>
                                    {" "}
                                    Not referred{" "}
                                  </td>
                                );
                              } else if (cell.render("Cell").props.column.Header === "Withdraw_Disabled" && cell.render("Cell").props.value) {
                                return (
                                  <td className="text-danger" {...cell.getCellProps()}>
                                    {" "}
                                    Disabled{" "}
                                  </td>
                                );
                              } else if (cell.render("Cell").props.column.Header === "Withdraw_Disabled") {
                                return (
                                  <td className="text-success" {...cell.getCellProps()}>
                                    {" "}
                                    Enabled{" "}
                                  </td>
                                );
                              } else if (cell.render("Cell").props.column.Header === "IsVerified" && cell.render("Cell").props.value === true) {
                                return (
                                  <td className="text-success" {...cell.getCellProps()}>
                                    {" "}
                                    verified{" "}
                                  </td>
                                );
                              } else if (cell.render("Cell").props.column.Header === "IsVerified" && cell.render("Cell").props.value === false) {
                                return (
                                  <td className="text-danger" {...cell.getCellProps()}>
                                    {" "}
                                    Not verified{" "}
                                  </td>
                                );
                              } else if (cell.render("Cell").props.cell.column.Header === "Joined") {
                                // if (cell.render("Cell").props.value === "Created At") {
                                return <td {...cell.getCellProps()}>{formatDate(cell.render("Cell").props.value)}</td>;
                              }

                              return <td {...cell.getCellProps()}> {cell.render("Cell")} </td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    {/* This is only for footer if u require */}
                  </table>
                </div>
                <div className="d-flex justify-content-end">
                  <Pagination size="sm" className="">
                    {/* <Pagination.First /> */}
                    <Pagination.Prev onClick={handlePreviousPage} />
                    {/* <Pagination.Ellipsis /> */}
                    <Pagination.Item
                      key={firstPage.value}
                      active={firstPage.value === currentPage}
                      disabled={firstPage.value > totalPages && totalPages != 0}
                      onClick={() => handlePageClick(firstPage.value)}
                    >
                      {firstPage.value}
                    </Pagination.Item>

                    <Pagination.Item
                      key={secondPage.value}
                      active={secondPage.value === currentPage}
                      disabled={secondPage.value > totalPages && totalPages != 0}
                      onClick={() => handlePageClick(secondPage.value)}
                    >
                      {secondPage.value}
                    </Pagination.Item>

                    <Pagination.Item
                      key={thirdPage.value}
                      active={thirdPage.value === currentPage}
                      disabled={thirdPage.value > totalPages && totalPages != 0}
                      onClick={() => handlePageClick(thirdPage.value)}
                    >
                      {thirdPage.value}
                    </Pagination.Item>

                    <Pagination.Item
                      key={fourthPage.value}
                      active={fourthPage.value === currentPage}
                      disabled={fourthPage.value > totalPages && totalPages != 0}
                      onClick={() => handlePageClick(fourthPage.value)}
                    >
                      {fourthPage.value}
                    </Pagination.Item>

                    <Pagination.Item
                      key={fifthPage.value}
                      active={fifthPage.value === currentPage}
                      disabled={fifthPage.value > totalPages && totalPages != 0}
                      onClick={() => handlePageClick(fifthPage.value)}
                    >
                      {fifthPage.value}
                    </Pagination.Item>
                    {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((pageNumber) => (
                  <Pagination.Item key={pageNumber} onClick={() => handlePageClick(pageNumber)}>
                    {pageNumber}
                  </Pagination.Item>
                ))} */}

                    <Pagination.Next onClick={handleNextPage} />

                    {/* <Pagination.Last /> */}
                  </Pagination>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {filters && (
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Filtered Partners</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive recentOrderTable">
                {/* <div className="table-container"> */}
                <table className="table verticle-middle table-responsive-md">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Country</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">isVerified</th>
                      <th scope="col">Referral-Level</th>
                      <th scope="col">Role</th>
                      <th scope="col">Status</th>
                      <th scope="col">Balance</th>
                      <th scope="col">Staked</th>
                      <th scope="col">IsReferred</th>
                      <th scope="col">Joined</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {props.partners.partners.map((partner, index) => (
                      <tr key={index}>
                        <td>{partner.name}</td>
                        <td>{partner.country}</td>
                        <td>{partner.phone}</td>
                        <td>{partner.email}</td>
                        <td>{partner.is_verified}</td>
                        <td>{partner.referral_level}</td>
                        <td>{partner.role}</td>
                        <td>{partner.status}</td>
                        <td>{partner.balance}</td>
                        <td>{partner.total_staked}</td>
                        <td>{partner.is_referred}</td>
                        <td>{partner.created_at}</td>
                        {/* <td>{"Action"}</td> */}

                        {/* <td>{calculateProfitPercentage(user.tier, partner.duration) + "%"}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  partner_by_phone: state.partner_by_phone,
  partner_by_email: state.partner_by_email,
  partners_pagination: state.partners_pagination,
  total_pages: state.total_pages,
  page_num: state.page_num,
  total_partners: state.total_partners,
  total_depositors: state.total_depositors,
  showLoading: state.showLoading,
  error: state.error,
});
export default connect(mapStateToProps)(ManagePartners);
