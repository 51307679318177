import React, { useState, useMemo, useEffect, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, Modal, Card, Tab, Nav } from "react-bootstrap";
import {
  cancelWithdraTransactionByAdminAction,
  getTotalsAction,
  updateTotalsAction,
  getUnProcessedWithdrawlTransactionsAction,
  updateWithdrawRequestAction,
} from "../../../store/actions/TransactionsActions";

const ManageFunds = (props) => {
  const [refreshTotals, setRefreshTotals] = useState(false);
  const [withdrawApprovalModal, setWithdrawApprovalModal] = useState(false);
  const [monthlyStatsModal, setMonthlyStatsModal] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [partnerEmail, setPartnerEmail] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [partnerBalance, setPartnerBalance] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [tronTransactionId, setTrontransactionId] = useState("");
  let errorsObj = { transId: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [isWithdrawCanceled, setIsWithdrawCanceled] = useState(false);

  const dispatch = useDispatch();

  const openWithdrawModal = (transaction) => {
    setWithdrawApprovalModal(true);
    setTransactionId(transaction._id);
    setTransactionType(transaction.type);
    setPartnerId(transaction.partner._id);
    setPartnerName(transaction.partner.name);
    setPartnerEmail(transaction.partner.email);
    setWithdrawAmount(transaction.amount);
    setPartnerBalance(transaction.partner.balance);
  };

  const closeWithdrawModal = () => {
    setWithdrawApprovalModal(false);
    setTrontransactionId("");
  };

  const openMonthlyStatsModal = (transaction) => {
    setMonthlyStatsModal(true);
  };

  const closeMonthlyStatsModal = () => {
    setMonthlyStatsModal(false);
  };

  useEffect(() => {
    dispatch(getTotalsAction());
    dispatch(getUnProcessedWithdrawlTransactionsAction());
  }, [refreshTotals, isWithdrawCanceled]);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  const handleUpdateTransaction = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (tronTransactionId === "") {
      errorObj.transId = "TransactionId is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(updateWithdrawRequestAction(transactionId, partnerId, tronTransactionId));
    setTimeout(() => {
      dispatch(getUnProcessedWithdrawlTransactionsAction());
      dispatch(getTotalsAction());
      setTrontransactionId("");
    }, 2000);
    closeWithdrawModal();
  };

  const handleDateRangeChange = (startDate, endDate) => {
    // Update the selected date range in state
    setDateRange({ startDate, endDate });
  };

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    };
    const formattedDate = new Date(dateTimeString).toLocaleString("en-US", options);
    return formattedDate;
  };
  const handleRefreshTotals = () => {
    setRefreshTotals(!refreshTotals);
    // console.log(refreshTotals);
  };
  const handleUpdateTotals = () => {
    dispatch(updateTotalsAction());
    // setRefreshTotals(!refreshTotals);
    // console.log(refreshTotals);
  };
  const handleCancelWithdraw = (_withdraw) => {
    const withdraw = {
      _id: _withdraw._id,
    };
    dispatch(cancelWithdraTransactionByAdminAction(withdraw));
    setTimeout(() => {
      setIsWithdrawCanceled(!isWithdrawCanceled);
    }, 2000);
  };
  return (
    <>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {/******************************** Cards to show Totals ***************************/}
        <div className="row">
          <div className="col">
            <div class="container text-center">
              <div class="row">
                <div class="col">
                  <div className="widget-stat card bg-secondary ">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="la la-dollar"></i>
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">Total Deposit</p>
                          <h3 className="text-white">{props.totals != 0 ? props.totals.total_deposit : ""}$</h3>
                          <div className="progress mb-2 bg-secondary">
                            <div className="progress-bar progress-animated bg-white" style={{ width: "30%" }}></div>
                          </div>
                          <small>30% Increase in 30 Days</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div className="widget-stat card bg-secondary ">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="la la-dollar"></i>
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">Owe Sum</p>
                          <h3 className="text-white">{props.totals != 0 ? props.totals.owe_sum : ""}$</h3>
                          <div className="progress mb-2 bg-secondary">
                            <div className="progress-bar progress-animated bg-white" style={{ width: "30%" }}></div>
                          </div>
                          <small>30% Increase in 30 Days</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div className="widget-stat card bg-secondary ">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="la la-dollar"></i>
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">Total Withdraws</p>
                          <h3 className="text-white">{props.totals.length != 0 ? props.totals.total_withdrawls : ""}$</h3>
                          <div className="progress mb-2 bg-secondary">
                            <div className="progress-bar progress-animated bg-white" style={{ width: "30%" }}></div>
                          </div>
                          <small>30% Increase in 30 Days</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div className="widget-stat card bg-secondary ">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="la la-dollar"></i>
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">Total Withdraws In-Process </p>
                          <h3 className="text-white">{props.totals.length != 0 ? props.totals.total_withdrawls_in_process : ""}$</h3>
                          <div className="progress mb-2 bg-secondary">
                            <div className="progress-bar progress-animated bg-white" style={{ width: "30%" }}></div>
                          </div>
                          <small>30% Increase in 30 Days</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col d-flex  align-items-center">
                  <div className="card" style={{ backgroundColor: "transparent" }}>
                    <button class="btn btn-primary shadow" style={{ visibility: "hidden" }} onClick={handleRefreshTotals}>
                      Refresh Totals
                    </button>
                    <button class="btn btn-primary shadow" style={{ visibility: "hidden" }} onClick={handleRefreshTotals}>
                      Refresh Totals
                    </button>
                    <button class="btn btn-primary shadow" style={{ marginBottom: "0px" }} onClick={handleUpdateTotals}>
                      Update Totals
                    </button>
                  </div>
                </div>
                <div class="col d-flex  align-items-center">
                  <div className="card" style={{ backgroundColor: "transparent" }}>
                    <button class="btn btn-primary shadow" style={{ visibility: "hidden" }} onClick={handleRefreshTotals}>
                      Refresh Totals
                    </button>
                    <button class="btn btn-primary shadow" style={{ visibility: "hidden" }} onClick={handleRefreshTotals}>
                      Refresh Totals
                    </button>
                    <button class="btn btn-primary shadow" style={{ marginBottom: "0px" }} onClick={openMonthlyStatsModal}>
                      Monthly Stats
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/******************************** Withdraw Requests Table ***************************/}
        <div className="row">
          <div className="col">
            <div className="card profile-card card-bx m-b30">
              <Card>
                {/* <Card.Header>
              <Card.Title>Default Tab</Card.Title>
            </Card.Header> */}
                <Card.Body>
                  {/* <!-- Nav tabs --> */}
                  <div className="default-tab">
                    <Tab.Container defaultActiveKey="un-processed">
                      <Nav as="ul" className="nav-tabs">
                        <Nav.Item as="li">
                          <Nav.Link eventKey="processed">Processed Withdraws</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="un-processed">Un-Processed Withdraws</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content className="pt-4">
                        {/* //////////////////////Processed Deposits///////////////////////////////////////// */}
                        <Tab.Pane eventKey="processed">
                          {/* <h4>This is Processed title</h4> */}
                          <div className="card-body">
                            <div className="table-responsive recentOrderTable">
                              <table className="table verticle-middle table-responsive-md">
                                <thead>
                                  <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">destination Wallet</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">transaction_id</th>
                                    <th scope="col">Balance</th>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {props.transactions.processedWithdrawl.map((withdrawl, index) => (
                                    <tr key={index}>
                                      <td>{withdrawl.type}</td>
                                      <td>{withdrawl.partner_id ? withdrawl.partner_id.name : ""}</td>
                                      <td>{withdrawl.partner_id ? withdrawl.partner_id.email : ""}</td>
                                      <td>{withdrawl.partner_id ? withdrawl.wallet_address : ""}</td>
                                      <td>{withdrawl.status}</td>
                                      <td>{withdrawl.amount}</td>
                                      <td>{withdrawl.transaction_id}</td>
                                      <td>{withdrawl.partner_id ? withdrawl.partner_id.balance : ""}</td>
                                      <td>{withdrawl.created_at}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Tab.Pane>
                        {/* //////////////////////Unprocessed Deposits///////////////////////////////////////// */}
                        <Tab.Pane eventKey="un-processed">
                          {/* <h4>This is Un-Processed title</h4> */}
                          <div className="card-body">
                            {props.transactions.unProcessedWithdrawl.length != 0 ? (
                              <div className="table-responsive recentOrderTable">
                                <table className="table verticle-middle table-responsive-md">
                                  <thead>
                                    <tr>
                                      <th scope="col">Typeeeee</th>
                                      <th scope="col">Name</th>
                                      <th scope="col">Email</th>
                                      <th scope="col">Destination Wallet</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Amount</th>
                                      <th scope="col">Balance</th>
                                      <th scope="col">Created At</th>
                                      <th scope="col">total Deposits</th>
                                      <th scope="col">Total Profits</th>
                                      <th scope="col">Total Withdrawls</th>
                                      <th scope="col">Total Return</th>
                                      <th scope="col">Process</th>
                                      <th scope="col">Cancel</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {props.transactions.unProcessedWithdrawl.map((withdrawl, index) => (
                                      <tr key={index}>
                                        <td>{withdrawl.type}</td>
                                        <td>{withdrawl.partner ? withdrawl.partner.name : ""}</td>
                                        <td>{withdrawl.partner ? withdrawl.partner.email : ""}</td>
                                        <td>{withdrawl.partner_id ? withdrawl.wallet_address : ""}</td>
                                        <td>{withdrawl.status}</td>
                                        <td>{withdrawl.amount}</td>
                                        <td>{withdrawl.partner_id ? withdrawl.partner_id.balance : ""}</td>
                                        <td>{withdrawl.created_at}</td>

                                        <td>{withdrawl.total_deposits.toFixed(2)}</td>
                                        <td>{withdrawl.total_profits.toFixed(2)}</td>
                                        <td>{withdrawl.total_withdrawls.toFixed(2)}</td>
                                        <td>{(Number(withdrawl.total_deposits.toFixed(2)) + Number(withdrawl.total_profits.toFixed(2))).toFixed(2)}</td>

                                        <td>
                                          {" "}
                                          <a href="#" class="btn btn-primary shadow btn-xs sharp me-3" onClick={() => openWithdrawModal(withdrawl)}>
                                            <i class="fas fa-pencil-alt"></i>
                                          </a>
                                        </td>
                                        <td>
                                          {withdrawl.status === "Requested" ? (
                                            <button className="btn btn-primary" onClick={() => handleCancelWithdraw(withdrawl)}>
                                              <div>Cancel</div>
                                            </button>
                                          ) : (
                                            "-"
                                          )}
                                          {/* {withdraw.status === "Served" || withdraw.status === "Canceled" ? (
                                      "-"
                                    ) : (
                                      <button className="button-cancel" onClick={() => handleCancelWithdraw(withdraw)}>
                                        <div className="button-cancel-text">Cancel</div>
                                      </button>
                                    )} */}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <p>{"No Requested Withdrawls"}</p>
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        {/******************************** Update Transaction Status Modal ***************************/}
        <div className="row">
          <Modal className="modal fade" show={withdrawApprovalModal} onHide={() => closeWithdrawModal()}>
            <div className="modal-content" style={{ overflow: "hidden" }}>
              <div className="modal-header">
                <h5 className="modal-title">Create New Pool</h5>
                <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeWithdrawModal()}>
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                {/* Form for Pool Creation */}
                <form className="comment-form" onSubmit={(e) => handleUpdateTransaction(e)}>
                  {/* <div className="row"> */}
                  <div className="row">
                    <div className="col">
                      <div className="form-group mb-3">
                        <h3 htmlFor="author" className="text-black font-w600">
                          {" "}
                          Are you sure you want to approve the following transaction?
                          {""}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <table className="table verticle-middle table-responsive-md">
                        <thead>
                          <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Name</th>
                            {/* <th scope="col">Email</th> */}
                            <th scope="col">Amount</th>
                            <th scope="col">Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{transactionType}</td>
                            <td>{partnerName}</td>
                            {/* <td>{partnerEmail}</td> */}
                            <td>{withdrawAmount}</td>
                            <td> {partnerBalance}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="form-group mb-2 mt-2">
                    <input value={tronTransactionId} onChange={(e) => setTrontransactionId(e.target.value)} required="" className="form-control" placeholder="Tron TransId" type="text" />
                    {errors.transId && <div className="text-danger fs-12">{errors.transId}</div>}
                  </div>

                  <div className="col-lg-12 text-center">
                    <div class="row justify-content-center">
                      <div class="col">
                        <div className="form-group mb-3">
                          <input type="submit" value="Approve" className="submit btn btn-success" name="submit" />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group mb-3">
                          <input type="button" value="Don't Approve" className="submit btn btn-danger" onClick={() => closeWithdrawModal()} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </form>
              </div>
            </div>
          </Modal>
        </div>
        {/******************************** Monthly Stats Modal ***************************/}
        <div className="row">
          <Modal className="modal fade" show={monthlyStatsModal} onHide={() => closeMonthlyStatsModal()}>
            <div className="modal-content" style={{ overflow: "hidden" }}>
              <div className="modal-header">
                <h5 className="modal-title">Monthly Stats</h5>
                <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeMonthlyStatsModal()}>
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                {/* Form for Pool Creation */}
                {props.transactions.monthlyStats && props.transactions.monthlyStats.length > 0 ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Year</th>
                        <th>Month</th>
                        <th>Total Deposits</th>
                        <th>Total Withdraws</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.transactions.monthlyStats.map((stat, index) => (
                        <tr key={index}>
                          <td>{stat.year}</td>
                          <td>{stat.monthName}</td>
                          <td>${stat.totalDeposits.toFixed(2)}</td>
                          <td>${stat.totalWithdrawals.toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
          </Modal>
        </div>
      </Suspense>
    </>
  );
};
const mapStateToProps = (state) => ({
  transactions: state.transactions,
  unProcessedWithdrawl: state.unProcessedWithdrawl,
  processedWithdrawl: state.processedWithdrawl,
  showLoading: state.showLoading,
  monthlyStats: state.transactions.monthlyStats,
  totals: state.transactions.totals,
  error: state.error,
});
export default connect(mapStateToProps)(ManageFunds);
