import React, { useState, useEffect, useRef, Fragment, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import { getReferredPartnersAction } from "../../store/actions/PartnersActions";

const Reward2 = (props) => {
  const dispatch = useDispatch();
  const partner = JSON.parse(localStorage.userDetails);
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const inputRef = useRef(null); // Create a ref to the input element
  const handleCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select(); // Select the input's text
      navigator.clipboard
        .writeText(inputRef.current.value) // Copy the selected text to the clipboard
        .then(() => {
          setCopyButtonText("Copied");
          setTimeout(() => {
            setCopyButtonText("Copy");
          }, 10000);
        })
        .catch((error) => {
          console.error("Error copying text:", error);
        });
    }
  };
  useEffect(() => {
    //  dispatch(loadingToggleAction(true));
    dispatch(getReferredPartnersAction());
  }, []);
  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {/* //////////////////////Referral Link///////////////////////////////////////// */}
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
          <div className="pools-container">
            <h4 className="pool-heading">Rewards</h4>
            <h2 className="text-pools">
              Dashboard/ <span className="text-ppols-1"> Reward</span>
            </h2>
            <h4 className="text-heading-reward-mobile">Referred Partners</h4>
          </div>
          <div className="reward-section">
            <div className="referral-link">
              <div className="reward-card-title">
                <h5 className="link-tag">
                  {""} {""} {""} Referral Link
                </h5>
                <div className="total-user">
                  Total Referred Partners {""} {""}
                  <span className="user-count">
                    {props.partners.referredPartners.length} Users
                  </span>
                </div>
              </div>
              <div className="input-fieid-reward">
                <textarea
                  type="text"
                  className="input-reward-text"
                  defaultValue={
                    process.env.REACT_APP_MYARBIT_DashboardUrl +
                    `register?ref=${partner.referral_code}`
                  }
                  disabled
                  ref={inputRef}
                />
                <button
                  className="reward-copy-button"
                  type="button"
                  onClick={handleCopyClick}
                >
                  {copyButtonText}
                </button>
              </div>
              <div class="referrals-system-container">
                <h1 class="referrals-main-heading">Our Referrals System</h1>
                <p class="referrals-description">
                  Ready to boost your earnings? Our referral system rewards you
                  for sharing our products or services. With limitless earning
                  potential, it's time to spread the word and start earning!
                </p>
              </div>
              <div class="referrals-system-container">
                <h1 class="referrals-main-heading">
                  How Our Referral System Works
                </h1>
                <p class="referrals-description">
                  The more you refer, the more you earn through our tiered
                  rewards system:
                </p>
              </div>
              <div class="referral-section">
                <div class="referral-cards">
                  <div class="referral-card">
                    <div class="referral-icon">
                      <svg
                        width="33"
                        height="44"
                        viewBox="0 0 33 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.60254 43.9958C2.61249 42.4926 2.62282 40.9899 2.63354 39.4875C2.63354 39.0031 2.63354 38.5186 2.63354 37.9951H10.2265V31.9772H17.6565V25.9513H25.1185V19.9609H32.6954C32.7035 20.0907 32.7207 20.2273 32.7207 20.3627C32.7207 28.1271 32.7207 35.8912 32.7207 43.6548C32.7207 43.7696 32.7046 43.8844 32.6954 43.9992L2.60254 43.9958Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M8.41814 10.2101C8.03355 9.87258 7.70407 9.58099 7.37115 9.29169C6.5308 8.55926 5.53433 8.55466 4.89259 9.27906C4.25085 10.0035 4.37713 11.0011 5.20944 11.7347C5.93039 12.3672 6.63986 13.0158 7.38033 13.6254C7.65356 13.855 7.69259 14.0755 7.65586 14.4038C7.54105 15.4129 7.47447 16.4255 7.38607 17.4369C7.36311 17.7055 7.43773 17.8329 7.74425 17.8834C9.33885 18.1486 10.9288 18.4471 12.52 18.7318C13.668 18.9385 14.2156 19.4757 14.3993 20.6421C14.8202 23.3285 15.235 26.0152 15.6437 28.7023C15.7199 29.211 15.5945 29.7293 15.2943 30.1469C14.9942 30.5646 14.5429 30.8486 14.0365 30.9387C12.8988 31.1166 11.9047 30.4129 11.7267 29.2786C11.3984 27.1858 11.0758 25.093 10.7762 22.9967C10.7153 22.5696 10.5707 22.3894 10.1183 22.3079C8.18624 21.9807 6.26676 21.5777 4.3358 21.246C2.81353 20.9842 1.97663 19.8741 1.83198 18.4747C1.69996 17.1969 1.71718 15.9043 1.65404 14.6185C1.64624 14.3514 1.59385 14.0876 1.49905 13.8378C1.06855 12.8046 0.608194 11.7921 0.166208 10.768C-0.17016 9.99083 -0.000254275 9.41108 0.714959 8.9645C2.0788 8.11497 3.47019 7.29873 5.07741 7.01288C5.65604 6.93312 6.24353 6.94358 6.81895 7.04388C7.42625 7.13227 7.81084 7.58344 8.0898 8.10923C8.39288 8.69702 8.50194 9.36401 8.41814 10.2101Z"
                          fill="#9568FF"
                        />
                        <path
                          d="M3.96289 21.5801C5.30377 21.8235 6.58266 22.0611 7.86614 22.2838C7.96382 22.2888 8.05658 22.3282 8.12801 22.395C8.19944 22.4618 8.24494 22.5518 8.25647 22.6489C8.47459 23.6408 8.78226 24.6177 8.91657 25.6199C9.01301 26.3455 8.90969 27.102 8.85573 27.8425C8.67205 30.398 8.4677 32.9512 8.28172 35.5055C8.19792 36.6237 7.32428 37.4686 6.26351 37.4399C5.13846 37.4089 4.29811 36.5135 4.33026 35.3528C4.34633 34.7788 4.40258 34.2048 4.44506 33.6388C4.62989 31.228 4.84801 28.8172 4.98003 26.4063C5.01218 25.8128 4.77568 25.2032 4.64252 24.6051C4.44276 23.7119 4.22464 22.8234 4.01685 21.9325C3.987 21.8246 3.98011 21.7132 3.96289 21.5801Z"
                          fill="#9568FF"
                        />
                        <path
                          d="M6.09571 3.56573C6.09435 2.86157 6.30191 2.17283 6.69213 1.58667C7.08235 1.00052 7.63769 0.543301 8.28786 0.272884C8.93804 0.00246679 9.65382 -0.068994 10.3446 0.067546C11.0354 0.204086 11.6702 0.542489 12.1686 1.03993C12.667 1.53736 13.0066 2.17148 13.1445 2.86201C13.2824 3.55255 13.2123 4.26847 12.9432 4.91916C12.674 5.56986 12.2179 6.12608 11.6325 6.51744C11.0471 6.90879 10.3587 7.11769 9.65456 7.11769C8.71188 7.11769 7.80772 6.74369 7.1405 6.07777C6.47329 5.41184 6.09753 4.5084 6.09571 3.56573Z"
                          fill="#9568FF"
                        />
                        <path
                          d="M10.456 12.4745L13.489 9.8616C13.7278 9.65611 13.9597 9.44258 14.2054 9.24627C14.7794 8.78706 15.521 8.84446 15.9825 9.36796C16.092 9.49189 16.176 9.63626 16.2295 9.79275C16.2831 9.94924 16.3051 10.1148 16.2944 10.2798C16.2838 10.4449 16.2406 10.6062 16.1673 10.7545C16.0941 10.9028 15.9923 11.0351 15.8677 11.1439C14.337 12.4833 12.796 13.8096 11.2447 15.123C10.7372 15.5523 10.1024 15.5351 9.59496 15.0954C8.15612 13.8487 6.72493 12.5927 5.30139 11.3276C4.73542 10.8225 4.69294 10.0579 5.16362 9.52294C5.63431 8.98797 6.39774 8.94319 6.9729 9.43684C8.00611 10.3231 9.02095 11.2277 10.0438 12.1243C10.1747 12.2357 10.309 12.3459 10.456 12.4745Z"
                          fill="#9568FF"
                        />
                      </svg>
                    </div>{" "}
                    <div class="referral-text">
                      <h3 class="referral-card-title">Tiered Rewards:</h3>
                      <p class="referral-card-description">
                        Increase your earnings based on your referral level.
                      </p>
                    </div>
                  </div>

                  <div class="referral-card">
                    <div class="referral-icon">
                      <svg
                        width="33"
                        height="31"
                        viewBox="0 0 33 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.0175 18.6025C17.438 22.33 23.1638 22.3768 26.6008 18.6565C28.3335 19.6167 29.8152 20.864 30.9331 22.5205C32.5369 24.8966 33.041 27.5613 32.9972 30.3739C32.9933 30.6335 32.8924 30.7169 32.6497 30.7067C32.3797 30.6953 32.1096 30.7049 31.8414 30.7049C26.1975 30.7049 20.5539 30.7081 14.9104 30.7145C14.4177 30.7145 14.0103 30.6155 13.6379 30.2944C12.8263 29.5917 11.9231 28.9763 11.203 28.1914C10.1163 27.0071 9.68 25.5096 9.53418 23.8359H14.3931L11.1916 20.6624L14.0175 18.6025Z"
                          fill="#9568FF"
                        />
                        <path
                          d="M20.3356 19.686C16.1532 19.683 12.7318 16.2499 12.7471 12.0723C12.7624 7.90169 16.1676 4.52468 20.3488 4.53518C24.5461 4.54538 27.9009 7.91069 27.8976 12.1065C27.8946 16.2874 24.5005 19.6893 20.3356 19.686Z"
                          fill="#9568FF"
                        />
                        <path
                          d="M11.1461 11.8094C10.0684 12.0128 9.02391 11.9537 8.00195 11.5931C5.42516 10.6836 3.74881 8.07412 4.02965 5.42231C4.3309 2.57458 6.45792 0.391449 9.26875 0.0445958C12.1405 -0.310358 14.8844 1.48692 15.6915 4.24764C15.7581 4.47597 15.8193 4.6434 15.5166 4.78112C13.2134 5.82858 11.9692 7.71616 11.3616 10.0937C11.2917 10.368 11.2535 10.6515 11.2178 10.9339C11.1842 11.1988 11.1731 11.4679 11.1461 11.8094Z"
                          fill="#E10495"
                        />
                        <path
                          d="M11.5636 30.6331C9.54794 30.2308 7.71736 29.4791 6.20243 28.0752C4.6809 26.6656 3.87317 24.883 3.54312 22.8181H0.754498C0.735295 22.78 0.715792 22.7422 0.696289 22.7041L5.78656 17.8389L10.9666 22.7896H7.94899C7.93849 26.0259 8.91394 28.7095 11.5636 30.6331Z"
                          fill="#FFD600"
                        />
                        <path
                          d="M6.21083 15.227C5.68094 15.7728 5.19487 16.2772 4.7058 16.7759C4.45466 17.0327 4.19872 17.2841 3.94458 17.538C2.35674 19.1243 2.33184 19.1327 0.0256836 18.8687C-0.289964 16.6417 2.35674 12.5524 5.05355 11.1377C6.83982 12.9958 9.02095 13.665 11.5969 13.1453C11.7977 13.9905 11.9894 14.7974 12.1472 15.4614L9.02675 18.0718L6.21083 15.227Z"
                          fill="#E10495"
                        />
                      </svg>
                    </div>
                    <div class="referral-text">
                      <h3 class="referral-card-title">Referral Levels:</h3>
                      <p class="referral-card-description">
                        Increase your earnings as you achieve referral
                        milestones.
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div class="unique-referral-system">
                <table class="custom-referral-table">
                  <thead>
                    <tr>
                      <th class="custom-table-heading">Title</th>
                      <th class="custom-table-heading">Level 1</th>
                      <th class="custom-table-heading">Level 2</th>
                      <th class="custom-table-heading">Level 3</th>
                      <th class="custom-table-heading">Level 4</th>
                      <th class="custom-table-heading">Level 5</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="custom-table-title-1st">
                      <td class="custom-table-title">Referrals</td>
                      <td class="custom-table-data">5</td>
                      <td class="custom-table-data">6-10</td>
                      <td class="custom-table-data">11-15</td>
                      <td class="custom-table-data">16-20</td>
                      <td class="custom-table-data">21 & Above</td>
                    </tr>
                    <tr className="custom-table-title-2nd">
                      <td class="custom-table-title">Rewards</td>
                      <td class="custom-table-data">10%</td>
                      <td class="custom-table-data">12%</td>
                      <td class="custom-table-data">15%</td>
                      <td class="custom-table-data">17%</td>
                      <td class="custom-table-data">20%</td>
                    </tr>
                  </tbody>
                </table>

                <p class="referral-program-info">
                  Join our referral program to earn unlimited, real money from
                  your referrals and help others benefit from our products or
                  services. Start earning immediately with your unique
                  link—everyone wins!
                </p>
              </div>
            </div>

            <div className="partner-link">
              <div className="total-user-2">
                Total Referred Partners {""} {""}
                <span className="user-count-1">
                  {props.partners.referredPartners.length} Users
                </span>
              </div>
              <div class="table-container-unique">
                <table class="styled-table-unique">
                  <thead>
                    <tr>
                      <th class="header-cell-unique">Name</th>
                      <th class="header-cell-unique">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(props.partners.referredPartners) &&
                    props.partners.referredPartners.length > 0 ? (
                      props.partners.referredPartners.map((partner) => (
                        <tr key={partner.id}>
                          <td className="data-cell-unique">{partner.name}</td>
                          <td className="data-cell-unique">{partner.email}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={8} className="no-data-message-2">
                          No referred partners found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  showLoading: state.showLoading,
  error: state.error,
});

export default connect(mapStateToProps)(Reward2);
