export const MenuList = [
  {
    title: "Dashboard",
    to: "/",
    className: "dashboard1",
  },
  {
    title: "Pools",
    to: "/pools2",
    className: "pools1",
  },
  {
    title: "Stakings",
    to: "/manage-stakings",
    className: "stakings1",
  },
  {
    title: "Stake History",
    to: "/stake-history",
    className: "stake-history1",
  },
  {
    title: "Transactions",
    to: "/transactions2",
    className: "transactions1",
  },
  {
    title: "Earnings",
    to: "/my-earnings2",
    className: "earnings1",
  },
  {
    title: "Rewards",
    to: "/rewards2",
    className: "rewards1",
  },
];