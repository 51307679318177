import React, { useState, useEffect, Fragment, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import { getTransactionsAction, loadingToggleAction, cancelWithdraTransactionAction } from "../../store/actions/TransactionsActions";
import { Row, Card, Tab, Nav, Button, ListGroup } from "react-bootstrap";

const Transactions = (props) => {
  // const [withdraws, setWithdraws] = useState([]);
  // const [deposits, setDeposits] = useState([]);
  const [isWithdrawCanceled, setIsWithdrawCanceled] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(getTransactionsAction());
  }, [isWithdrawCanceled]);
  const withdrawls = props.transactions.withdrawls;
  const deposits = props.transactions.deposits;
  function formatDate(dateString) {
    const date = new Date(dateString);

    // Format date as "May 14, 2024"
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    // Format time as "2:03:26 PM"
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    return { formattedDate, formattedTime };
  }
  const handleCancelWithdraw = (_withdraw) => {
    dispatch(cancelWithdraTransactionAction(_withdraw));
    setTimeout(() => {
      setIsWithdrawCanceled(!isWithdrawCanceled);
    }, 2000);
  };
  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Card>
          {/* <Card.Header>
              <Card.Title>Default Tab</Card.Title>
            </Card.Header> */}
          <Card.Body>
            {/* <!-- Nav tabs --> */}
            <div className="default-tab">
              <Tab.Container defaultActiveKey="deposits">
                <Nav as="ul" className="nav-tabs">
                  <Nav.Item as="li">
                    <Nav.Link eventKey="deposits">Deposits</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="withdraws">Withdraws</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="pt-4">
                  {/* //////////////////////Deposits Tab///////////////////////////////////////// */}
                  <Tab.Pane eventKey="deposits">
                    {/* <h4>This is Un-Processed title</h4> */}
                    <div className="card-body">
                      <div className="table-responsive recentOrderTable">
                        <table className="table verticle-middle table-responsive-md">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Transaction Type</th>
                              <th scope="col">Transaction Amount</th>
                              {/* <th scope="col">Withdraw Fee</th> */}
                              <th scope="col">Created At</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {deposits ? (
                              deposits.map((deposit, index) => (
                                <tr key={deposit.id}>
                                  <td>{index + 1 + "."}</td> {/* Row number */}
                                  <td>
                                    <span class={deposit.type === "deposit" ? "badge bg-success badge-lg" : "badge-danger badge badge-lg"}>{deposit.type}</span>
                                  </td>
                                  <td>{deposit.amount}</td>
                                  {/* <td>{deposit.type === "withdrawl" ? deposit.withdraw_fee : "-"}</td> */}
                                  <td>{formatDate(deposit.created_at)}</td>
                                  <td>
                                    <span class={deposit.status === "Served" ? "text-success" : "text-danger"}>{deposit.status}</span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <p>"Loading..."</p>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Tab.Pane>
                  {/* //////////////////////Withdraws Tab///////////////////////////////////////// */}

                  <Tab.Pane eventKey="withdraws">
                    {/* <h4>This is Processed title</h4> */}
                    <div className="card-body">
                      <div className="table-responsive recentOrderTable">
                        <table className="table verticle-middle table-responsive-md">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Transaction Type</th>
                              <th scope="col">Transaction Amount</th>
                              <th scope="col">Withdraw Fee</th>
                              <th scope="col">Created At</th>
                              <th scope="col">Status</th>
                              <th scope="col">Details</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {withdrawls &&
                              withdrawls.map((withdraw, index) => (
                                <tr key={withdraw.id}>
                                  <td>{index + 1 + "."}</td> {/* Row number */}
                                  <td>
                                    <span class={withdraw.type === "deposit" ? "badge bg-success badge-lg" : "badge-danger badge badge-lg"}>{withdraw.type}</span>
                                  </td>
                                  <td>{withdraw.amount}</td>
                                  <td>{withdraw.type === "withdrawl" ? withdraw.withdraw_fee : "-"}</td>
                                  <td>{formatDate(withdraw.created_at)}</td>
                                  <td>
                                    <span class={withdraw.status === "Served" ? "text-success" : "text-danger"}>{withdraw.status}</span>
                                  </td>
                                  <td>
                                    {withdraw.transaction_id ? (
                                      <a class="btn btn-primary shadow  sharp me-3" href={`https://tronscan.org/#/transaction/${withdraw.transaction_id}`} target="blank">
                                        Details{" "}
                                      </a>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {withdraw.status === "Served" || withdraw.status === "Canceled" ? (
                                      "-"
                                    ) : (
                                      <Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={() => handleCancelWithdraw(withdraw)}>
                                        Cancel
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  transactions: state.transactions,
  withdrawls: state.withdrawls,
  deposits: state.deposits,
  showLoading: state.showLoading,
  error: state.error,
});

export default connect(mapStateToProps)(Transactions);
