import React, { useState, useEffect, Fragment, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import { loadingAction, getProcessedDepositsAction, getUnProcessedDepositsAction, transferUsdtAction, transferAllUsdtAction } from "../../../store/actions/PartnerDepositsActions";
import { Row, Card, Tab, Nav, Button } from "react-bootstrap";
import { Bars } from "react-loader-spinner";

const PartnerDeposits = (props) => {
  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    };
    const formattedDate = new Date(dateTimeString).toLocaleString("en-US", options);
    return formattedDate;
  };
  const [activeTab, setActiveTab] = useState("un-processed");

  const dispatch = useDispatch();
  const openTransferUsdtModel = (deposit) => {
    // console.log(deposit);
    dispatch(transferUsdtAction(deposit));
    // setUnStakeDoc(staking);
    // setUnstakeModal(true);
  };

  const handleTrasferAllDeposits = () => {
    dispatch(loadingAction(true));
    dispatch(transferAllUsdtAction());
    // dispatch(getUnProcessedDepositsAction());
  };
  useEffect(() => {
    // dispatch(loadingToggleAction(true));
    dispatch(getProcessedDepositsAction());
    dispatch(getUnProcessedDepositsAction());
  }, [activeTab]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  return (
    <Fragment>
      {/* <PageTitle activeMenu="Tab" motherMenu="Bootstrap" pageContent="Tab" /> */}
      <Row>
        <div className="col-xl-12">
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            <Card>
              {/* <Card.Header>
              <Card.Title>Default Tab</Card.Title>
            </Card.Header> */}

              <Card.Body>
                {/* <!-- Nav tabs --> */}
                <div className="default-tab">
                  <Tab.Container defaultActiveKey="un-processed" activeKey={activeTab} onSelect={handleTabChange}>
                    <Nav as="ul" className="nav-tabs">
                      <Nav.Item as="li">
                        <Nav.Link eventKey="processed">Processed</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey="un-processed">Un-Processed</Nav.Link>
                      </Nav.Item>
                      {props.showLoading ? (
                        <Button as="a" href="#" className="btn btn-primary mb-1 ms-1">
                          <Bars height="25" width="25" color="#ffffff" ariaLabel="bars-loading" wrapperStyle={{}} wrapperClass="" visible={true} />
                        </Button>
                      ) : (
                        <Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={handleTrasferAllDeposits}>
                          Transfer All
                        </Button>
                      )}
                    </Nav>
                    <Tab.Content className="pt-4">
                      {/* //////////////////////Processed Deposits///////////////////////////////////////// */}
                      <Tab.Pane eventKey="processed">
                        {/* <h4>This is Processed title</h4> */}
                        <div className="card-body">
                          <div className="table-responsive recentOrderTable">
                            <table className="table verticle-middle table-responsive-md">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Amount(USDT)</th>
                                  <th scope="col">Wallet</th>
                                  <th scope="col">Email</th>
                                  <th scope="col">Trans. ID</th>
                                  <th scope="col">BlockTS</th>
                                  <th scope="col">From</th>
                                  <th scope="col">Created At</th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.processed.map((deposit, index) => (
                                  <tr key={index}>
                                    <td>{index + 1 + "."}</td> {/* Row number */}
                                    <td>{deposit.partner_info.name && deposit.partner_info.name ? deposit.partner_info.name : "no name"}</td>
                                    <td>{deposit.quant / 1e6}</td>
                                    <td>{deposit.wallet_address}</td>
                                    <td>{deposit.partner_info.email && deposit.partner_info.email ? deposit.partner_info.email : "no email"}</td>
                                    <td>{deposit.transaction_id}</td>
                                    <td>{formatDate(deposit.block_ts)}</td>
                                    <td>{deposit.from_address}</td>
                                    <td>{deposit.created_at}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Tab.Pane>
                      {/* //////////////////////Unprocessed Deposits///////////////////////////////////////// */}
                      <Tab.Pane eventKey="un-processed">
                        {/* <h4>This is Un-Processed title</h4> */}
                        <div className="card-body">
                          {Array.isArray(props.unProcessed) ? (
                            <div className="table-responsive recentOrderTable">
                              <table className="table verticle-middle table-responsive-md">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Wallet</th>
                                    <th scope="col">Trans. ID</th>
                                    <th scope="col">BlockTS</th>
                                    <th scope="col">From</th>
                                    <th scope="col">Amount(USDT)</th>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Is Suspicous</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {props.unProcessed.map((deposit, index) => (
                                    <tr key={index}>
                                      <td>{index + 1 + "."}</td> {/* Row number */}
                                      <td>{deposit.wallet_address}</td>
                                      <td>{deposit.transaction_id}</td>
                                      <td>{deposit.block_ts}</td>
                                      <td>{deposit.from_address}</td>
                                      <td>{deposit.quant / 1e6}</td>
                                      <td>{deposit.created_at}</td>
                                      <td>
                                        <span class={deposit.is_suspicious === false ? "badge bg-success badge-lg" : "badge-danger badge badge-lg"}>
                                          {deposit.is_suspicious === false ? "Legit" : "Suspicious"}
                                        </span>
                                      </td>
                                      <td>
                                        <Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={() => openTransferUsdtModel(deposit)}>
                                          Transfer
                                        </Button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p>{props.unProcessed}</p>
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Card.Body>
            </Card>
          </Suspense>
        </div>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  processed: state.partnerdeposits.processed,
  unProcessed: state.partnerdeposits.unProcessed,
  showLoading: state.partnerdeposits.showLoading,
  error: state.error,
});

export default connect(mapStateToProps)(PartnerDeposits);
