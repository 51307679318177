import { useEffect } from "react";

function useSmoothScroll() {
  useEffect(() => {
    // Check if the browser supports smooth scroll
    if (typeof window !== "undefined" && "scrollBehavior" in document.documentElement.style) {
      document.documentElement.style.scrollBehavior = "smooth";
    } else {
      // Fallback for older mobile browsers
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);
}

export default useSmoothScroll;
