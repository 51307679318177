import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { createPool, formatStakings, formatError, formatError2, getStakings, getStakingsHistory, createStaking, unStake, autoStakeToggle } from "../../services/ManageStakingsService";
import {
  CONFIRMED_CREATE_STAKINGS_ACTION,
  CONFIRMED_GET_STAKINGS_ACTION,
  CONFIRMED_GET_STAKINGS_HISTORY_ACTION,
  GET_STAKINGS_FAILED_ACTION,
  LOADING_TOGGLE_ACTION,
  CONFIRMED_UPDATE_STAKINGS_ACTION,
  CLEAR_STAKINGS_ACTION,
} from "./ManageStakingTypes";
import { CONFIRMED_GET_PARTNERS_ACTION } from "./PartnersTypes";

import { confirmedTopUpPartnerAction } from "./PartnersActions";
import swal from "sweetalert";

//Action to create a staking of a Logged-In User
export function createStakingsAction(_amount, _poolId, _autoStake, navigate) {
  return (dispatch, getState) => {
    createStaking(_amount, _poolId, _autoStake)
      .then((response) => {
        let staking = response.data.data.staking;
        let updated_user = response.data.data.updated_user;
        localStorage.setItem("userDetails", JSON.stringify(updated_user));
        dispatch(confirmedCreateStakingsAction(true));
        dispatch(confirmedCreateStakingsPartnerAction(updated_user));
        navigate("/manage-stakings");
      })
      .catch((error) => {
        const errorMessage = formatError2(error.response.data);
        dispatch(getStakingsFailedAction(errorMessage));
      });
  };
}

export function confirmedCreateStakingsAction(staking) {
  return {
    type: CONFIRMED_CREATE_STAKINGS_ACTION,
    payload: staking,
  };
}

export function confirmedCreateStakingsPartnerAction(_partner) {
  return {
    type: CONFIRMED_GET_PARTNERS_ACTION,
    payload: _partner,
  };
}
//Action to create a staking of a Logged-In User
export function unStakeAction(_unstakeDoc, navigate) {
  return (dispatch, getState) => {
    unStake(_unstakeDoc)
      .then((response) => {
        localStorage.setItem("userDetails", JSON.stringify(response.data.data));
        dispatch(confirmedUnstakeAction(response.data.data));
        swal("Success!", "You have successfully unstaked!", "success");
        navigate("/stake-history");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(getStakingsFailedAction(errorMessage));
      });
  };
}

export function confirmedUnstakeAction(_partner) {
  return {
    type: CONFIRMED_GET_PARTNERS_ACTION,
    payload: _partner,
  };
}

//Action to toggle Auto-Stake
export function autoStakeToggleAction(_staking, navigate) {
  return (dispatch, getState) => {
    autoStakeToggle(_staking)
      .then((response) => {
        // localStorage.setItem("userDetails", JSON.stringify(response.data));
        dispatch(confirmedAutoStakeToggle(response.data.data));
        navigate("/manage-stakings");
        // swal("Success!", "You have successfully unstaked!", "success");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(getStakingsFailedAction(errorMessage));
      });
  };
}

export function confirmedAutoStakeToggle(_staking) {
  return {
    type: CONFIRMED_UPDATE_STAKINGS_ACTION,
    payload: _staking,
  };
}

//Action for reading all in-process stakings of a Logged-In User
export function getStakingsAction() {
  return (dispatch, getState) => {
    getStakings()
      .then((response) => {
        let stakings = formatStakings(response.data.data);
        dispatch(confirmedGetStakingsAction(stakings));
      })
      .catch((error) => {
        const err = error.response.data;
        if (err.error.type == "NO_STAKINGS_FOUND") dispatch(clearStakingsAction());
        dispatch(getStakingsFailedAction(err));
      });
  };
}
export function confirmedGetStakingsAction(flag) {
  return {
    type: CONFIRMED_GET_STAKINGS_ACTION,
    payload: flag,
  };
}
export function clearStakingsAction() {
  return {
    type: CLEAR_STAKINGS_ACTION,
  };
}

//Action for reading all completed stakings of a Logged-In User
export function getStakingsHistoryAction() {
  return (dispatch, getState) => {
    getStakingsHistory()
      .then((response) => {
        let stakings = formatStakings(response.data.data);
        dispatch(confirmedGetHistoryStakingsAction(stakings));
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(getStakingsFailedAction(errorMessage));
      });
  };
}

export function confirmedGetHistoryStakingsAction(flag) {
  return {
    type: CONFIRMED_GET_STAKINGS_HISTORY_ACTION,
    payload: flag,
  };
}

export function getStakingsFailedAction(data) {
  return {
    type: GET_STAKINGS_FAILED_ACTION,
    payload: data,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
