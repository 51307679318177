import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, Logout, loginAuthenticatedAction } from "../store/actions/AuthActions";

//Read all partners from Server
export function getIpInfo() {
  return axios.get("https://get.geojs.io/v1/ip/country.json");
}

//Service for initiation sign up process with email
export function registerSignUpEmail(email, referralCode) {
  //axios call
  const postData = {
    email,
    referralCode,
  };
  const reqUrl = process.env.REACT_APP_MYARBIT_HostUrl + "user/emailLookUp";
  return axios.post(reqUrl, postData);
}

// service for verifying email on signUp
export function signUpVerifyEmail(email, code, token) {
  //axios call
  const postData = {
    email,
    code,
  };
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  const reqUrl = process.env.REACT_APP_MYARBIT_HostUrl + "user/verifySignUpEmail";
  return axios.put(reqUrl, postData, { headers });
  // return axios.post(reqUrl, postData);
}

// service for setting up password on signUp
export function signUpSetPassword(email, password, token) {
  //axios call
  const postData = {
    email,
    password,
  };
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  const reqUrl = process.env.REACT_APP_MYARBIT_HostUrl + "user/setPassword";
  return axios.post(reqUrl, postData, { headers });
  // return axios.post(reqUrl, postData);
}

// service for setting Promocode on signUp
export function signUpPromoCode(promoCode) {
  let token = JSON.parse(localStorage.jwt);

  const postData = {
    promoCode,
  };
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  const reqUrl = process.env.REACT_APP_MYARBIT_HostUrl + "user/setPromoCode";
  return axios.post(reqUrl, postData, { headers });
}
// service for Choosing account types on signUp
export function signUpSetAccountType(acctype) {
  let token = JSON.parse(localStorage.jwt);

  const postData = {
    acctype,
  };
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  const reqUrl = process.env.REACT_APP_MYARBIT_HostUrl + "user/setAccountType";
  return axios.post(reqUrl, postData, { headers });
}

// service for profile completion on signUp
export function signUpCompleteProfile(name, phone, country, dob) {
  let token = JSON.parse(localStorage.jwt);
  const postData = {
    name,
    phone,
    country,
    dob,
  };
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  const reqUrl = process.env.REACT_APP_MYARBIT_HostUrl + "user/completeProfile";
  return axios.post(reqUrl, postData, { headers });
}
export function signUp(name, account_type, email, password, repeat_password, phone, country, referralCode) {
  //axios call
  const postData = {
    name,
    account_type,
    email,
    password,
    repeat_password,
    phone,
    country,
    referralCode,
    // returnSecureToken: true,
  };
  const reqUrl = process.env.REACT_APP_MYARBIT_HostUrl + "user";
  return axios.post(reqUrl, postData);
}

export function forgotPassword(email) {
  const postData = { email };
  const reqUrl = process.env.REACT_APP_MYARBIT_HostUrl + "user/forgotPassword";

  return axios.post(reqUrl, postData);
}

export function resetPassword(_password, _resetToken) {
  const data = {
    password: _password,
  };
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": _resetToken,
  };
  const reqUrl = process.env.REACT_APP_MYARBIT_HostUrl + "user/resetPassword";

  return axios.post(reqUrl, data, { headers });
}

export function login(email, password, captchaToken) {
  const postData = {
    email,
    password,
    captchaToken,
    //returnSecureToken: true,
  };

  const reqUrl = process.env.REACT_APP_MYARBIT_HostUrl + "user/login";

  return axios.post(reqUrl, postData);
}

export function verifyLoginService(_loginCode) {
  let token = JSON.parse(localStorage.jwt);
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  const postData = {
    loginCode: _loginCode,
    //returnSecureToken: true,
  };
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/verifylogin";
  return axios.post(url, postData, { headers });
}

export function googleLogin() {
  const reqUrl = process.env.REACT_APP_MYARBIT_HostUrl + "auth/google";

  return axios.get(reqUrl);
}

export function formatError(errorResponse, navigate) {
  switch (errorResponse) {
    case "Account is temporarily locked due to multiple failed attempts. Please try again later.":
      swal("Sorry", errorResponse, "error");
      // localStorage.removeItem("userDetails");
      // localStorage.removeItem("jwt");
      //navigate("/login");
      break;
    case "Internal server error":
      swal("Sorry", errorResponse, "error");
      // localStorage.removeItem("userDetails");
      // localStorage.removeItem("jwt");
      //navigate("/login");
      break;
    case "Too many attempts, please try again later in a minute.":
      swal("Sorry", errorResponse, "error");
      break;
    case "Verification code is required":
      swal("Sorry", errorResponse, "error");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("jwt");
      //navigate("/login");
      break;
    case "Invalid email verification code":
      swal("Sorry", errorResponse, "error");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("jwt");
      //navigate("/login");
      break;
    case "Partner not found":
      swal("Sorry", errorResponse, "error");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("jwt");
      navigate("/login");
      break;
    case "Profile already complete":
      swal("Sorry", "Your Profile is already complete. Please Login again", "error");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("jwt");
      navigate("/login");
      break;
    case "Promo code already used":
      swal("Sorry", errorResponse, "error");
      break;
    case "Invalid Promocode":
      swal("Sorry", errorResponse, "error");
      break;
    case "Promo code is expired":
      swal("Sorry", errorResponse, "error");
      break;
    case "INVALID_EMAIL_VERIFICATION_CODE":
      swal("Sorry", "You have entered an invalid email verification code. Please check your email for verification code", "error");
      break;
    case "Invalid_login_code":
      swal("Sorry", "You have entered an invalid verification code. Please check your email for login verification code", "error");
      break;
    case "PARTNER_BLOCKED":
      swal("Sorry", "Your access to MyArbit is blocked. Please contact support for assistance", "error");
      break;
    case "User already registered":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "Invalid email or password":
      //return 'Email not found';
      swal("Oops", "Invalid Email or Password ", "error", {
        button: "Try Again!",
      });
      break;
    case "InActive_Account":
      //return 'Email not found';
      swal("Oops", "Your account is not active. Please complete your signup ", "error", {
        button: "Try Again!",
      });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      swal("Oops", "Something went wrong please try again", "error", { button: "Try Again!" });
      return "";
  }
}

export function formatError2(errorResponse, navigate) {
  const errorMessage = errorResponse.error.message;
  switch (errorResponse.error.type) {
    case "RESET_PASSWORD_PARTNER_NOT_FOUND":
      swal("Sorry", errorMessage, "error");
      break;

    case "INVALID_LOGIN_CODE":
      swal("Sorry", errorMessage, "error");
      break;
    case "Internal server error":
      swal("Sorry", errorResponse, "error");
      // localStorage.removeItem("userDetails");
      // localStorage.removeItem("jwt");
      //navigate("/login");
      break;
    case "Too many attempts, please try again later in a minute.":
      swal("Sorry", errorResponse, "error");
      break;
    case "Verification code is required":
      swal("Sorry", errorResponse, "error");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("jwt");
      //navigate("/login");
      break;
    case "Invalid email verification code":
      swal("Sorry", errorResponse, "error");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("jwt");
      //navigate("/login");
      break;
    case "Partner not found":
      swal("Sorry", errorResponse, "error");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("jwt");
      navigate("/login");
      break;
    case "Profile already complete":
      swal("Sorry", "Your Profile is already complete. Please Login again", "error");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("jwt");
      navigate("/login");
      break;
    case "Promo code already used":
      swal("Sorry", errorResponse, "error");
      break;
    case "Invalid Promocode":
      swal("Sorry", errorResponse, "error");
      break;
    case "Promo code is expired":
      swal("Sorry", errorResponse, "error");
      break;
    case "INVALID_EMAIL_VERIFICATION_CODE":
      swal("Sorry", "You have entered an invalid email verification code. Please check your email for verification code", "error");
      break;
    case "Invalid_login_code":
      swal("Sorry", "You have entered an invalid verification code. Please check your email for login verification code", "error");
      break;
    case "PARTNER_BLOCKED":
      swal("Sorry", "Your access to MyArbit is blocked. Please contact support for assistance", "error");
      break;
    case "User already registered":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "Invalid email or password":
      //return 'Email not found';
      swal("Oops", "Invalid Email or Password ", "error", {
        button: "Try Again!",
      });
      break;
    case "InActive_Account":
      //return 'Email not found';
      swal("Oops", "Your account is not active. Please complete your signup ", "error", {
        button: "Try Again!",
      });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      swal("Oops", "Something went wrong please try again", "error", { button: "Try Again!" });
      return "";
  }
}

export function saveTokenInLocalStorage(_userDetails) {
  // _userDetails.expireDate = new Date(
  //     new Date().getTime() + _userDetails.expiresIn * 1000,
  // );
  localStorage.setItem("jwt", JSON.stringify(_userDetails.idToken));
  localStorage.setItem("userDetails", JSON.stringify(_userDetails.user));
}
export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function checkAutoLogin(dispatch, navigate, referralCode, resetToken, signUp, emailVerifyToken, partnerId, userObj) {
  const tokenDetailsString = localStorage.getItem("jwt");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  if (user && !user.is_complete) navigate("/register-done");
  if (tokenDetailsString) {
    let decodeUrl = parseJwt(tokenDetailsString);
    // console.log(decodeUrl.exp);
    let todaysDate = Date.now();
    var currentEpochTime = Math.floor(todaysDate / 1000);
    if (currentEpochTime > decodeUrl.exp) {
      dispatch(Logout(navigate));
      return;
    }
  }

  let tokenDetails = "";
  if (emailVerifyToken) {
    navigate(`/verify-email?partnerId=${partnerId}&emailVerifyToken=${emailVerifyToken}`);
    return;
  }
  if (userObj) {
    saveTokenInLocalStorage(userObj);
    if (userObj.user.is_active && userObj.user.is_complete) {
      dispatch(loginAuthenticatedAction());
      dispatch(loginConfirmedAction(userObj));
      navigate("/pools");
    } else {
      navigate("/register-done");
    }
    return;
  }
  if (resetToken) {
    navigate(`/reset-password?token=${resetToken}`);
    return;
  }
  if (!tokenDetailsString) {
    if (referralCode) {
      navigate(`/register?ref=${referralCode}`);
      return;
    }
    if (resetToken) {
      navigate(`/reset-password?token=${resetToken}`);
      return;
    }
    if (signUp === "true") {
      navigate(`/register`);
      return;
    }

    dispatch(Logout(navigate));
    return;
  }
  if (!user.is_active) return navigate("/register");
  if (Object.keys(user).length === 0) {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = {
    idToken: tokenDetailsString,
    user: user,
  };
  // let expireDate = new Date(tokenDetails.expireDate);
  // // let todaysDate = new Date();

  // if (todaysDate > expireDate) {
  //   dispatch(Logout(navigate));
  //   return;
  // }

  dispatch(loginConfirmedAction(tokenDetails));

  // const timer = expireDate.getTime() - todaysDate.getTime();
  // runLogoutTimer(dispatch, timer, navigate);
}
