import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Logout,
  loginUnAuthenticateAction,
} from "../../../store/actions/AuthActions";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function LogoutPage(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 990);

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 990);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLogout = () => {
    dispatch(Logout(navigate));
    dispatch(loginUnAuthenticateAction());
  };

  const openLogoutModal = () => setShowLogoutModal(true);
  const closeLogoutModal = () => setShowLogoutModal(false);

  return (
    <>
      {isMobileView ? (
        <Link className="logout1" onClick={openLogoutModal}>
          Logout
        </Link>
      ) : (
        <Button variant="" className="logout-view" onClick={openLogoutModal}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="18"
              cy="18"
              r="17.75"
              stroke="white"
              strokeWidth="0.5"
            />
            <path
              d="M17.5777 9.02315C17.7217 9.02315 17.8664 9.01607 18.0104 9.02315C18.2133 9.02953 18.4057 9.11526 18.5461 9.26193C18.6865 9.40859 18.7638 9.60448 18.7613 9.80751C18.7591 10.0065 18.6787 10.1966 18.5375 10.3368C18.3962 10.4769 18.2055 10.5559 18.0065 10.5565C17.028 10.581 16.0726 10.7282 15.1725 11.1236C12.5944 12.2571 11.0192 14.2096 10.6322 17.0025C10.1101 20.7739 12.3938 24.184 16.0565 25.1869C16.668 25.3554 17.318 25.3914 17.9538 25.4557C18.4431 25.5058 18.7774 25.8112 18.7607 26.2574C18.7446 26.6927 18.3917 27.0032 17.9191 27C15.9425 26.9807 14.0272 26.3119 12.4682 25.0966C10.9093 23.8814 9.79332 22.1871 9.29233 20.275C8.01356 15.392 11.0546 10.3784 15.9845 9.25717C16.506 9.13887 17.0422 9.08551 17.5719 9L17.5777 9.02315Z"
              fill="white"
            />
            <path
              d="M24.3349 18.7823H24.0366C21.3899 18.7823 18.7431 18.7823 16.0964 18.7823C15.9607 18.7871 15.8251 18.7715 15.694 18.736C15.5277 18.6831 15.3849 18.5743 15.2899 18.428C15.1948 18.2817 15.1534 18.107 15.1726 17.9336C15.1916 17.7525 15.2741 17.584 15.4054 17.4579C15.5367 17.3318 15.7084 17.2562 15.8901 17.2444C15.9704 17.2386 16.0508 17.2444 16.1305 17.2444H24.3246C24.2571 17.166 24.2166 17.112 24.169 17.065C23.6425 16.5365 23.1075 16.0158 22.59 15.4789C22.4919 15.3832 22.4213 15.263 22.3856 15.1307C22.3498 14.9984 22.3502 14.8589 22.3867 14.7269C22.4233 14.5948 22.4946 14.4749 22.5932 14.3798C22.6918 14.2847 22.8141 14.2177 22.9474 14.186C23.0724 14.1513 23.2043 14.1509 23.3295 14.1848C23.4546 14.2188 23.5683 14.2858 23.6585 14.3789C24.6941 15.4076 25.726 16.4403 26.7542 17.4771C27.0654 17.7915 27.0615 18.2416 26.7478 18.5585C25.729 19.5898 24.7024 20.6159 23.6682 21.6369C23.5979 21.7092 23.5139 21.7668 23.421 21.8061C23.3281 21.8453 23.2283 21.8656 23.1275 21.8656C23.0266 21.8656 22.9268 21.8453 22.8339 21.8061C22.7411 21.7668 22.657 21.7092 22.5868 21.6369C22.5138 21.5648 22.456 21.4788 22.4169 21.384C22.3777 21.2892 22.358 21.1875 22.3588 21.0849C22.3597 20.9824 22.3811 20.881 22.4218 20.7869C22.4625 20.6927 22.5216 20.6077 22.5958 20.5368C23.1069 20.0154 23.6244 19.503 24.1426 18.9867C24.1973 18.934 24.2481 18.8755 24.3349 18.7823Z"
              fill="white"
            />
          </svg>
        </Button>
      )}
      <Modal show={showLogoutModal} onHide={closeLogoutModal} centered>
        <div className="unstake-modal-content">
          <div className="unstake-modal-header">
            <div className="unstake-model-svg">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.6875 15.4875L14.6625 2.57501C14.025 1.71251 13.05 1.22501 12 1.22501C10.9125 1.22501 9.93753 1.71251 9.33753 2.57501L1.31253 15.4875C0.562527 16.5 0.450027 17.8125 1.01253 18.9375C1.57503 20.0625 2.70003 20.775 3.97503 20.775H20.025C21.3 20.775 22.425 20.0625 22.9875 18.9375C23.55 17.85 23.4375 16.5 22.6875 15.4875ZM21.4875 18.1875C21.1875 18.75 20.6625 19.0875 20.025 19.0875H3.97503C3.33753 19.0875 2.81253 18.75 2.51253 18.1875C2.25003 17.625 2.28753 16.9875 2.66253 16.5L10.6875 3.58751C10.9875 3.17501 11.475 2.91251 12 2.91251C12.525 2.91251 13.0125 3.13751 13.3125 3.58751L21.3375 16.5C21.7125 16.9875 21.75 17.625 21.4875 18.1875Z"
                  fill="#F23030"
                />
                <path
                  d="M12 8.20004C11.55 8.20004 11.1375 8.57504 11.1375 9.06254V13.15C11.1375 13.6 11.5125 14.0125 12 14.0125C12.4875 14.0125 12.8625 13.6375 12.8625 13.15V9.02504C12.8625 8.57504 12.45 8.20004 12 8.20004Z"
                  fill="#F23030"
                />
                <path
                  d="M12 15C11.55 15 11.1375 15.375 11.1375 15.8625V16.05C11.1375 16.5 11.5125 16.9125 12 16.9125C12.4875 16.9125 12.8625 16.5375 12.8625 16.05V15.825C12.8625 15.375 12.45 15 12 15Z"
                  fill="#F23030"
                />
              </svg>{" "}
            </div>
            <div className="unstake-modal-title-wrapper">
              <div className="unstake-modal-title">Confirm Logout</div>
              <div className="unstake-modal-description">
                <span>Are you sure you want to log out?</span>
              </div>
            </div>
            <div className="unstake-modal-footer">
              <button className="unstake-confirm-button" onClick={handleLogout}>
                <div className="unstake-confirm-text">Yes</div>
              </button>
              <button
                className="unstake-cancel-button"
                onClick={closeLogoutModal}
              >
                <div>No</div>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(LogoutPage);
